import { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import constants from '../../config/constants';
import { usePermissions } from '../../utils/PermissionsProvider';
import { useAuth0 } from '../../utils/Auth0Provider';
import DashboardIcon from '../Icons/DashboardIcon';
import ContractIcon from '../Icons/ContractIcon';
import StatsIcon from '../Icons/StatsIcon';
import HelpIcon from '../Icons/HelpIcon';
import ContractSelectorButton from '../UserHeader/UserProfileMenu/ContractSelector/ContractSelectorButton';
import { useContractProvider } from '../../utils/ContractProvider';
import MobileContractSelectorMenu from '../UserHeader/UserProfileMenu/ContractSelector/MobileContractSelectorMenu';
import InviteesListIcon from '../Icons/InviteesListIcon';
import { ROUTES as STATISTICS_ROUTES } from '../Statistics/routes';
import { CONTRACTS } from '../../config/routes';

import AnimatedDrawer from './AnimatedDrawer';
import MerLogo from './MerLogo';
import MobileUserProfile from './MobileUserProfile';
import {
  LeftArrowIcon,
  RightArrowIcon,
  NavContainer,
  PageWrapper,
  NavBarToggleButton,
  NavList,
  NavlistItem,
  NavigationText,
  NavListIcon,
  NavItemLink,
  BurgerMenu,
  NavBackdrop,
} from './NavigationBar.parts';

const getDrawerState = () => {
  try {
    const drawerState = localStorage.getItem('navbarDrawerState');
    return drawerState === 'true';
  } catch (err) {
    return false;
  }
};

function NavigationBar({ children }: PropsWithChildren<Record<string, unknown>>) {
  const saveDrawerState = useCallback((status) => {
    try {
      localStorage.setItem('navbarDrawerState', status);
    } catch (err) {
      // ignore write errors
    }
  }, []);

  const { t } = useTranslation(['translation', 'navigation']);
  const [open, setOpen] = useState(getDrawerState());
  const [menuLevel, setMenuLevel] = useState(1);
  const { availableContracts, selectedContract, eligibleForFollowUpContract } = useContractProvider();
  const { isAuthenticated, loading, user } = useAuth0();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { hasAssumedRole, getAssumedRole } = usePermissions();
  const activeRole = getAssumedRole();
  const contractLink = activeRole === 'driver' && selectedContract ? `${CONTRACTS}/${selectedContract.id}` : CONTRACTS;

  const handleDrawerOpen = () => {
    setOpen(true);
    saveDrawerState(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMenuLevel(1);
    saveDrawerState(false);
  };

  const handleChangeMenuLevel = () => {
    if (menuLevel === 1) {
      setMenuLevel(2);
    } else {
      setMenuLevel(1);
    }
  };

  if (loading || !isAuthenticated) {
    return children;
  }

  if (menuLevel === 2 && isMobile) {
    return (
      <MobileContractSelectorMenu
        contractList={availableContracts}
        selectedContract={selectedContract}
        handleGoBack={handleChangeMenuLevel}
        handleClose={handleDrawerClose}
        eligibleForFollowUpContract={!!eligibleForFollowUpContract}
      />
    );
  }

  return (
    <>
      {isMobile && <NavBackdrop open={open} />}
      <NavContainer>
        <AnimatedDrawer open={open}>
          {isMobile ? (
            <BurgerMenu
              open={open}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              data-testid="mobile-burger-menu"
            />
          ) : (
            <NavBarToggleButton data-testid="nav-toggle-button" onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {open ? <LeftArrowIcon /> : <RightArrowIcon />}
            </NavBarToggleButton>
          )}

          <NavList component="nav">
            {isMobile && <ContractSelectorButton contract={selectedContract} handleClick={handleChangeMenuLevel} />}

            <NavlistItem button onClick={isMobile ? handleDrawerClose : () => {}}>
              <NavItemLink exact to="/" className="nav-link dashboard-nav-link">
                <NavListIcon className="dashboard-nav-icon">
                  <DashboardIcon />
                </NavListIcon>
                <NavigationText open={open} disableTypography primary={t('dashboard')} delay={80} />
              </NavItemLink>
            </NavlistItem>
            {hasAssumedRole(constants.assumedRoles.MANAGER) && (
              <NavlistItem button onClick={isMobile ? handleDrawerClose : () => {}}>
                <NavItemLink exact to="/invitees" className="nav-link invitees-nav-link">
                  <NavListIcon className="invitees-nav-icon">
                    <InviteesListIcon />
                  </NavListIcon>
                  <NavigationText open={open} disableTypography primary={t('navigation:invites')} delay={80} />
                </NavItemLink>
              </NavlistItem>
            )}
            <NavlistItem button onClick={isMobile ? handleDrawerClose : () => {}}>
              <NavItemLink
                isActive={(_match, location) => {
                  return location?.pathname?.toString().startsWith(CONTRACTS);
                }}
                to={contractLink}
                className="nav-link contract-nav-link"
              >
                <NavListIcon className="contract-nav-icon">
                  <ContractIcon />
                </NavListIcon>
                <NavigationText
                  open={open}
                  disableTypography
                  primary={hasAssumedRole(constants.assumedRoles.MANAGER) ? t('navigation:contracts') : t('contract')}
                  delay={80}
                />
              </NavItemLink>
            </NavlistItem>
            <NavlistItem button onClick={isMobile ? handleDrawerClose : () => false}>
              <NavItemLink to={STATISTICS_ROUTES.overview} className="nav-link statistics-nav-link">
                <NavListIcon>
                  <StatsIcon />
                </NavListIcon>
                <NavigationText open={open} disableTypography primary={t('navigation:statistics')} delay={80} />
              </NavItemLink>
            </NavlistItem>
            {isMobile && (
              <NavlistItem button onClick={isMobile ? handleDrawerClose : () => false}>
                <NavItemLink exact to="/faq" className="nav-link faq-nav-link">
                  <NavListIcon>
                    <HelpIcon />
                  </NavListIcon>
                  <NavigationText open={open} disableTypography primary={t('navigation:faq')} delay={80} />
                </NavItemLink>
              </NavlistItem>
            )}
          </NavList>
          {isMobile && user && (
            <MobileUserProfile user={user} closeDrawer={handleDrawerClose} updateDrawerState={saveDrawerState} />
          )}
          <MerLogo open={open} />
        </AnimatedDrawer>
        <PageWrapper open={open}>{children}</PageWrapper>
      </NavContainer>
    </>
  );
}

export default NavigationBar;
