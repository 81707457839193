// @ts-nocheck
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Link as MailLink, useMediaQuery } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { useAuth0 } from '../../utils/Auth0Provider';
import MerLogoDesktop from '../Icons/MerLogoFull';
import MerLogoMobile from '../Icons/MerLogoShort';
import ActionDialog from '../ActionDialog';
import profileSecurity from '../../assets/images/profileSecurity.png';
import { INITIAL_PATH } from '../constants';

const HeaderWrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(4, 7)};
  width: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3, 5)};
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(2, 2, 5, 2)};
  }
`;

const LogoLink = styled(Link)`
  svg {
    width: auto;
    height: 64px;
  }
`;

const SecurityImage = styled.img`
  max-width: 12rem;
`;

const ContentLine = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const StyledLink = styled(MailLink)`
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(1, 0)};
`;

function GuestHeader() {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { loginWithRedirect, getErrorMessage } = useAuth0();
  const hasLoginError = getErrorMessage() === 'unauthorized';
  const [errorDialogOpen, setErrorDialogOpen] = useState(hasLoginError);
  const { hash, pathname, search } = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const loginHandler = () => {
    sessionStorage.setItem(INITIAL_PATH, pathname + search + hash);
    loginWithRedirect({});
  };

  const handleCloseDialog = () => {
    history.push('/');
    setErrorDialogOpen(false);
  };

  return (
    <HeaderWrapper>
      <LogoLink to="/" data-testid="header-logo">
        {isMobile ? <MerLogoMobile /> : <MerLogoDesktop />}
      </LogoLink>
      {hasLoginError ? (
        <ActionDialog
          open={errorDialogOpen}
          title={t('missing permissions title')}
          content={
            <>
              <ContentLine>{t('missing permissions text line 1')}</ContentLine>
              <ContentLine>
                {t('missing permissions text line 2')}
                <StyledLink href="mailto:service.plus@mer.eco">service.plus@mer.eco</StyledLink>
              </ContentLine>
            </>
          }
          image={<SecurityImage src={profileSecurity} alt={t('missing permissions title')} />}
          maxWidth="sm"
          confirmationButtonText={t('ok')}
          handleConfirmation={() => handleCloseDialog()}
          showCloseIcon
          hideCancel
          handleClose={() => handleCloseDialog()}
          disableFullscreen
        />
      ) : (
        <StyledButton variant="contained" color="primary" onClick={loginHandler}>
          {t('to login')}
        </StyledButton>
      )}
    </HeaderWrapper>
  );
}

export default GuestHeader;
