/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CookieConsent from 'react-cookie-consent';
import i18next from 'i18next';

import { useAuth0 } from '../../utils/Auth0Provider';
import googlePlay from '../../assets/images/googlePlay.png';
import SignetLogo from '../Icons/SignetLogo';
import AppStore from '../Icons/AppStore';
import appTheme from '../../config/appTheme';

const FooterContent = styled(Grid)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};

  .MuiAccordionSummary-root {
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(3, 5)};

    .MuiAccordionDetails-root {
      padding: ${(props) => props.theme.spacing(2, 0)};
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(4, 8)};
  }
`;

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  cursor: default;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
  padding-bottom: ${(props) => props.theme.spacing(1.5)}px;
  opacity: 0.7;
`;

const LinkToOtherWebsite = styled.a`
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
  padding-bottom: ${(props) => props.theme.spacing(1.5)}px;
  opacity: 0.7;
`;

const FooterCopyright = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  border-top: 1px solid ${(props) => props.theme.palette.primary.dark};
  padding: ${(props) => props.theme.spacing(3)}px;

  > p {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin: 0;
  }
  a {
    margin-left: ${(props) => props.theme.spacing(5)}px;
    text-decoration: none;
    color: ${(props) => props.theme.palette.common.white};
  }
  svg {
    width: 50px;
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    > p {
      margin-top: ${(props) => props.theme.spacing(2)}px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    a {
      margin-left: 0;
    }
  }

  ${(props) => props.theme.breakpoints.only('xs')} {
    > div {
      flex-direction: column;
    }
    a {
      margin: ${(props) => props.theme.spacing(0.5, 0)};
    }
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(1, 5)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(1, 8)};
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  box-shadow: none;
  border: none;
  margin: 0;

  &.Mui-expanded {
    border: none;
    margin: 0;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  padding: ${(props) => props.theme.spacing(0, 0, 2, 0)};

  ${(props) => props.theme.breakpoints.only('xs')} {
    padding: ${(props) => props.theme.spacing(1.5, 3)};
    border-top: 1px solid ${(props) => props.theme.palette.tertiary.main};
  }

  a {
    padding-bottom: 0;
  }

  svg {
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  ${(props) => props.theme.breakpoints.only('xs')} {
    padding: ${(props) => props.theme.spacing(0, 3, 1.5, 3)};
  }
`;

const DownloadSection = styled(Grid)`
  ${(props) => props.theme.breakpoints.only('xs')} {
    border-top: 1px solid ${(props) => props.theme.palette.tertiary.main};
    padding-top: ${(props) => props.theme.spacing(1)}px;

    > h4 {
      padding-left: ${(props) => props.theme.spacing(3)}px;
      padding-top: ${(props) => props.theme.spacing(1.5)}px;
    }

    > div {
      padding-left: ${(props) => props.theme.spacing(3)}px;
    }
  }
`;

const DownloadButtons = styled.div`
  svg {
    width: 111px;
  }
  img {
    width: 120px;
  }
  svg,
  img {
    fill: ${(props) => props.theme.palette.common.black};
    height: 37px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
    margin-top: ${(props) => props.theme.spacing(2)}px;

    ${(props) => props.theme.breakpoints.only('xs')} {
      margin-right: ${(props) => props.theme.spacing(1.5)}px;
      margin-bottom: ${(props) => props.theme.spacing(4)}px;
    }
  }
`;

const CookieStyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.common.white};
  opacity: 0.5;
`;

function Footer() {
  const { t } = useTranslation(['footer', 'translation', 'cookiePolicy']);
  const { isAuthenticated } = useAuth0();
  const [expandedSections, setExpandedSections] = useState<Array<string>>([]);
  const isEnglish = i18next?.language?.toLowerCase() === 'en';
  const aboutUs = isEnglish ? 'https://mer.eco/mer/' : 'https://de.mer.eco/mer/';
  const press = isEnglish ? 'https://mer.eco/news/' : 'https://de.mer.eco/news/presse/';
  const career = isEnglish ? 'https://mer.eco/mer/career/' : 'https://de.mer.eco/mer/karriere/';

  const desktop = window.innerWidth >= appTheme.breakpoints.width('sm');
  const accordionExpanded = desktop ? true : undefined;

  const handleAccordionClick = (panel: string) => {
    if (!expandedSections.includes(panel)) {
      setExpandedSections((oldArray) => [...oldArray, panel]);
    } else {
      setExpandedSections(expandedSections.filter((item) => item !== panel));
    }
  };

  const getExpandIcon = (panel: string) => {
    if (expandedSections.includes(panel)) {
      return <RemoveIcon />;
    }
    return <AddIcon />;
  };

  return (
    <div id="groa-footer">
      <FooterContent container>
        {isAuthenticated && (
          <StyledGrid item xs={12} sm={6} md={4}>
            <StyledAccordion expanded={accordionExpanded}>
              <StyledAccordionSummary
                data-testid="footer-service-section"
                expandIcon={!desktop && getExpandIcon('panel1')}
                aria-controls="panel1-content"
                onClick={() => handleAccordionClick('panel1')}
              >
                <SectionTitle variant="h4">{t('service')}</SectionTitle>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <StyledLink to="/faq">{t('translation:FAQ')}</StyledLink>
              </StyledAccordionDetails>
            </StyledAccordion>
          </StyledGrid>
        )}

        <StyledGrid item xs={12} sm={isAuthenticated ? 6 : 4} md={isAuthenticated ? 4 : 8}>
          <StyledAccordion expanded={accordionExpanded}>
            <StyledAccordionSummary
              data-testid="footer-company-section"
              aria-controls="panel2-content"
              onClick={() => handleAccordionClick('panel2')}
              expandIcon={!desktop && getExpandIcon('panel2')}
            >
              <SectionTitle variant="h4">{t('company')}</SectionTitle>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <LinkToOtherWebsite target="_blank" rel="noreferrer" href={aboutUs}>
                {t('about us')}
              </LinkToOtherWebsite>
              <LinkToOtherWebsite target="_blank" rel="noreferrer" href={press}>
                {t('press')}
              </LinkToOtherWebsite>
              <LinkToOtherWebsite target="_blank" rel="noreferrer" href={career}>
                {t('careers')}
              </LinkToOtherWebsite>
            </StyledAccordionDetails>
          </StyledAccordion>
        </StyledGrid>

        <DownloadSection item xs={12} sm={isAuthenticated ? 6 : 4} md={4}>
          <SectionTitle variant="h4">{t('app download')}</SectionTitle>
          <DownloadButtons>
            <a
              href="https://play.google.com/store/apps/details?id=com.eemobility.android"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={googlePlay} alt="google-play" />
            </a>
            <a href="https://itunes.apple.com/de/app/eemobility/id1287456872" rel="noopener noreferrer" target="_blank">
              <AppStore />
            </a>
          </DownloadButtons>
        </DownloadSection>
      </FooterContent>
      <FooterCopyright>
        <Typography variant="body1" paragraph>
          <SignetLogo /> &#169; Copyright 2020
        </Typography>
        <div>
          <Link to="/privacy">{t('privacy policy')}</Link>
          <Link to="/impressum">{t('legal notice')}</Link>
          <Link to="/cookies">{t('cookiePolicy:cookie policy')}</Link>
        </div>
      </FooterCopyright>
      <CookieConsent
        location="bottom"
        buttonText={t('translation:ok')}
        cookieName="cookieConsent"
        style={{ background: appTheme.palette.tertiary.dark, zIndex: '1201' }}
        buttonStyle={{
          background: appTheme.palette.primary.main,
          color: appTheme.palette.common.white,
          fontSize: '1.1rem',
        }}
      >
        {t('cookiePolicy:cookie consent')}
        <CookieStyledLink to="/cookies">[{t('cookiePolicy:further information')}]</CookieStyledLink>
      </CookieConsent>
    </div>
  );
}

export default Footer;
