import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import * as deICU from 'i18next-icu/locale-data/de';
import * as enICU from 'i18next-icu/locale-data/en';

import de from './locales/de';
import en from './locales/en';

const getLanguageSetting = () => {
  try {
    const languageSettings = localStorage.getItem('groalng');
    if (languageSettings === null) {
      return undefined;
    }
    return languageSettings;
  } catch (err) {
    return undefined;
  }
};

i18n
  .use(
    new ICU({
      localeData: [deICU, enICU],
    })
  )
  .use(initReactI18next)
  .init({
    resources: {
      de,
      en,
    },
    lng: getLanguageSetting() || 'de',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    keySeparator: false,
  });

export default i18n;
