// @ts-nocheck
import { Children, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  will-change: transform;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};

  transition: ${(props) =>
    props.theme.transitions.create(['transform', 'height'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.complex,
    })};
  transform: translate(${(props) => -props.index * 100}%, 0);
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Item = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`;

const Carousel = ({ adjustHeight, children, index, onChange }) => {
  const slideRefs = useRef([]);

  const [currentItem, setCurrentItem] = useState(index);
  const [currentHeight, setCurrentHeight] = useState();

  const numItems = Children.count(children);

  useEffect(() => {
    if (!adjustHeight) {
      return;
    }
    const activeChild = slideRefs.current[currentItem];
    if (activeChild && activeChild.offsetHeight !== undefined && currentHeight !== activeChild.offsetHeight) {
      setCurrentHeight(activeChild.offsetHeight);
    }
  }, [adjustHeight, currentHeight, currentItem]);

  useEffect(() => {
    if (index !== currentItem) {
      setCurrentItem(index);
    }
  }, [index, currentItem]);

  const slide = (dir) => {
    if (dir === 'prev' && currentItem !== 0) {
      const newPos = currentItem - 1;
      setCurrentItem(newPos);
      onChange(newPos);
    } else if (dir === 'next' && currentItem !== numItems - 1) {
      const newPos = currentItem + 1;
      setCurrentItem(newPos);
      onChange(newPos);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('next'),
    onSwipedRight: () => slide('prev'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper {...handlers}>
      <Container index={currentItem} height={currentHeight}>
        {Children.map(children, (child, i) => (
          /* eslint-disable react/no-array-index-key */
          /* eslint-disable no-return-assign */
          <Item key={i} aria-hidden={i !== currentItem} ref={(element) => (slideRefs.current[i] = element)}>
            {child}
          </Item>
        ))}
      </Container>
    </Wrapper>
  );
};

Carousel.defaultProps = {
  adjustHeight: false,
  index: 0,
  onChange: () => {},
  children: null,
};

Carousel.propTypes = {
  adjustHeight: PropTypes.bool,
  children: PropTypes.node,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

export default Carousel;
