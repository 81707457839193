// @ts-nocheck
import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Carousel from '../Carousel';
import DotIndicator from '../Carousel/DotIndicator';
import merStripes from '../../assets/images/mer-stripes.png';
import { LightbulbWhite } from '../Illustrations';
import { PopoverImage } from '../../layout/InfoPopover';

import InfoPopover from './InfoPopover';

const Content = styled.div`
  padding: ${(props) => (props.stepper ? props.theme.spacing(4, 4, 5) : props.theme.spacing(4, 4))};
  position: relative;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  background: url('${(props) => props.url}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: ${(props) => props.theme.spacing(7, 6, 6)};
`;

const Dots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(3)}px;
  right: ${(props) => props.theme.spacing(3)}px;

  svg {
    font-size: 1.2rem;
  }

  > button:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }

  > button {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

function InfoSlider({ content, images, open, onClose }) {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);

  const numberOfSteps = Math.min(content?.length, images?.length);
  const isSingleView = !Array.isArray(content);

  const stepNext = () => {
    setIndex((step) => {
      if (step === numberOfSteps - 1) {
        return 0;
      }
      return step + 1;
    });
  };

  const stepBack = () => {
    setIndex((step) => {
      if (step === 0) {
        return numberOfSteps - 1;
      }
      return step - 1;
    });
  };

  if (!content) {
    return null;
  }

  return (
    <InfoPopover open={open} onClose={onClose}>
      <Image url={merStripes}>
        <Carousel index={index} onChange={(i) => setIndex(i)}>
          {Array.isArray(images) ? images.map((element) => <Fragment key={element}>{element}</Fragment>) : images}
        </Carousel>
      </Image>
      <Content stepper={numberOfSteps > 1}>
        <Carousel adjustHeight index={index} onChange={(i) => setIndex(i)}>
          {Array.isArray(content) ? content.map((element) => <Fragment key={element}>{element}</Fragment>) : content}
        </Carousel>

        {!isSingleView && (
          <Dots>
            {Array.from(Array(numberOfSteps).keys()).map((i) => (
              <DotIndicator key={i} active={i === index} index={i} onClick={() => setIndex(i)} />
            ))}
          </Dots>
        )}
        {!isSingleView && (
          <Actions>
            <IconButton size="small" aria-label={t('back')} onClick={stepBack} disabled={index === 0}>
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton size="small" aria-label={t('next')} onClick={stepNext} disabled={index === numberOfSteps - 1}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Actions>
        )}
      </Content>
    </InfoPopover>
  );
}

InfoSlider.defaultProps = {
  images: <PopoverImage image={<LightbulbWhite />} />,
  content: null,
};

InfoSlider.propTypes = {
  images: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InfoSlider;
