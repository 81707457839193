import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { ReactNode } from 'react';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  color: ${(props) => props.theme.palette.common.white};

  > svg {
    height: 86px;
    width: auto;
  }
`;

const InfoPopoverHeading = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(1.5)}px;
`;

function PopoverImage({ image }: { image: ReactNode }) {
  return (
    <Wrapper>
      <ImageWrapper>{image}</ImageWrapper>
    </Wrapper>
  );
}

PopoverImage.defaultProps = {
  image: null,
};

PopoverImage.propTypes = {
  image: PropTypes.element,
};

interface PopoverContentProps {
  title: string;
  subtitle?: string;
  description: string | object;
}

function PopoverContent({ title, subtitle, description }: PopoverContentProps) {
  return (
    <>
      <InfoPopoverHeading variant="h5" color="primary">
        {title}
      </InfoPopoverHeading>
      {subtitle && (
        <Typography variant="body1" component="p" paragraph color="primary">
          {subtitle}
        </Typography>
      )}
      <Typography variant="body1" component="p" paragraph color="primary">
        {description}
      </Typography>
    </>
  );
}

PopoverContent.defaultProps = {
  subtitle: '',
};

export { PopoverImage, PopoverContent };
