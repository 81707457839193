// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Footer from '../../components/Footer';
import Header from '../../components/GuestHeader';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const PageContent = styled.div`
  background-color: ${(props) =>
    props.color === 'paper' ? props.theme.palette.background.paper : props.theme.palette.background.default};
  max-width: 100%;
  padding: ${(props) => (props.isLandingPage ? props.theme.spacing(0, 0) : props.theme.spacing(5, 8, 14))};
  flex-grow: 1;
  flex-shrink: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => (props.isLandingPage ? 0 : props.theme.spacing(3, 5, 10))};
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => (props.isLandingPage ? 0 : props.theme.spacing(3, 3, 6))};
  }
`;

function GuestPageLayout({ color, children }) {
  const isLandingPage = window.location.pathname === '/';

  return (
    <PageContainer>
      <Header />
      <PageContent color={color} isLandingPage={isLandingPage}>
        {children}
      </PageContent>
      <Footer />
    </PageContainer>
  );
}

GuestPageLayout.defaultProps = {
  color: 'default',
};

GuestPageLayout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};

export default GuestPageLayout;
