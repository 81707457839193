import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../utils/NotificationProvider';
import { inviteUser, resendInvitationToUser } from '../utils/api';
import { User } from '../utils/models';

import useErrorHandler from './useErrorHandler';

const useDriverInviteApi = () => {
  const { t } = useTranslation('invitees');
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { openSnackbar } = useNotification();

  const cancelRequest = useRef<boolean>();

  useEffect(() => {
    cancelRequest.current = false;
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const createInvitee = useCallback(
    async (organizationId: string, data: User, signupToken: string | null = null) => {
      setIsLoading(true);
      try {
        const inviteeData = await inviteUser(organizationId, data, signupToken);

        if (!cancelRequest.current) {
          setIsLoading(false);
        }
        return inviteeData.data;
      } catch (error) {
        errorHandler(error);

        if (!cancelRequest.current) {
          setIsLoading(false);
        }
        return null;
      }
    },
    [errorHandler]
  );

  const resendInvitation = useCallback(
    async (userId, userEmail) => {
      setIsLoading(true);
      try {
        const response = await resendInvitationToUser(userId);
        openSnackbar(
          t('invitation re-sent notification title'),
          t('invitation re-sent notification description', { email_address: userEmail })
        );
        if (!cancelRequest.current) {
          setIsLoading(false);
        }
        return response.data;
      } catch (error) {
        if (!cancelRequest.current) {
          setIsLoading(false);
        }

        errorHandler(error);
        return null;
      }
    },
    [errorHandler, openSnackbar, t]
  );

  return {
    createInvitee,
    resendInvitation,
    isLoading,
  };
};

export default useDriverInviteApi;
