// @ts-nocheck
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';

import CustomCheckbox from '../layout/CustomCheckbox';
import CustomRadio from '../layout/CustomRadio';

const defaultTheme: Theme = createMuiTheme();

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0000c8',
      dark: '#4d4dd9',
      light: '#9999e9',
      lighter: '#b3b3ef',
      lightest: '#ccccf4',
      bright: '#e6e6fa',
      brighter: '#f2f2fc',
      contrastText: '#fff',
    },
    secondary: {
      main: '#14e6e6',
      dark: '#5beeee',
      light: '#a1f5f5',
      lighter: '#b9f8f8',
      lightest: '#d0fafa',
      bright: '#e8fcfc',
      brighter: '#f3fefe',
      contrastText: '#fff',
    },
    tertiary: {
      darkest: '#000',
      darker: '#4d4d4d',
      dark: '#999',
      main: '#b3b3b3',
      light: '#ccc',
      lighter: '#e6e6e6',
      lightest: '#f2f2f2',
      bright: '#fafafa',
      brighter: '#f5f5f5',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#0000c8',
    },
    background: {
      default: '#fff',
      paper: '#fff', // @TODO: check if we can combine these two
    },
    common: {
      black: '#000',
      white: '#fff',
      gray: '#808080',
      green: '#40af0c',
      red: '#d2375d',
    },
    navbar: {
      main: '#f2f2f2',
      text: '#253081',
      activeText: '#253081',
    },
    status: {
      active: '#253081',
      inactive: '#c4c4c4',
    },
    statistics: {
      home: '#0000c8',
      business: '#14e6e6',
      public: '#3b9ac4',
      graphText: '#0EA146',
    },
  },
  typography: {
    fontFamily: '"Sharp Sans","Arial", sans-serif',
    fontWeight: 500,
    fontWeightLighter: 200,
    fontWeightLight: 300,
    fontWeightNormal: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    htmlFontSize: 12,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    // To be further defined
    h2: {
      fontSize: '2.4rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '2.3rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
    },
    h4: {
      fontSize: '1.35rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    h5: {
      fontSize: '1.165rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1.165rem',
      lineHeight: '2rem',
    },
    body2: {
      fontSize: '1rem',
      lineHeight: 1.8,
    },
    caption: {
      fontSize: '0.85rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1.35rem',
      fontWeight: 500,
      lineHeight: 1.8,
    },
    // subtitle2
  },
  general: {
    width: '100%',
    letterSpacing: '0.05em',
  },
  props: {
    MuiCheckbox: {
      icon: <CustomCheckbox />,
      checkedIcon: <CustomCheckbox checked />,
    },
    MuiRadio: {
      icon: <CustomRadio />,
      checkedIcon: <CustomRadio selected />,
    },
    MuiSelect: {
      IconComponent: ExpandMoreIcon,
      MenuProps: {
        elevation: 3,
      },
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
});

const overrides = (theme) => ({
  MuiTypography: {
    paragraph: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
  },
  MuiButton: {
    root: {
      fontSize: '1.2rem',
      fontWeight: 700,
      borderRadius: 0,
      textTransform: 'normal',
      boxShadow: 'none',
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      transition: theme.transitions.create(['color', 'border', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
    },
    label: {
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.05em',
    },
    contained: {
      padding: theme.spacing(1.5, 4),
      boxShadow: 'none',
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    containedPrimary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&$disabled': {
        color: theme.palette.tertiary.main,
        backgroundColor: theme.palette.tertiary.lightest,
      },
    },
    containedSizeSmall: {
      padding: theme.spacing(1, 2),
    },
    outlined: {
      padding: theme.spacing(1.5, 4),
      backgroundColor: 'transparent',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    outlinedPrimary: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      },
    },
    outlinedSecondary: {
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.common.white}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.dark,
          border: `2px solid ${theme.palette.common.white}`,
        },
      },
    },
    text: {
      padding: theme.spacing(1.5, 4),
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    textPrimary: {
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAccordion: {
    root: {
      marginBottom: theme.spacing(1),
      border: `1px solid ${theme.palette.primary.lightest}`,
      transition: theme.transitions.create('border', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
      '&$expanded': {
        margin: 0,
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 0,
    },
    elevation1: {
      boxShadow: 'none',
    },

    elevation8: {
      boxShadow: theme.shadows[6],
    },
  },
  MuiAccordionSummary: {
    root: {
      padding: theme.spacing(2, 3),
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create('background-color', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 4),
      },
      '&$expanded': {
        minHeight: 'auto',
        backgroundColor: theme.palette.secondary.main,
      },
    },
    content: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    expandIcon: {
      color: theme.palette.primary.main,
      padding: theme.spacing(1, 1),
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: theme.spacing(2, 3),
      flexDirection: 'column',
    },
  },
  MuiCollapse: {
    container: {
      '&.MuiCollapse-hidden': {
        display: 'none',
      },
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '1.35rem',
      margin: 0,
      padding: 0,
      color: theme.palette.primary.main,
    },
    input: {
      fontSize: '1.35rem',
      height: '1.6rem',
      lineHeight: '1.7rem',
      '&::placeholder': {
        color: theme.palette.primary.light,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 0,
      '& $notchedOutline': {
        borderColor: theme.palette.primary.lightest,
        borderWidth: 1,
        transition: theme.transitions.create(['border'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.complex,
        }),
      },
      '&:hover $notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.primary.lighter,
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
      },
    },
    input: {
      padding: theme.spacing(1.5, 2.5),
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(20px, 14px) scale(1)',
      '&$shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  },
  MuiSelect: {
    outlined: {
      '&&': {
        paddingRight: theme.spacing(6),
      },
    },
    icon: {
      fontSize: '1.4rem',
      top: 'calc(50% - 8px)',
      color: theme.palette.primary.main,
    },
    select: {
      '&:focus': {
        backgroundColor: 'inherit',
      },
    },
    selectMenu: {
      height: '1.4rem',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '1.35rem',
      color: theme.palette.primary.light,
    },
    filled: {
      color: theme.palette.primary.main,
    },
  },
  MuiFormControlLabel: {
    label: {
      color: theme.palette.primary.main,
    },
  },
  MuiList: {
    root: {
      color: theme.palette.primary.main,
    },
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      'min-width': 0,
    },
  },
  MuiStepper: {
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 3, 0, 0),
      backgroundColor: 'transparent',
      maxWidth: '700px',
      justifyContent: 'space-between',
    },
    alternativeLabel: {
      marginTop: theme.spacing(8),
    },
  },
  MuiStep: {
    alternativeLabel: {
      marginTop: -49,
    },
    horizontal: {
      padding: theme.spacing(0),
    },
  },
  MuiStepConnector: {
    alternativeLabel: {
      top: 'unset',
      bottom: 15,
      left: '-80%',
      right: '80%',
    },
    active: {
      '& $line': {
        borderColor: theme.palette.secondary.main,
        borderTopWidth: 3,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.secondary.main,
        borderTopWidth: 3,
      },
    },
    line: {
      borderColor: theme.palette.tertiary.lightest,
    },
  },
  MuiStepLabel: {
    root: {
      alignItems: 'flex-start',
      '&.Mui-disabled': {
        '& $label': {
          color: theme.palette.tertiary.light,
        },
      },
      '&$alternativeLabel': {
        flexDirection: 'column-reverse',
      },
    },
    label: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      '&$alternativeLabel': {
        marginTop: 0,
        marginBottom: theme.spacing(1.5),
        textAlign: 'left',
      },
      '&$active': {
        color: theme.palette.primary.main,
      },
      '&$completed': {
        color: theme.palette.primary.main,
      },
    },
    iconContainer: {
      marginLeft: theme.spacing(3),
    },
  },
  MuiStepIcon: {
    root: {
      color: theme.palette.primary.light,
      width: '1.1em',
      height: '1.1em',
    },
  },
  MuiCircularProgress: {
    circle: {
      strokeLinecap: 'round',
    },
  },
  MuiDialog: {
    paper: {
      backgroundColor: theme.palette.background.white,
    },
    paperFullScreen: {
      margin: theme.spacing(0.5),
    },
  },
  MuiDialogTitle: {
    root: {
      textAlign: 'center',
      padding: theme.spacing(3, 2, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4, 4, 2),
      },
    },
  },
  MuiDialogContent: {
    root: {
      margin: theme.spacing(0),
      padding: theme.spacing(1, 2, 1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4, 1),
      },
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'center',
      padding: theme.spacing(1, 2, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 4, 4),
      },
    },
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(3),
      },
    },
  },
  MuiSnackbar: {
    root: {
      [theme.breakpoints.down('sm')]: {
        left: 0,
        right: 0,
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: theme.palette.common.white,
      alignItems: 'flex-start',
      padding: theme.spacing(3, 3),
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        padding: theme.spacing(0.5, 3, 3),
      },
    },
    message: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    action: {
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.1rem',
      },
    },
  },
  MuiAutocomplete: {
    paper: {
      boxShadow: theme.shadows[3],
    },
    option: {
      color: theme.palette.primary.dark,
      minHeight: 40,
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 40,
      },
    },
    inputRoot: {
      '&&': {
        paddingTop: 3,
        paddingBottom: 2,
      },
    },
    popupIndicator: {
      color: theme.palette.primary.main,
      fontSize: '1.4rem',
      '& .MuiSvgIcon-root': {
        fontSize: 'inherit',
      },
    },
    clearIndicator: {
      color: theme.palette.primary.main,
    },
  },
  MuiTable: {
    root: {
      borderCollapse: 'collapse',
      borderSpacing: theme.spacing(0, 1),
    },
  },
  MuiTableHead: {
    root: {
      borderBottom: `1px solid ${theme.palette.tertiary.lightest}`,
    },
  },
  MuiTableCell: {
    root: {
      fontSize: '1rem',
      borderBottom: 'none',
      padding: theme.spacing(4, 3),
    },
    head: {
      color: theme.palette.tertiary.dark,
      paddingBottom: theme.spacing(2),
    },
    body: {
      color: theme.palette.tertiary.darker,
      backgroundColor: theme.palette.common.white,
    },
  },
  MuiTableRow: {
    root: {
      minHeight: theme.spacing(12),
    },
    head: {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },
  MuiCardHeader: {
    root: {
      color: theme.palette.primary.main,
    },
  },
  MuiTableSortLabel: {
    root: {
      '&$active': {
        color: theme.palette.tertiary.dark,
      },
    },
    icon: {
      height: '0.7em',
      color: theme.palette.primary.light,
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    arrow: {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    tooltipPlacementBottom: {
      marginTop: '0 !important',
    },
  },
});

const customShadows = (theme) => {
  const { shadows } = theme;
  shadows[1] = '0px 1px 2px rgba(0, 0, 0, 0.06)';
  shadows[3] = '0px 8px 16px rgba(0, 0, 0, 0.12)';
  shadows[6] = '0px 16px 24px rgba(0, 0, 0, 0.08)';
  shadows[24] = '0px 16px 24px rgba(0, 0, 0, 0.08)';
  return shadows;
};

interface ExtendedPalette extends Palette {
  statistics: typeof appTheme.statistics;
  primary: typeof appTheme.primary;
  secondary: typeof appTheme.secondary;
  tertiary: typeof appTheme.tertiary;
  common: typeof appTheme.common;
  navbar: typeof appTheme.navbar;
}

export interface ExtendedTheme extends Theme {
  palette: ExtendedPalette;
}

export default createMuiTheme({
  ...appTheme,
  shadows: customShadows(appTheme),
  overrides: overrides(appTheme),
}) as ExtendedTheme;
