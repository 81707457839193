import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { StatisticalSubValues } from '../../../utils/models';
import SavingsIcon from '../../Icons/SavingsIcon';
import HomeIcon from '../../Icons/HomeIcon';
import CompanyIcon from '../../Icons/CompanyIcon';
import PublicIcon from '../../Icons/PublicIcon';

// Base for tree calculation: One tree can absorb 500 kg of CO2 in its lifetime
// https://www.naturefund.de/en/information/co2_calculator/data
const AVERAGE_CO2_ABSORPTION_PER_TREE = 500;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  max-width: 370px;
  margin-top: ${(props) => props.theme.spacing(5)}px;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const Text = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)}px;
  text-align: center;
`;

const IconContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    height: 32px;
    width: 32px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const Value = styled(Typography)<{ component: 'span' }>`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 16px;
  line-height: 26px;
`;

const Unit = styled(Typography)<{ component: 'span' }>`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 10px;
  line-height: 26px;
`;

function formatValue(value: number, maximumFractionDigits = 1): string {
  return new Intl.NumberFormat(i18next.language.toLowerCase() === 'en' ? 'en-GB' : 'de-DE', {
    maximumFractionDigits,
  }).format(value);
}

function calculateTrees(data: StatisticalSubValues): number {
  return ((data.private || 0) + (data.business || 0) + (data.public || 0)) / AVERAGE_CO2_ABSORPTION_PER_TREE;
}

interface Co2SavingsProps {
  data: StatisticalSubValues;
}

function Co2Savings({ data }: Co2SavingsProps) {
  const { t } = useTranslation('translation');

  const trees = calculateTrees(data);

  const getFormattedValue = (value: number) => {
    const numberOfFractionDigits = value < 1000 ? 1 : 0;

    return formatValue(value, numberOfFractionDigits);
  };

  const savingsData = [
    {
      value: getFormattedValue(data.private || 0),
      icon: <HomeIcon />,
      title: t('at home'),
    },
    {
      value: getFormattedValue(data.business || 0),
      icon: <CompanyIcon />,
      title: t('business site'),
    },
    {
      value: getFormattedValue(data.public || 0),
      icon: <PublicIcon />,
      title: t('on the road'),
    },
  ];

  return (
    <Wrapper>
      <SavingsIcon value={formatValue(trees, 2)} />
      <Grid container spacing={2} justify="center" alignItems="flex-start">
        {savingsData.map(({ value, icon, title }) => {
          return (
            <Tooltip title={title} arrow placement="bottom" key={title}>
              <IconContainer item md={4} xs={4}>
                {icon}
                <Text>
                  <Value component="span">{`${value} `}</Value>
                  <Unit component="span">{t('kilogram unit')}</Unit>
                </Text>
              </IconContainer>
            </Tooltip>
          );
        })}
      </Grid>
    </Wrapper>
  );
}

export default Co2Savings;
