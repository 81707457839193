import styled from 'styled-components';

import co2Savings from '../../assets/icons/co2Savings.svg';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.brighter};
  background-image: url(${co2Savings});
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 134px; // height of background svg
  margin-bottom: 20px;
`;

const Value = styled.p`
  color: ${(props) => props.theme.palette.statistics.graphText};
  font-size: 24px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  line-height: 1.2;
  margin: 20px 0 0;
  padding: 0;
  text-align: center;
`;

export default function ({ value }: { value: string }) {
  return (
    <Wrapper>
      <Value>{value}</Value>
    </Wrapper>
  );
}
