// @ts-nocheck
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth0 } from '../../utils/Auth0Provider';
import PageLoadingSpinner from '../../layout/PageLoadingSpinner';
import PageLayout from '../../layout/PageLayout';
import Dashboard from '../Dashboard';
import LandingPage from '../LandingPage';
import { INITIAL_PATH } from '../constants';
import { FOLLOW_UP_CONTRACTS } from '../../config/routes';

function HomePage() {
  const { isAuthenticated, loading } = useAuth0();
  const history = useHistory();
  const { hash, pathname, search } = useLocation();
  const { loginWithRedirect } = useAuth0();

  if (loading) {
    return <PageLoadingSpinner />;
  }

  if (
    !isAuthenticated &&
    ((pathname === '/faq' && hash === '#video') || pathname?.toLowerCase() === FOLLOW_UP_CONTRACTS.toLowerCase())
  ) {
    sessionStorage.setItem(INITIAL_PATH, pathname + search + hash);
    loginWithRedirect({});
    return <PageLoadingSpinner />;
  }

  if (isAuthenticated) {
    const initialPath = sessionStorage.getItem(INITIAL_PATH);
    sessionStorage.removeItem(INITIAL_PATH);

    if (initialPath && initialPath !== '/') {
      history.replace(initialPath);
    } else if (hash || pathname !== '/' || search) {
      history.push('/');
    }
    return (
      <PageLayout>
        <Dashboard />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <LandingPage />
    </PageLayout>
  );
}

export default HomePage;
