// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LandingpageAccordionIcon from '../Icons/LandingpageAccordionIcon';

const StyledAccordion = styled(Accordion)`
  border: none;
  color: ${(props) => props.theme.palette.primary.main};
  transition: ${(props) =>
    props.theme.transitions.create('background-color', {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};

  &.Mui-expanded {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: none;
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: ${(props) => props.theme.spacing(1.5, 3)};
  background-color: ${(props) => props.theme.palette.tertiary.bright};

  .MuiAccordionSummary-content svg {
    display: none;
  }

  .MuiAccordionSummary-expandIcon svg {
    color: ${(props) => props.theme.palette.primary.main};
  }

  p {
    font-size: 1.35rem;
    transition: ${(props) =>
      props.theme.transitions.create('font-size', {
        easing: props.theme.transitions.easing.easeInOut,
        duration: props.theme.transitions.duration.short,
      })};
  }

  &.Mui-expanded {
    background-color: ${(props) => props.theme.palette.primary.main};

    .MuiAccordionSummary-content svg {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      color: ${(props) => props.theme.palette.primary.main};
      margin-right: ${(props) => props.theme.spacing(2)}px;
    }

    .MuiAccordionSummary-expandIcon svg {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }

    p {
      font-size: 2rem;
    }
  }
`;

const Title = styled(Typography)`
  margin: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.3rem;
    font-weight: normal;
  }
`;

const Description = styled(Typography)`
  line-height: 2.4rem;
  font-size: 1.35rem;
  margin: 0;

  &:not(:last-child) {
    padding-bottom: ${(props) => props.theme.spacing(3)}px;
  }
`;

function LandingPageAccordion({ title, description, expanded }) {
  return (
    <StyledAccordion defaultExpanded={expanded} square>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title>
          <LandingpageAccordionIcon />
          {title}
        </Title>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Description variant="subtitle1">{description}</Description>
      </AccordionDetails>
    </StyledAccordion>
  );
}

LandingPageAccordion.defaultProps = {
  expanded: false,
};

LandingPageAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
};

export default LandingPageAccordion;
