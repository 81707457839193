import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Wallbox } from '../../Illustrations';
import { CONTRACTS } from '../../../config/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.palette.primary.bright};
  padding: ${(props) => props.theme.spacing(8)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    height: fit-content;
    padding: ${(props) => props.theme.spacing(3)}px;
  }
`;

const IllustrationWrapper = styled.div`
  max-width: 33.3334%;
  width: 288px;
  flex: 0 0 288px;
  margin-right: 64px;

  > svg {
    fill: ${(props) => props.theme.palette.primary.main};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: none;
    width: 170px;
    flex: auto;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightNormal};
  text-transform: initial;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ContentDescription = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const StyledButton = styled(Button)`
  max-width: 350px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

function CreateNewContractWidget() {
  const { t } = useTranslation('processwidget');
  const history = useHistory();

  const handleCreateContract = () => {
    history.push(`${CONTRACTS}#step1`);
  };

  return (
    <Wrapper className="process-widget">
      <IllustrationWrapper>
        <Wallbox />
      </IllustrationWrapper>
      <Description>
        <ContentTitle variant="h2" color="primary">
          {t('start today title')}
        </ContentTitle>
        <ContentDescription color="primary">{t('start today content 1')}</ContentDescription>
        <ContentDescription color="primary">{t('start today content 2')}</ContentDescription>
        <StyledButton onClick={handleCreateContract} variant="contained" color="primary">
          {t('start today button')}
        </StyledButton>
      </Description>
    </Wrapper>
  );
}

export default CreateNewContractWidget;
