import constants from '../config/constants';

import { setupAuth0Client } from './Auth0Provider';

const roleNamespace = `${process.env.REACT_APP_AUTH_0_NAMESPACE}roles`;

let userRoles = '';
let assumedRole: string | null = '';

// Only needed for tests
export function setUserRoles(value: string) {
  userRoles = value;
}

async function initializeUser() {
  if (!userRoles) {
    try {
      const auth0Client = await setupAuth0Client();
      const auth0User = await auth0Client.getUser();

      if (auth0User) {
        setUserRoles(auth0User[roleNamespace]);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

function translateAsummedRoleToAuth0Role(role: string) {
  if (role === constants.assumedRoles.MANAGER) {
    return constants.roles.ROLE_FLEET_MANAGER;
  }
  if (role === constants.assumedRoles.DRIVER) {
    return constants.roles.ROLE_DRIVER;
  }
  if (role === constants.assumedRoles.USER) {
    return constants.roles.ROLE_GROA_USER;
  }
  return '';
}

function translateAuth0RoleToAssumedRole(role: string) {
  if (role === constants.roles.ROLE_FLEET_MANAGER) {
    return constants.assumedRoles.MANAGER;
  }
  if (role === constants.roles.ROLE_DRIVER) {
    return constants.assumedRoles.DRIVER;
  }
  if (role === constants.roles.ROLE_GROA_USER) {
    return constants.assumedRoles.USER;
  }
  return '';
}

function verifyAssumedRole(role: string | null, availableRoles = userRoles) {
  let checkRole = role || '';
  if (!checkRole.startsWith('ROLE_')) {
    checkRole = translateAsummedRoleToAuth0Role(checkRole);
  }
  return availableRoles.indexOf(checkRole) >= 0;
}

function updateAssumedRole(newRole: string) {
  try {
    if (newRole && verifyAssumedRole(newRole, userRoles)) {
      assumedRole = newRole;
      localStorage.setItem('assumedRole', assumedRole);
    }
  } catch (err) {
    // ignore write errors
  }
  return assumedRole;
}

async function initializeAssumedRole() {
  let role = '';
  if (userRoles.indexOf(constants.roles.ROLE_FLEET_MANAGER) >= 0) {
    role = constants.assumedRoles.MANAGER;
  } else if (userRoles.indexOf(constants.roles.ROLE_DRIVER) >= 0) {
    role = constants.assumedRoles.DRIVER;
  } else if (userRoles.indexOf(constants.roles.ROLE_GROA_USER) >= 0) {
    role = constants.assumedRoles.USER;
  }
  updateAssumedRole(role);
  return role;
}

async function getAssumedRole(): Promise<string | null> {
  try {
    await initializeUser();

    if (!assumedRole) {
      assumedRole = localStorage.getItem('assumedRole');
      if (!verifyAssumedRole(assumedRole, userRoles)) {
        assumedRole = null;
      }
    }

    if (!assumedRole) {
      assumedRole = await initializeAssumedRole();
    }
    return assumedRole;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
}

export {
  getAssumedRole,
  updateAssumedRole,
  verifyAssumedRole,
  roleNamespace,
  translateAsummedRoleToAuth0Role,
  translateAuth0RoleToAssumedRole,
};
