import { Button, Link as MailLink, Typography } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mutate } from 'swr';

import { isFinalState } from '../ProcessWidgetUtils';
import { formatTimeLocal as formatTime } from '../../../../utils/timeHelper';
import { dateFormat } from '../../../Contracts/ContractDetail/ContractDetail.parts';
import ActiveContractIcon2 from '../../../Icons/ActiveContractIcon2';
import CalendarIcon from '../../../Icons/CalendarIcon';
import CheckIcon from '../../../Icons/CheckIcon';
import DraftContractIcon2 from '../../../Icons/DraftContractIcon2';
import FaqIcon from '../../../Icons/FaqIcon';
import StationIcon from '../../../Icons/StationIcon';
import { SignupSuccessExtended } from '../../../Illustrations';
import { hideWidget } from '../../DashboardStorageUtils';
import { Contract } from '../../../../utils/models';

const Step = styled.div<{ activestep?: number; laststep: number; completed: number; firststep?: number }>`
  position: relative;
  z-index: ${(props) => (props.activestep ? 2 : 1)};
  padding-bottom: ${(props) => (props.laststep ? 0 : props.theme.spacing(3.5))}px;

  :before {
    content: '';
    position: absolute;
    display: ${(props) => (props.firststep ? 'none' : 'block')};
    height: 44px;
    z-index: -1;
    border: ${(props) =>
      props.completed
        ? `2px solid ${props.theme.palette.secondary.main}`
        : `1px solid ${props.theme.palette.tertiary.lightest}`};
    top: -36px;
    left: ${(props) => (props.completed ? 6 : 7)}px;
  }
`;
const StepLabel = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`;

const StepTitle = styled(Typography)<{ activestep?: number; completed: number; component: string }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.completed ? props.theme.palette.primary.main : props.theme.palette.tertiary.main)};
  font-weight: ${(props) => (props.activestep ? props.theme.typography.fontWeightBold : 'normal')};
  margin-bottom: ${(props) => (props.activestep ? props.theme.spacing(1) : 0)}px;
  font-size: ${(props) => (props.activestep ? '1.35rem' : '1rem')};
`;

const StepIconWrapper = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 50px;
  margin-right: ${(props) => props.theme.spacing(1)}px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  svg {
    width: 10px;
    height: auto;
    margin: auto;
  }
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.secondary.bright};
  padding: ${(props) => props.theme.spacing(3)}px;
`;

const IllustrationWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1.5, 0, 8)};
  align-self: center;

  svg {
    height: 140px;
  }
`;

const CheckIconWrapper = styled.div<{ completed: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.completed ? props.theme.palette.secondary.main : props.theme.palette.tertiary.lightest};
  border-radius: 50px;
  width: ${(props) => props.theme.spacing(2)}px;
  height: ${(props) => props.theme.spacing(2)}px;
  margin-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    width: ${(props) => props.theme.spacing(1)}px;
    height: ${(props) => props.theme.spacing(1)}px;
  }
`;

const FinishButton = styled(Button)`
  max-width: 340px;
  width: 100%;
  align-self: center;
`;
interface FinalStepProps {
  contract: Contract;
  stationState?: string;
  setFinalStepInProgress?(...args: unknown[]): unknown;
}

const StyledLink = styled(MailLink)`
  text-decoration: underline;
`;

function FinalStep({ contract, stationState, setFinalStepInProgress }: FinalStepProps) {
  const history = useHistory();
  const { t } = useTranslation('processwidget');
  const [activeStep, setActiveStep] = useState(0); // starts from 0
  if (setFinalStepInProgress) {
    setFinalStepInProgress(activeStep === 5);
  }

  const scrollToDocuments = () => {
    document.getElementById('availableDocuments')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const steps = [
    {
      label: t('step 3.1 title'),
      description: t('step 3.1 description'),
    },
    {
      label: t('step 3.2 title'),
      description: t(
        contract?.digitalPrecheckStatus === 'APPROVED' ? 'step 3.2 description 1' : 'step 3.2 description 2'
      ),
    },
    {
      label: t('step 3.3 title'),
      description: <Trans t={t} i18nKey="step 3.3 description" components={{ Link: <Link to="/faq#video" /> }} />,
    },
    {
      label: t('step 3.4 title'),
      description: (
        <>
          {t('step 3.4 description') +
            formatTime(contract?.wallboxInstallationDate, dateFormat) +
            t('step 3.4 description part 2')}
          <br />
          <br />
          <Trans
            t={t}
            i18nKey="step 3.4 description part 3"
            components={{ Link: <Link to="/#availableDocuments" onClick={scrollToDocuments} /> }}
          />
          <Trans t={t} i18nKey="step 3.4 description part 4" components={{ Link: <Link to="/faq#video" /> }} />
        </>
      ),
    },
    {
      label: t('step 3.5 title'),
      description: <Trans t={t} i18nKey="step 3.5 description" components={{ Link: <StyledLink /> }} />,
    },
    {
      label: t('step 3.6 title'),
      description: t('step 3.6 description 1'),
    },
  ];

  useEffect(() => {
    mutate(`getStationByUuid-${contract?.homeStationUuid}`);
  }, [contract?.homeStationUuid]);

  useEffect(() => {
    if (setFinalStepInProgress) {
      setFinalStepInProgress(activeStep === 5);
    }
  }, [activeStep, setFinalStepInProgress]);

  useEffect(() => {
    const isStationOperative = stationState === 'OPERATIVE';
    const hasInstallationDate = contract?.wallboxInstallationDate;
    const arePrerequisitesFulfilled = contract?.prerequisitesFulfilled;
    const isDigitalPrecheckDone =
      contract?.digitalPrecheckStatus === 'APPROVED' || contract?.digitalPrecheckStatus === 'MANUAL_PRECHECK';
    const isContractActive = contract?.contractStatus !== 'ACTIVE';
    const isDigitalPrecheckSubmitted = contract?.digitalPrecheckStatus === 'SUBMITTED';
    const isBuildingConsentUploaded = contract?.documents?.length && contract?.documents?.length > 0;

    if (isFinalState(contract?.contractStatus, stationState)) {
      setActiveStep(5);
    } else if (isStationOperative && isContractActive) {
      setActiveStep(4);
    } else if (isDigitalPrecheckSubmitted && isBuildingConsentUploaded) {
      setActiveStep(0);
    } else if (isDigitalPrecheckDone && !arePrerequisitesFulfilled) {
      setActiveStep(1);
    } else if (arePrerequisitesFulfilled && !hasInstallationDate) {
      setActiveStep(2);
    } else if (hasInstallationDate && !isStationOperative) {
      setActiveStep(3);
    }
  }, [
    contract?.contractStatus,
    contract?.digitalPrecheckStatus,
    contract?.documents?.length,
    contract?.prerequisitesFulfilled,
    contract?.wallboxInstallationDate,
    stationState,
  ]);

  const getStepIcon = () => {
    switch (activeStep) {
      case 0:
        return <DraftContractIcon2 />;
      case 1:
        return <FaqIcon />;
      case 2:
        return <CheckIcon />;
      case 3:
        return <CalendarIcon />;
      case 4:
        return <StationIcon />;
      default:
        return <ActiveContractIcon2 />;
    }
  };

  const handleHideOnboardingWidget = () => {
    if (contract?.id != null) {
      hideWidget(contract?.id);
    }
    history.go(0);
  };

  function getStepCompleted(index: number) {
    return index <= activeStep ? 1 : 0;
  }

  return (
    <>
      {isFinalState(contract?.contractStatus, stationState) ? (
        <>
          <Step completed={1} laststep={1}>
            <StepContent data-testid="stepContent6">
              <StepTitle component="span" completed={1} activestep={1}>
                <StepIconWrapper>{getStepIcon()}</StepIconWrapper>
                {t('step 3.6 title')}
              </StepTitle>
              <Typography color="primary">
                {t('step 3.6 description 1')} <Link to="/faq">{t('step 3.6 description 2')}</Link>
              </Typography>
              <IllustrationWrapper>
                <SignupSuccessExtended />
              </IllustrationWrapper>
              <FinishButton variant="contained" color="primary" onClick={() => handleHideOnboardingWidget()}>
                {t('step 3.6 finish button')}
              </FinishButton>
            </StepContent>
          </Step>
        </>
      ) : (
        <>
          {steps.map((step, index) => (
            <Step
              key={step.label}
              completed={getStepCompleted(index)}
              firststep={index === 0 ? 1 : 0}
              laststep={index === 5 ? 1 : 0}
              activestep={activeStep === index ? 1 : 0}
            >
              {index !== activeStep ? (
                <StepLabel>
                  <CheckIconWrapper completed={getStepCompleted(index)}>
                    {index < activeStep && <Check />}
                  </CheckIconWrapper>

                  <StepTitle component="span" completed={getStepCompleted(index)} variant="body2">
                    {step.label}
                  </StepTitle>
                </StepLabel>
              ) : (
                <StepContent data-testid={`stepContent${index}`}>
                  <StepTitle component="span" completed={1} activestep={1}>
                    <StepIconWrapper>{getStepIcon()}</StepIconWrapper>
                    {step.label}
                  </StepTitle>
                  <Typography color="primary">{step.description}</Typography>
                </StepContent>
              )}
            </Step>
          ))}
        </>
      )}
    </>
  );
}

FinalStep.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    contractStatus: PropTypes.string,
    digitalPrecheckStatus: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    prerequisitesFulfilled: PropTypes.bool,
    wallboxInstallationDate: PropTypes.string,
    homeStationUuid: PropTypes.string,
  }).isRequired,
  stationState: PropTypes.string,
  setFinalStepInProgress: PropTypes.func,
};

FinalStep.defaultProps = {
  stationState: undefined,
  setFinalStepInProgress: undefined,
};

export default FinalStep;
