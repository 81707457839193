import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Title = styled(Typography)`
  font-size: 1.7rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: 2rem;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const Content = styled(Typography)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: ${(props) => props.theme.spacing(6)}px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.palette.primary.main};

  svg {
    width: 10px;
    margin-left: ${(props) => props.theme.spacing(0.75)}px;
  }
`;

function FaqCard() {
  const { t } = useTranslation('dashboard');

  return (
    <>
      <Title>{t('FAQ')}</Title>
      <Content>{t('faq content')}</Content>
      <StyledLink to="/faq">
        {t('faq link')} <ArrowForwardIcon />
      </StyledLink>
    </>
  );
}

export default FaqCard;
