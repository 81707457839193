import styled from 'styled-components';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { PropsWithChildren } from 'react';

const CircularProgressButton = styled(CircularProgress)`
  position: absolute;
`;

interface LoadingButtonProps extends ButtonProps {
  disabled?: boolean;
  isLoading: boolean;
}

const defaultProps = {
  disabled: false,
};

function LoadingButton({
  children,
  isLoading,
  disabled = defaultProps.disabled,
  ...allTheProps
}: PropsWithChildren<LoadingButtonProps>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isLoading || disabled} {...allTheProps}>
      {children}
      {isLoading && <CircularProgressButton color="primary" size={20} />}
    </Button>
  );
}

LoadingButton.defaultProps = defaultProps;

export default LoadingButton;
