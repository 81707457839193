// @ts-nocheck
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PageLayout from '../../layout/PageLayout';

const StyledText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const Title = styled(Typography)`
  margin: 0 0 2rem;
`;

function CookiePolicyDE() {
  const { t } = useTranslation('cookiePolicy');
  return (
    <PageLayout>
      <Title variant="h1" color="primary">
        {t('cookie policy')}
      </Title>
      <Typography variant="h3">1. Einführung</Typography>
      <StyledText varaint="body1" paragraph>
        Unser Kundenportal, <Link href="https://groa.mer.eco">https://groa.mer.eco</Link> (im folgenden:
        &ldquo;groa&rdquo;, “Kundenportal” oder “Website”) verwendet Cookies. Diese werden auch von uns beauftragten
        Drittparteien platziert. In dem unten stehendem Dokument informieren wir über die Verwendung von Cookies auf
        unserer Website.
      </StyledText>
      <Typography variant="h3">2. Was sind Cookies?</Typography>
      <StyledText varaint="body1" paragraph>
        Ein Cookie ist eine einfache kleine Datei, die gemeinsam mit den Seiten einer Internetadresse versendet und vom
        Webbrowser auf dem PC oder einem anderen Gerät gespeichert werden kann. Die darin gespeicherten Informationen
        können während folgender Besuche zu unseren oder den Servern relevanter Drittanbieter gesendet werden.
      </StyledText>
      <Typography variant="h3">3. Zustimmung</Typography>
      <StyledText varaint="body1" paragraph>
        Wenn du unsere Website zum ersten Mal besuchst, wird dir ein Popup mit einer Erklärung zu Cookies angezeigt.
        Sobald du auf &ldquo;Zustimmen&rdquo; klickst, stimmst du zu, dass wir alle Cookies verwenden, die im Popup und
        in dieser Cookie-Erklärung beschrieben sind. Du kannst die Verwendung von Cookies über deinen Browser
        deaktivieren. Beachte jedoch, dass unsere Website möglicherweise nicht mehr ordnungsgemäß funktioniert.
      </StyledText>
      <Typography variant="h3">4. Cookies</Typography>
      <Typography variant="h3">4.1 Technische oder funktionelle Cookies</Typography>
      <StyledText varaint="body1" paragraph>
        Einige Cookies stellen sicher, dass Teile groas richtig funktionieren und deine Nutzervorlieben bekannt bleiben.
        Durch das Platzieren funktionaler Cookies machen wir es dir einfacher groa zu besuchen. Auf diese Weise musst du
        bei Besuchen groas nicht wiederholt die gleichen Informationen eingeben. Wir können diese Cookies ohne dein
        Einverständnis platzieren.
      </StyledText>
      <StyledText varaint="body1" paragraph>
        Verwendete Cookies: <br />
        Authentication Cookie
      </StyledText>
      <Typography variant="h3">4.2 Analysecookies</Typography>
      <StyledText varaint="body1" paragraph>
        Wir verwenden keine analytischen Cookies.
      </StyledText>
      <Typography variant="h3">4.3 Werbecookies</Typography>
      <StyledText varaint="body1" paragraph>
        Wir verwenden keine Werbecookies.
      </StyledText>
      <Typography variant="h3">5. Aktivierung/Deaktivierung und Löschen von Cookies</Typography>
      <StyledText varaint="body1" paragraph>
        Du kannst deinen Internetbrowser verwenden, um Cookies automatisch oder manuell zu löschen. Du kannst auch
        festlegen, dass bestimmte Cookies möglicherweise nicht platziert werden. Eine weitere Möglichkeit besteht darin,
        die Einstellungen deines Internetbrowsers so zu ändern, dass du jedes Mal eine Nachricht erhältst, wenn ein
        Cookie gesetzt wird. Weitere Informationen zu diesen Optionen findest du in den Anweisungen im Hilfebereich
        deines Browsers.
        <br />
        Bitte nimm zur Kenntnis, dass groa möglicherweise nicht richtig funktioniert wenn alle Cookies deaktiviert sind.
        Wenn du die Cookies in deinem Browser löschst, werden diese neu platziert wenn du unsere Website erneut
        besuchst.
      </StyledText>
      <Typography variant="h3">6. Kontaktdaten</Typography>
      <StyledText varaint="body1" paragraph>
        Für Fragen und/oder Kommentare über unsere Cookie-Richtlinien und diese Aussage kontaktiere uns bitte mittels
        der folgenden Kontaktdaten: <br />
        Mer Germany GmbH <br />
        Taunusstraße 23, 80807 München <br />
        Deutschland <br />
      </StyledText>
      <StyledText varaint="body1" paragraph>
        Website: <Link href="https://de.mer.eco">https://de.mer.eco</Link> <br />
        Email: <Link href="mailto:service.plus@mer.eco">service.plus@mer.eco</Link> <br />
        Telefonnummer: +49 89 80913300
      </StyledText>
    </PageLayout>
  );
}

export default CookiePolicyDE;
