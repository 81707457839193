// @ts-nocheck
import BannerSection from './BannerSection';
import WhatWeDoSection from './WhatWeDoSection';
import PortalSection from './OurPortalSection';
import ProductSection from './OurProductsSection';
import MobileAppSection from './MobileAppSection';

function LandingPage() {
  return (
    <>
      <BannerSection />
      <WhatWeDoSection />
      <PortalSection />
      <MobileAppSection />
      <ProductSection />
    </>
  );
}

export default LandingPage;
