import styled from 'styled-components/macro';
import { Avatar } from '@material-ui/core';

const StyledAvatar = styled(Avatar)<{ open: boolean }>`
  width: 2.5rem;
  height: 2.5rem;

  border: ${(props) => (props.open === true ? `2px solid ${props.theme.palette.secondary.main}` : 'none')};
`;

interface UserAvatarProps {
  user: {
    name?: string;
    email?: string;
    picture?: string;
  };
  handleOpen?(e: unknown): unknown;
  isOpen?: boolean;
}

function UserAvatar({ user, handleOpen, isOpen }: UserAvatarProps) {
  return (
    <StyledAvatar
      aria-describedby="profile-menu"
      className="user-profile"
      alt={user.name}
      src={user.picture}
      onClick={(e) => handleOpen?.(e)}
      data-testid="header-banner-profile"
      open={!!isOpen}
    >
      {user.name && user.name?.length > 0 && user.name.charAt(0)}
    </StyledAvatar>
  );
}

UserAvatar.defaultProps = {
  handleOpen: () => {},
  isOpen: false,
};

export default UserAvatar;
