import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Typography } from '@material-ui/core';

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    align-items: stretch;
  }
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  align-self: flex-start;

  svg {
    width: 95px;
    height: 95px;
    border-radius: 50px;
    margin-right: ${(props) => props.theme.spacing(6)}px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
    align-self: center;

    svg {
      margin-right: 0;
    }
  }
`;

export const Info = styled.div`
  flex-grow: 1;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

export const EditFormWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(1.5, 3)};
  width: fit-content;
  margin-left: auto;
  margin-bottom: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(1)}px;
  }
`;

export const SectionTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled(Typography)`
  font-size: 1.67rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.67rem;
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
  }
`;

export const contractType = {
  contractNumber: PropTypes.string,
  contractStatus: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  costCenter: PropTypes.string,
  product: PropTypes.shape({
    name: PropTypes.string,
    accessCategories: PropTypes.arrayOf(PropTypes.string),
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  vehicleData: PropTypes.shape({
    vehicleType: PropTypes.string,
    batteryCapacity: PropTypes.number,
    vehicleNumber: PropTypes.string,
    vehicleModel: PropTypes.string,
    manufactureYear: PropTypes.number,
    licencePlate: PropTypes.string,
    vehicleHandoverDate: PropTypes.string,
    annualMileage: PropTypes.number,
    startingMileage: PropTypes.number,
  }),
  rfids: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      serialNumber: PropTypes.string,
    })
  ),
  approvedByManager: PropTypes.bool,
  prerequisitesFulfilled: PropTypes.bool,
};

export const sideMenuType = {
  id: PropTypes.string,
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export const contractLength = (
  startDate: string | number | Date | undefined,
  endDate: string | number | Date | undefined
) => {
  return startDate && endDate ? new Date(endDate).getFullYear() - new Date(startDate).getFullYear() : 0;
};
export const dateFormat = i18next?.language?.toLowerCase() === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy';
