import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import './config/i18n';
import './utils/polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalTheme from './utils/GlobalTheme';
import { Auth0Provider } from './utils/Auth0Provider';
import { PermissionsProvider } from './utils/PermissionsProvider';
import { NotificationProvider } from './utils/NotificationProvider';
import { InfoPopoverProvider } from './components/InfoPopover';
import { ContractProvider } from './utils/ContractProvider';
import { VehicleModelProvider } from './utils/VehicleModelProvider';

smoothscroll.polyfill();

ReactDOM.render(
  <StrictMode>
    <GlobalTheme>
      <Auth0Provider>
        <HelmetProvider>
          <NotificationProvider>
            <PermissionsProvider>
              <VehicleModelProvider>
                <ContractProvider>
                  <InfoPopoverProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </InfoPopoverProvider>
                </ContractProvider>
              </VehicleModelProvider>
            </PermissionsProvider>
          </NotificationProvider>
        </HelmetProvider>
      </Auth0Provider>
    </GlobalTheme>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
