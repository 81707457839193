import { useState } from 'react';
import styled from 'styled-components/macro';
import { Typography, Button, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { TFunctionResult } from 'i18next';

import Carousel from '../../../Carousel';
import DotIndicator from '../../../Carousel/DotIndicator';
import { useContractProvider } from '../../../../utils/ContractProvider';
import qrCodePhone from '../../../../assets/images/qrCodePhone.png';
import dpcPhone from '../../../../assets/images/dpcPhone.png';
import dpcSubmitted from '../../../../assets/images/dpcSubmitted.png';
import PhoneIcon from '../../../Icons/PhoneIcon';

const Wrapper = styled.div`
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  color: transparent;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(5)}px;

  svg {
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(5)}px;

  img {
    height: 240px;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(4)}px;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Dots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

function DigitalPrecheckStep() {
  const { t } = useTranslation('processwidget');
  const { selectedContract } = useContractProvider();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dpcStatus = selectedContract?.digitalPrecheckStatus;

  const [index, setIndex] = useState(0);

  const goToAppStore = () => {
    window.open('https://mer.qrd.by/0hq87i', '_blank');
  };

  const renderTitle = (title: TFunctionResult) => {
    return (
      <Title>
        <PhoneIcon />
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
      </Title>
    );
  };

  const renderContent = () => {
    if (!dpcStatus) {
      return (
        <>
          {renderTitle(t('dpc not started'))}
          <Carousel index={index} onChange={(i) => setIndex(i)}>
            <ContentWrapper>
              <a href="https://mer.qrd.by/0hq87i" target="_blank" rel="noreferrer">
                <img src={qrCodePhone} alt="dpc qr code" />
              </a>
              <Typography variant="body1" color="primary">
                {t('dpc not started description 1')}
              </Typography>
            </ContentWrapper>
            <ContentWrapper>
              <a href="https://mer.qrd.by/0hq87i" target="_blank" rel="noreferrer">
                <img src={dpcPhone} alt="dpc on phone" />
              </a>
              <Typography variant="body1" color="primary">
                {t('dpc not started description 2')}
              </Typography>
            </ContentWrapper>
          </Carousel>
          <Dots>
            {[0, 1].map((i) => (
              <DotIndicator key={i} active={i === index} index={i} onClick={() => setIndex(i)} />
            ))}
          </Dots>
          {isMobile && (
            <ButtonWrapper>
              <Button variant="contained" color="primary" onClick={goToAppStore}>
                {t('download mer app')}
              </Button>
            </ButtonWrapper>
          )}
        </>
      );
    }

    if (dpcStatus === 'INITIATED') {
      return (
        <>
          {renderTitle(t('dpc initiated'))}
          <ContentWrapper>
            <a href="https://mer.qrd.by/0hq87i" target="_blank" rel="noreferrer">
              <img src={qrCodePhone} alt="dpc qr code" />
            </a>
            <Typography variant="body1" color="primary">
              {t('dpc started description')}
            </Typography>
          </ContentWrapper>
          {isMobile && (
            <ButtonWrapper>
              <Button variant="contained" color="primary" onClick={goToAppStore}>
                {t('download mer app')}
              </Button>
            </ButtonWrapper>
          )}
        </>
      );
    }

    return (
      <>
        {renderTitle(t('dpc submitted'))}
        <ContentWrapper>
          <div>
            <img src={dpcSubmitted} alt="dpc submitted" />
          </div>
          <Typography variant="body1" color="primary">
            {t('dpc submitted description')}
          </Typography>
        </ContentWrapper>
      </>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
}

export default DigitalPrecheckStep;
