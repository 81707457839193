import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ChangeEvent, ReactNode } from 'react';

import constants from '../../../config/constants';
import { usePermissions } from '../../../utils/PermissionsProvider';

const RoleFormControl = styled(FormControl)`
  margin-bottom: ${(props) => props.theme.spacing(1.5)}px;
`;

const RoleMenuItem = styled(MenuItem)`
  font-size: 1rem;
`;

function RoleSwitch({ onSwitchRole }: { onSwitchRole: () => void }) {
  const { t } = useTranslation('roles');

  const { changeAssumedRole, getAssumedRole, hasMultipleRoles } = usePermissions();

  const activeRole = getAssumedRole();

  const changeRole = (e: { target: { value: string } }) => {
    changeAssumedRole(e.target.value);
    onSwitchRole();
  };

  if (!hasMultipleRoles()) {
    return <></>;
  }

  return (
    <RoleFormControl variant="outlined">
      <Select
        displayEmpty
        value={activeRole}
        onChange={changeRole as (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void}
      >
        {constants.assumedRoles &&
          Object.values(constants.assumedRoles)
            .filter((assumeRoleOptions) => assumeRoleOptions !== constants.assumedRoles.USER)
            .map((assumedRole) => {
              return (
                <RoleMenuItem dense key={assumedRole} value={assumedRole}>
                  {t(assumedRole)}
                </RoleMenuItem>
              );
            })}
      </Select>
    </RoleFormControl>
  );
}

RoleSwitch.defaultProps = {
  onSwitchRole: () => {},
};

RoleSwitch.propTypes = {
  onSwitchRole: PropTypes.func,
};

export default RoleSwitch;
