/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import createAuth0Client, { Auth0Client, IdToken, LogoutOptions, User } from '@auth0/auth0-spa-js';

import authOptions from '../config/auth';

export async function setupAuth0Client() {
  let auth0Client: Auth0Client;
  try {
    auth0Client = await createAuth0Client(authOptions);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    // When createAuth0Client throws an error (e.g. refresh token expired),
    // no auth0 client is created and the user cannot use the system at all.
    // In this case, we have to force logout so that the user can log in again
    if ((e as any).error === 'invalid_grant') {
      auth0Client = new Auth0Client(authOptions);
      auth0Client.logout();
    } else {
      throw e;
    }
  }
  return auth0Client;
}

const onRedirectCallback = (appState: { targetUrl: string | URL | null | undefined }) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

export interface IAuth0Provider {
  getIdTokenClaims: (p: any) => Promise<IdToken> | undefined;
  isAuthenticated: boolean;
  user: User | undefined;
  loading: boolean;
  handleRedirectCallback: () => void;
  loginWithRedirect: (p: any) => Promise<void> | undefined;
  getTokenSilently: (p: any) => Promise<void> | undefined;
  getTokenWithPopup: (p: any) => Promise<string> | undefined;
  logout: (p: any) => void;
  getErrorMessage: () => void;
}

// @ts-ignore
export const Auth0Context = createContext<IAuth0Provider>();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children }: { children: JSX.Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook: Auth0Client = await setupAuth0Client();
        setAuth0(auth0FromHook);
        if (window.location.search.includes('code=') || window.location.search.includes('error=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const auth0IsAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(auth0IsAuthenticated);

        if (auth0IsAuthenticated) {
          const auth0User = await auth0FromHook.getUser();
          setUser(auth0User);
        }
      } catch (e: any) {
        setErrorMessage(e.error);
      }

      setLoading(false);
    };
    initAuth0();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const auth0User = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(auth0User);
  };

  const getErrorMessage = useCallback(() => {
    return errorMessage;
  }, [errorMessage]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client?.getTokenWithPopup(...p),
        logout: (p: LogoutOptions) => auth0Client?.logout({ returnTo: `${authOptions.redirect_uri}/logout`, ...p }),
        getErrorMessage,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
