/* tslint:disable */
/* eslint-disable */
/**
 * Consolidated API for apps/portals of the Mer Group
 * The idea behind this API is to create a common API to provide the functionality for a common customer portal used by fleet-managers, building owners, employees of companies, private customers and many more. 🇪🇺 Available for both backends. 🇩🇪 Available for ePower. 🇳🇴 Available for Frigg. 
 *
 * OpenAPI spec version: VERSION_PLACEHOLDER
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AccessCategory } from './access-category';
import { Address } from './address';
import { CarOwnership } from './car-ownership';
import { Coordinates } from './coordinates';
import { ElectricCurrentType } from './electric-current-type';
import { MonetaryValue } from './monetary-value';
import { StartMedium } from './start-medium';
/**
 * Entity model of a ChargeDetailRecord.
 * @export
 * @interface ChargeDetailRecord
 */
export interface ChargeDetailRecord {
    /**
     * Id to uniquely identify a ChargeDetailRecord. 🇪🇺
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    id?: string;
    /**
     * The status of a ChargeDetailRecord 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    status?: ChargeDetailRecordStatusEnum;
    /**
     * The total consumed energy in kilowatt hours. 🇪🇺
     * @type {number}
     * @memberof ChargeDetailRecord
     */
    consumedEnergy?: number;
    /**
     * The time when the ChargeDetailRecord started. 🇪🇺
     * @type {Date}
     * @memberof ChargeDetailRecord
     */
    startTime?: Date;
    /**
     * The time when this ChargeDetailRecord ended. 🇪🇺
     * @type {Date}
     * @memberof ChargeDetailRecord
     */
    endTime?: Date;
    /**
     * 
     * @type {StartMedium}
     * @memberof ChargeDetailRecord
     */
    startMedium?: StartMedium;
    /**
     * Unique identifier of a Rfid.
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    rfidUid?: string;
    /**
     * The name of the ChargeLocation this ChargeDetailRecord was created at. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    chargeLocationName?: string;
    /**
     * Average Effect. 🇳🇴
     * @type {number}
     * @memberof ChargeDetailRecord
     */
    averageEffect?: number;
    /**
     * Id to uniquely identify the ChargePoint. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    chargePointId?: string;
    /**
     * 
     * @type {CarOwnership}
     * @memberof ChargeDetailRecord
     */
    carOwnership?: CarOwnership;
    /**
     * Id to uniquely identify the Connector. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    connectorId?: string;
    /**
     * The shortId of a Connector. Can be used as SMS code. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    connectorShortId?: string;
    /**
     * The type of the physical Connector plugged into a car. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    connectorType?: string;
    /**
     * 
     * @type {ElectricCurrentType}
     * @memberof ChargeDetailRecord
     */
    electricCurrentType?: ElectricCurrentType;
    /**
     * 
     * @type {AccessCategory}
     * @memberof ChargeDetailRecord
     */
    accessCategory?: AccessCategory;
    /**
     * 
     * @type {Address}
     * @memberof ChargeDetailRecord
     */
    address?: Address;
    /**
     * 
     * @type {Coordinates}
     * @memberof ChargeDetailRecord
     */
    coordinates?: Coordinates;
    /**
     * The currency of the price for this ChargeDetailRecord. 🇳🇴
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    currency?: string;
    /**
     * 
     * @type {MonetaryValue}
     * @memberof ChargeDetailRecord
     */
    totalCost?: MonetaryValue;
    /**
     * 
     * @type {MonetaryValue}
     * @memberof ChargeDetailRecord
     */
    energyCost?: MonetaryValue;
    /**
     * 
     * @type {MonetaryValue}
     * @memberof ChargeDetailRecord
     */
    timeCost?: MonetaryValue;
    /**
     * 
     * @type {MonetaryValue}
     * @memberof ChargeDetailRecord
     */
    parkingCost?: MonetaryValue;
    /**
     * 
     * @type {MonetaryValue}
     * @memberof ChargeDetailRecord
     */
    discountAmount?: MonetaryValue;
    /**
     * The unique number which can be used to identify the Contract for the Charging session. 🇩🇪 
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    contractNumber?: string;
    /**
     * The unique number which can be used to identify the contract in the database. 🇩🇪
     * @type {number}
     * @memberof ChargeDetailRecord
     */
    contractId?: number;
    /**
     * The type of the contract from the user. 🇩🇪
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    contractType?: string;
    /**
     * The license plate from the charging car. 🇩🇪
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    licensePlate?: string;
    /**
     * The code for the country the station is places. 🇩🇪
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    countryCode?: string;
    /**
     * The total duration of the charging session in seconds. 🇩🇪
     * @type {number}
     * @memberof ChargeDetailRecord
     */
    duration?: number;
    /**
     * Type of vehicle that initiated the charging session. 🇩🇪
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    vehicleType?: string;
    /**
     * Contains first and last name of the user who charged. 🇪🇺
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    fullName?: string;
    /**
     * Indicates if a CDR is calibration law compliant (i.e. contains public key & signed data) 🇩🇪
     * @type {boolean}
     * @memberof ChargeDetailRecord
     */
    isCalibrationLawCompliant?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ChargeDetailRecordStatusEnum {
    OPEN = 'OPEN',
    ORDERCREATED = 'ORDER_CREATED',
    INVOICED = 'INVOICED',
    CREDITED = 'CREDITED'
}

