import { createContext, useContext, useCallback, useMemo, useState, useEffect, useRef, ReactNode } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { mutate } from 'swr';

import constants from '../config/constants';
import PageLoadingSpinner from '../layout/PageLoadingSpinner';
import useErrorHandler from '../hooks/useErrorHandler';

import { useAuth0 } from './Auth0Provider';
import { getMyPersonalContracts } from './api';
import { getAssumedRole } from './assumedRole';
import { Contract } from './models';

export interface IContractProvider {
  availableContracts: Contract[] | undefined;
  fetchContracts: (newContract: boolean) => Promise<void>;
  selectedContract: Contract | undefined;
  getSelectedContract: () => Contract | undefined;
  changeActiveContract: (contract: Contract) => void;
  error: boolean;
  eligibleForFollowUpContract: Contract | undefined;
}

export const ContractContext = createContext<IContractProvider>({
  availableContracts: [],
  fetchContracts: () => new Promise(() => undefined),
  selectedContract: undefined,
  getSelectedContract: () => undefined,
  changeActiveContract: () => undefined,
  error: false,
  eligibleForFollowUpContract: undefined,
});
export const useContractProvider = () => useContext(ContractContext);

export function ContractProvider({ children }: { children: ReactNode }) {
  const { initializeErrorHandler } = useErrorHandler();
  const [activeRole, setActiveRole] = useState<string | null>();
  const [hasError, setHasError] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [availableContracts, setAvailableContracts] = useState<Contract[]>([]);
  const [currentSelectedContractNr, setCurrentSelectedContractNr] = useState<string | null>();
  const [selectedContract, setSelectedContract] = useState<Contract>();
  const [loadingContracts, setLoadingContracts] = useState(isAuthenticated);
  const componentMounted = useRef<boolean>();

  useEffect(() => {
    initializeErrorHandler();
  }, [initializeErrorHandler]);

  useEffect(() => {
    const updateRole = async () => {
      if (user) {
        const role = await getAssumedRole();
        setActiveRole(role);
      }
    };

    if (user) {
      setCurrentSelectedContractNr(localStorage.getItem(`selectedContractNr-${user.sub}`));
    }

    updateRole();
  }, [user]);

  const fetchContracts = useCallback(async (newContract: boolean) => {
    if (availableContracts !== undefined || newContract) {
      setLoadingContracts(true);
      try {
        const contractResponse = await mutate('getMyPersonalContracts', getMyPersonalContracts());
        if (componentMounted.current) {
          setAvailableContracts(contractResponse);
          setLoadingContracts(false);
        }
      } catch (error) {
        setHasError(true);
        if (componentMounted.current) {
          setLoadingContracts(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeRole === 'driver') {
      fetchContracts(false);
    } else {
      setLoadingContracts(false);
    }
  }, [fetchContracts, activeRole]);

  const getActiveContract = useCallback(() => {
    let activeContract;
    let latestContract;
    let mostRecentDate = moment('2000-01-01').format('YYYY-MM-DD');

    availableContracts.forEach((contract) => {
      if (contract && contract.contractStatus === constants.contractStatus.ACTIVE) {
        activeContract = contract;
      }
      if (contract && typeof contract.startDate === 'string' && contract.startDate > mostRecentDate) {
        mostRecentDate = contract.startDate;
        latestContract = contract;
      }
    });

    // In case there is at least a contract, none active and none with a start date, we just select the first one
    const currentContract = activeContract || latestContract || availableContracts[0];
    localStorage.setItem(`selectedContractNr-${user?.sub}`, currentContract.contractNumber || '');
    return currentContract;
  }, [availableContracts, user]);

  useEffect(() => {
    if (availableContracts?.length > 0 && currentSelectedContractNr === null) {
      const contract = getActiveContract();
      setSelectedContract(contract);
    }
    if (availableContracts?.length > 0 && currentSelectedContractNr !== null && selectedContract === undefined) {
      const contract = availableContracts.find(
        (contractItem) => contractItem.contractNumber === currentSelectedContractNr
      );
      // edge case: current selected contract is deleted from the DB
      setSelectedContract(contract || getActiveContract());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableContracts]);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const changeActiveContract = useCallback((contract) => {
    setSelectedContract(contract);
    localStorage.setItem(`selectedContractNr-${user?.sub}`, contract.contractNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eligibleForFollowUpContract = (availableContracts || []).find(
    (contract) =>
      contract.contractStatus === constants.contractStatus.ACTIVE &&
      contract?.product?.id &&
      (contract?.product?.id?.toString() === '1' || contract?.product?.id?.toString() === '16')
  );
  const value = useMemo(
    () => ({
      availableContracts,
      fetchContracts,
      selectedContract,
      getSelectedContract: () => selectedContract,
      changeActiveContract,
      error: hasError,
      eligibleForFollowUpContract,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableContracts, selectedContract, changeActiveContract, hasError, eligibleForFollowUpContract]
  );

  if (loadingContracts) {
    return <PageLoadingSpinner />;
  }

  return <ContractContext.Provider value={value}>{children}</ContractContext.Provider>;
}

ContractProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
