// @ts-nocheck
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import styled from 'styled-components/macro';
import useSWR from 'swr';

import Card from '../../../layout/Card';
import { getOrganizations, getSelfSignupTokenByOrganization } from '../../../utils/api';
import { useNotification } from '../../../utils/NotificationProvider';
import useErrorHandler from '../../../hooks/useErrorHandler';

const Organization = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(3)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const SignupLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  padding: ${(props) => props.theme.spacing(2, 3)};
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CopyButton = styled.div`
  cursor: pointer;
  svg {
    height: 24px;
    width: auto;
  }
`;

function SelfSignup() {
  const { t } = useTranslation('dashboard');
  const { openSnackbar } = useNotification();
  const { errorHandler } = useErrorHandler();

  const { data: organizations, error } = useSWR('getOrganizations', () => getOrganizations());
  const [selfSignupToken, setSelfSignupToken] = useState();

  useEffect(() => {
    const getAllTokens = async () => {
      let result = [];
      if (organizations?.data?.content?.length > 0) {
        result = await Promise.all(
          organizations.data.content.map(async (org) => {
            const tkn = await getSelfSignupTokenByOrganization(org.id);
            if (tkn && tkn.length > 0) {
              return { organization: org, token: tkn[0] };
            }
            return null;
          })
        );
      }
      return result.filter((val) => val !== null);
    };

    getAllTokens().then((data) => setSelfSignupToken(data));
  }, [organizations]);

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        openSnackbar(t('copy successful'));
      } else {
        errorHandler({});
      }
    } catch (err) {
      errorHandler(err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = (elementId) => {
    const textId = `id-${elementId}`;
    const text = document.querySelector(`#${textId}`).innerText;
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        openSnackbar(t('copy successful'));
      },
      (err) => {
        errorHandler(err);
      }
    );
  };

  if (error || !selfSignupToken || selfSignupToken.length === 0) return null;

  return (
    <Card hasHeader title={t('direct invitation link')}>
      {selfSignupToken.map(({ organization, token }) => (
        <div key={organization.id}>
          <Organization variant="h4" color="primary">
            {organization.name}
          </Organization>
          <SignupLink>
            <Typography color="primary" id={`id-${token.id}`}>
              {`${process.env.REACT_APP_PUBLIC_URL}/signup?selfSignupTokenId=${token.id}&customer=${organization.id}`}
            </Typography>
            <CopyButton onClick={() => copyTextToClipboard(token.id)} data-testid="copy-link-to-clipboard">
              <FileCopyOutlinedIcon />
            </CopyButton>
          </SignupLink>
        </div>
      ))}
    </Card>
  );
}

export default SelfSignup;
