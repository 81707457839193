// @ts-nocheck
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';

const DropArea = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: ${(props) => props.theme.spacing(1, 0, 0.5)};
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => (props.hover ? 'transparent' : props.theme.palette.tertiary.bright)};
  border: 2px dashed ${(props) => props.theme.palette.tertiary.light};

  transition: ${(props) =>
    props.theme.transitions.create(['color', 'background-color', 'border-color'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};

  input {
    display: none;
  }
`;

const DropAreaInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.theme.spacing(3)}px;

  transform: ${(props) => (props.hover ? `scale(1.05)` : 'scale(1.0)')};

  transition: ${(props) =>
    props.theme.transitions.create(['transform', 'opacity'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};
`;

const DropAreaLabel = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const DropAreaSubline = styled(Typography)`
  margin: 0;
`;

const FileButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-top: ${(props) => props.theme.spacing(1)}px;

  :hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

function DropZone({ accept, disabled, icon, label, onChange }) {
  const { t } = useTranslation();
  const [dropzoneHover, setDropzoneHover] = useState(false);
  const fileInputRef = useRef();

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { files } = event.dataTransfer;
    if (files) {
      onChange(files.item(0));
    }
    setDropzoneHover(false);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { files } = event.target;
    if (files) {
      onChange(files.item(0));
    }
  };

  const handleInputClick = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDropzoneHover(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDropzoneHover(false);
  };

  const openFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <DropArea
      hover={dropzoneHover}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      data-testid="dropzone-area"
    >
      <DropAreaInner hover={dropzoneHover} disabled={disabled}>
        {icon}
        <DropAreaLabel color="primary" variant="h5">
          {label || t('drag file here')}
        </DropAreaLabel>
        <DropAreaSubline color="primary">{t('or')}</DropAreaSubline>
        <FileButton onClick={openFileSelection}>{t('select from')}</FileButton>
      </DropAreaInner>
      <input
        accept={accept}
        type="file"
        ref={fileInputRef}
        onChange={handleInputChange}
        onClick={handleInputClick}
        data-testid="dropzone-file-input"
      />
    </DropArea>
  );
}

DropZone.defaultProps = {
  accept: 'image/*',
  disabled: false,
  label: null,
};

DropZone.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DropZone;
