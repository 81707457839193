import styled from 'styled-components/macro';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Backdrop } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavLink } from 'react-router-dom';

import { ExtendedTheme } from '../../config/appTheme';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 224,
    backgroundColor: theme.palette.navbar.main,
    overflow: 'visible',
    border: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 64,
    backgroundColor: theme.palette.navbar.main,
    overflow: 'visible',
    border: 'none',
  },
  drawerOpenMobile: {
    transition: theme.transitions.create('max-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: 'auto',
    maxHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  drawerCloseMobile: {
    transition: theme.transitions.create('max-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 70,
    maxHeight: 70,
    backgroundColor: theme.palette.background.default,
    borderBottom: 'none',
    overflow: 'hidden',
  },
}));

const LeftArrowIcon = styled(ChevronLeftIcon)`
  height: 18px;
  width: 18px;
`;

const RightArrowIcon = styled(ChevronRightIcon)`
  height: 18px;
  width: 18px;
`;

const NavContainer = styled.div`
  display: flex;
`;

const PageWrapper = styled.div<{ open: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('sm')} {
    ${(props) => props.open && 'max-height: 100vh'};
  }
`;

const NavigationText = styled(ListItemText)<{ open: boolean; delay: number }>`
  margin-left: ${(props) => props.theme.spacing(1)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up('sm')} {
    ${(props) =>
      props.open
        ? `opacity: 1;
    transition: ${props.theme.transitions.create('opacity', {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
      delay: props.delay,
    })};`
        : `opacity: 0;
           visibility: collapse;`};
  }
`;

const NavlistItem = styled(ListItem)`
  color: ${(props) => props.theme.palette.navbar.text};
  padding: 0;
  background-color: initial;
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lighter};

  &.Mui-disabled a,
  &.Mui-disabled .MuiListItemIcon-root {
    color: ${(props) => props.theme.palette.tertiary.light};
  }

  &:hover {
    background-color: initial;
  }

  &:hover svg,
  &:hover div {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    border: none;
  }
`;

const NavItemLink = styled(NavLink)`
  width: 100%;
  display: flex;
  text-decoration: none;
  align-items: center;
  color: ${(props) => props.theme.palette.tertiary.dark};

  padding: ${(props) => props.theme.spacing(1.5)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-left: ${(props) => props.theme.spacing(4)}px;
    width: auto;
  }

  &.active,
  &.active div:first-child {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const NavListIcon = styled(ListItemIcon)`
  color: ${(props) => props.theme.palette.tertiary.dark};
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 17px;
    height: auto;
  }
`;

const NavBarToggleButton = styled(IconButton)`
  position: absolute;
  border-radius: 50%;
  line-height: 50%;
  bottom: 52px;
  right: -13px;
  width: 25px;
  height: 25px;
  z-index: 1500;
  background-color: ${(props) => props.theme.palette.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0;
  border: 1px solid rgba(233, 234, 242, 0.5);
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.06);
`;

const NavList = styled(List)<{ component: 'nav' }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    &:first-of-type {
      margin-top: ${(props) => props.theme.spacing(12)}px;
    }
  }
`;

const BurgerMenu = styled.button<{ open: boolean }>`
  outline: none;
  height: 15px;
  width: 15px;
  border: 0;
  padding: 0;
  background: transparent;
  transition: all 250ms ease-out;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;

  &:before,
  &:after {
    content: '';
    width: 15px;
    height: 2px;
    position: absolute;
    background: ${(props) => props.theme.palette.primary.main};
    transition: all 250ms ease-out;
    will-change: transform;
  }

  &:before {
    transform: translateY(-4px);
    ${(props) => props.open && `transform: translateY(0) rotate(45deg);`}
  }

  &:after {
    transform: translateY(4px);
    ${(props) => props.open && `transform: translateY(0) rotate(-45deg);`}
  }
`;

const NavBackdrop = styled(Backdrop)`
  z-index: ${(props) => props.theme.zIndex.drawer - 1};
`;

export {
  useStyles,
  NavContainer,
  PageWrapper,
  NavlistItem,
  NavBarToggleButton,
  NavigationText,
  NavListIcon,
  NavList,
  LeftArrowIcon,
  RightArrowIcon,
  NavItemLink,
  BurgerMenu,
  NavBackdrop,
};
