// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import UserAvatar from '../UserHeader/UserAvatar';
import RoleSwitch from '../UserHeader/UserProfileMenu/RoleSwitch';
import { useAuth0 } from '../../utils/Auth0Provider';

const UserEmail = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.dark};
  word-break: break-word;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  border-top: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  padding: ${(props) => props.theme.spacing(4, 3)};
  margin-top: ${(props) => props.theme.spacing(2)}px;
  align-items: center;

  > div:nth-child(2) {
    flex-direction: column;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    flex: 1;
  }
`;

const UserDetailArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoutLink = styled(Typography)`
  color: ${(props) => props.theme.palette.common.red};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-top: ${(props) => props.theme.spacing(1.5)}px;
  text-align: end;
  font-size: 0.85rem;
`;

function MobileUserProfile({ user, closeDrawer, updateDrawerState }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const handleLogout = () => {
    closeDrawer();
    logout();
  };

  const handleOpenProfile = () => {
    history.push('/profile');
    closeDrawer();
  };

  const roleSwitched = () => {
    updateDrawerState(false);
    history.push('/');
  };

  return (
    <Wrapper>
      <UserAvatar user={user} />
      <div>
        <RoleSwitch onSwitchRole={roleSwitched} />
        <UserDetailArea onClick={handleOpenProfile}>
          <Typography variant="h4" color="primary">
            {user.name}
          </Typography>
          <UserEmail variant="h5">{user.email}</UserEmail>
        </UserDetailArea>
        <LogoutLink variant="h5" onClick={handleLogout}>
          {t('translation:logout')}
        </LogoutLink>
      </div>
    </Wrapper>
  );
}

MobileUserProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
  closeDrawer: PropTypes.func.isRequired,
  updateDrawerState: PropTypes.func.isRequired,
};

export default MobileUserProfile;
