// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Dot = styled.button`
  display: inline-block;
  border: 0;
  background: none;
  line-height: 0;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing(0.5)}px;
  outline: none;

  transition: ${(props) =>
    props.theme.transitions.create(['transform'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};

  transform: ${(props) => (props.active ? 'scale(1.3)' : 'scale(0.8)')};
  &:hover {
    transform: ${(props) => (props.active ? 'scale(1.3)' : 'scale(1)')};
  }

  &:after {
    content: '';
    display: inline-block;
    border-radius: 50%;
    background-color: ${(props) =>
      props.active ? props.theme.palette.secondary.main : props.theme.palette.tertiary.light};
    width: ${(props) => props.theme.spacing(1)}px;
    height: ${(props) => props.theme.spacing(1)}px;
    transition: ${(props) =>
      props.theme.transitions.create(['background-color'], {
        easing: props.theme.transitions.easing.easeInOut,
        duration: props.theme.transitions.duration.short,
      })};
  }
`;

function DotIndicator({ active, index, onClick }) {
  return (
    <Dot
      active={active}
      type="button"
      tabIndex={0}
      aria-label={`slide ${index}`}
      data-testid={`slide ${index + 1}`}
      onClick={onClick}
    />
  );
}

DotIndicator.defaultProps = {
  active: false,
};

DotIndicator.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DotIndicator;
