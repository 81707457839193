// @ts-nocheck
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@material-ui/core';

import { useNotification } from '../../../../utils/NotificationProvider';
import Card from '../../../../layout/Card';
import DropZone from '../../../DropZone';
import AcrobatLogo from '../../../Icons/AcrobatLogo';
import useDocumentApi from '../../../../hooks/useDocumentApi';
import { useContractProvider } from '../../../../utils/ContractProvider';
import AcrobatLogoLarge from '../../../Icons/AcrobatLogoLarge';
import RemoveItemIcon from '../../../Icons/RemoveItemIcon';
import LoadingButton from '../../../LoadingButton';

import validateDocument from './documentValidation';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const DocumentPreview = styled.div`
  display: flex;
  border: 1px solid;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  min-width: 21px;
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:hover {
    background: none;
  }
`;

const DocumentPreviewText = styled(Typography)`
  margin: ${(props) => props.theme.spacing(2.5, 0, 2.5, 1)};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentSubmitAction = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing(1)}px;
  float: right;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const StyledIconButtonWrapper = styled.div`
  display: flex;
  width: 20%;
  float: right;
  justify-content: flex-end;

  button:first-child {
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;
const DocumentInfoWrapper = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;

  > svg:first-child {
    width: ${(props) => props.theme.spacing(8.5)}px;
    height: ${(props) => props.theme.spacing(8.5)}px;
  }
`;

function DocumentUploadCard({ onClose, onUpload }) {
  const { t } = useTranslation('processwidget');
  const [fileName, setFileName] = useState();
  const { uploadDocument, isLoading } = useDocumentApi();
  const { selectedContract } = useContractProvider();

  const { openAlert } = useNotification();
  const [uploadFile, setUploadFile] = useState();

  const onSubmit = async () => {
    const body = {
      name: fileName,
    };
    const response = await uploadDocument(uploadFile, body, selectedContract.id);
    if (response) {
      onUpload(response);
    }
    onClose();
  };

  const handleFileAdded = (file) => {
    if (file) {
      const validation = validateDocument(file, 1048576 * 20);
      if (Object.keys(validation).length === 0) {
        setUploadFile(file);
        setFileName(file.name);
      } else {
        openAlert(t('errors:error'), validation.message);
      }
    }
  };

  const removeFile = () => {
    setUploadFile(null);
    setFileName(null);
  };

  return (
    <Wrapper className="upload-document">
      <Card noBorder noPadding>
        <DropZone
          accept="application/pdf,image/jpg,image/jpeg,image/png"
          icon={<AcrobatLogo />}
          label={t('drag document here')}
          onChange={handleFileAdded}
        />
      </Card>
      {uploadFile && (
        <>
          <DocumentPreview>
            <DocumentInfoWrapper>
              <AcrobatLogoLarge />
              <DocumentPreviewText>{fileName}</DocumentPreviewText>
            </DocumentInfoWrapper>
            <StyledIconButtonWrapper>
              <StyledIconButton aria-label="removeFile" size="small" onClick={removeFile}>
                <RemoveItemIcon />
              </StyledIconButton>
            </StyledIconButtonWrapper>
          </DocumentPreview>
          <DocumentSubmitAction>
            <ButtonWrapper>
              <Button variant="outlined" size="medium" color="primary" onClick={onClose}>
                {t('cancel button')}
              </Button>
            </ButtonWrapper>
            <LoadingButton
              variant="contained"
              color="primary"
              isLoading={isLoading}
              onClick={onSubmit}
              data-testid="action-dialog-confirm"
            >
              {t('upload button')}
            </LoadingButton>
          </DocumentSubmitAction>
        </>
      )}
    </Wrapper>
  );
}

DocumentUploadCard.defaultProps = {
  onUpload: undefined,
};

DocumentUploadCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
};
export default DocumentUploadCard;
