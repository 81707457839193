// @ts-nocheck
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import AppStore from '../Icons/AppStore';
import googlePlay from '../../assets/images/googlePlay.png';
import appPhoneFrame from '../../assets/images/appPhoneFrame.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(12, 7, 12, 16)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(12, 3)};
    align-items: center;
  }
`;

const AppFrame = styled.img`
  height: 650px;
  width: auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 430px;
  }
`;

const Description = styled.div`
  margin-left: ${(props) => props.theme.spacing(12)}px;
  align-self: flex-end;
  padding-bottom: ${(props) => props.theme.spacing(5)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    text-align: center;
    margin-left: 0;
    padding-bottom: 0;
  }
`;

const Title = styled(Typography)`
  font-size: 4rem;
  line-height: 4.5rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-bottom: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 2.5rem;
    line-height: 3.33rem;
    margin-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Subtitle = styled(Typography)`
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.65rem;
    line-height: 2rem;
  }
`;

const DownloadButtons = styled.div`
  svg {
    width: 111px;
  }
  img {
    width: 120px;
  }
  svg,
  img {
    fill: ${(props) => props.theme.palette.common.black};
    height: 37px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
    margin-top: ${(props) => props.theme.spacing(2)}px;

    ${(props) => props.theme.breakpoints.only('xs')} {
      margin-right: ${(props) => props.theme.spacing(1.5)}px;
      margin-bottom: ${(props) => props.theme.spacing(4)}px;
    }
  }
`;

function MobileAppSection() {
  const { t } = useTranslation('landingpage');

  return (
    <Wrapper>
      <AppFrame src={appPhoneFrame} alt="mer app" />
      <Description>
        <Title color="primary">{t('app title')}</Title>
        <Subtitle color="primary">{t('app subtitle')}</Subtitle>
        <DownloadButtons>
          <a
            href="https://play.google.com/store/apps/details?id=com.eemobility.android"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={googlePlay} alt="google-play" />
          </a>
          <a href="https://itunes.apple.com/de/app/eemobility/id1287456872" rel="noopener noreferrer" target="_blank">
            <AppStore />
          </a>
        </DownloadButtons>
      </Description>
    </Wrapper>
  );
}

export default MobileAppSection;
