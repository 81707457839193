// @ts-nocheck
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledUserList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(0, 0, 0, 3)};
  }
`;

function UserList({ users }) {
  return (
    <StyledUserList>
      {users?.map((user) => {
        return (
          <Typography key={user.id}>
            {user.firstName} {user.lastName}
          </Typography>
        );
      })}
    </StyledUserList>
  );
}

UserList.defaultProps = {
  users: [],
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
};

export default UserList;
