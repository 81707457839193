// @ts-nocheck
import styled from 'styled-components';
import { Button, Card, CardHeader, CardContent } from '@material-ui/core';

export const CardStyled = styled(Card)`
  box-shadow: ${(props) => props.theme.shadows[1]};
  padding: ${(props) => props.theme.spacing(4)}px;
  background-color: ${(props) => props.theme.palette.primary.brighter};
  position: relative;
  overflow: visible;

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 36rem;
  }
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  span {
    font-size: 1.35rem;
    font-weight: 600;
  }
`;

export const CardContentStyled = styled(CardContent)`
  padding: 0px;

  :last-child {
    padding: 0px;
  }

  > div:first-child {
    padding-right: ${(props) => props.theme.spacing(13)}px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const InviteButton = styled(Button)<{ simplifiedview?: number }>`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: ${(props) => props.simplifiedview && 'initial'};
  }
`;

export const IllustrationWrapper = styled.div`
  svg {
    position: absolute;
    height: 170px;
    width: 170px;
    fill: none;
    top: -40px;
    right: -20px;

    ${(props) => props.theme.breakpoints.up('md')} {
      right: -35px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 20px;
  }
`;

export const InviteSuccessContentWrapper = styled.div`
  max-width: 350px;
`;

export const NoOfInviteesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(5)}px;

  > svg {
    margin-right: ${(props) => props.theme.spacing(1)}px;
    color: ${(props) => props.theme.palette.primary.main};
    height: 20px;
  }
`;

export const NoOfInviteesButton = styled(Button)`
  padding: ${(props) => props.theme.spacing(0.5, 1)};
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;
