import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { useAuth0 } from '../../utils/Auth0Provider';

import { generateGreeting, greeterGreetings } from './greetings';

const Headline = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(0.5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: 2rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.15rem;
  }
`;

const StyledGreeting = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.65rem;
  }
`;

const userInfo = `${process.env.REACT_APP_AUTH_0_NAMESPACE}userInfo`;

function Greeter() {
  const { t } = useTranslation('dashboard');

  const { user } = useAuth0();

  const name = user?.[userInfo]?.name;
  const generateGreetings = generateGreeting(greeterGreetings(name));

  return (
    <>
      <Headline variant="h3" color="primary">
        {generateGreetings}
      </Headline>
      <StyledGreeting variant="h1" color="primary">
        {t('greeting')}
      </StyledGreeting>
    </>
  );
}

export default Greeter;
