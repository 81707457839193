/* tslint:disable */
/* eslint-disable */
/**
 * Consolidated API for apps/portals of the Mer Group
 * The idea behind this API is to create a common API to provide the functionality for a common customer portal used by fleet-managers, building owners, employees of companies, private customers and many more. 🇪🇺 Available for both backends. 🇩🇪 Available for ePower. 🇳🇴 Available for Frigg. 
 *
 * OpenAPI spec version: VERSION_PLACEHOLDER
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Basic entity model of the vehicle data without user and contract specific information. 🇪🇺
 * @export
 * @interface BasicVehicleData
 */
export interface BasicVehicleData {
    /**
     * The type of the vehicle.
     * @type {string}
     * @memberof BasicVehicleData
     */
    vehicleType?: string;
    /**
     * The model of the vehicle.
     * @type {string}
     * @memberof BasicVehicleData
     */
    vehicleModel?: string;
    /**
     * The unique number which can be used to identify the vehicle model. 🇪🇺
     * @type {string}
     * @memberof BasicVehicleData
     */
    vehicleModelReferenceNumber?: string;
    /**
     * The battery capacity of the vehicle.
     * @type {number}
     * @memberof BasicVehicleData
     */
    batteryCapacity?: number;
    /**
     * The standard consumption of the vehicle as measured by the manufacturer. 🇩🇪
     * @type {string}
     * @memberof BasicVehicleData
     */
    standardConsumption?: BasicVehicleDataStandardConsumptionEnum;
    /**
     * The exact value of the standard consumption of the vehicle as measured by the manufacturer.
     * @type {number}
     * @memberof BasicVehicleData
     */
    standardConsumptionExactValue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BasicVehicleDataStandardConsumptionEnum {
    LT16 = 'LT_16',
    GTE16 = 'GTE_16'
}

