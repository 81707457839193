import styled from 'styled-components/macro';
import { Fade } from '@material-ui/core';

import { borderStyle } from './commonStyles';

const RadioContainer = styled.div<{ disabled: boolean; checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;

  ${borderStyle};
  border-radius: 100%;

  /*
    If used as form element inside a RadioGroup and a FormControlLabel, the disabled property is not passed in.
    -> set the color based on the FormControlGroup being disabled
  */
  .MuiFormControlLabel-root.Mui-disabled & {
    border-color: ${(props) => props.theme.palette.primary.lightest};
    transition: none;
  }
`;

const Circle = styled.span<{ disabled: boolean; checked: boolean }>`
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;

  background-color: ${(props) => {
    if (props.disabled && props.checked) {
      return props.theme.palette.tertiary.main;
    }
    if (props.checked) {
      return props.theme.palette.primary.main;
    }
    return props.theme.palette.primary.lightest;
  }};

  transition: ${(props) =>
    props.theme.transitions.create(['border'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};

  /*
    If used as form element inside a RadioGroup and a FormControlLabel, the disabled property is not passed in.
    -> set the color based on the FormControlGroup being disabled
  */
  .MuiFormControlLabel-root.Mui-disabled & {
    background-color: ${(props) => props.theme.palette.primary.lightest};
    transition: none;
  }
`;

interface CustomRadioProps {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
}

const defaultProps = {
  className: undefined,
  disabled: false,
  selected: false,
};

function CustomRadio({
  className,
  disabled = defaultProps.disabled,
  selected = defaultProps.selected,
}: CustomRadioProps) {
  return (
    <RadioContainer checked={selected} disabled={disabled} className={className}>
      <Fade in={selected}>
        <Circle checked={selected} disabled={disabled} />
      </Fade>
    </RadioContainer>
  );
}

CustomRadio.defaultProps = defaultProps;

export default CustomRadio;
