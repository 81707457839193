import { Button, FormControl, makeStyles, MenuItem, Select, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/styles';
import i18next from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import type { Payload, Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';
import styled from 'styled-components';

import appTheme, { ExtendedTheme } from '../../../config/appTheme';
import constants from '../../../config/constants';
import { StatisticalValues } from '../../../utils/models';
import useStatisticsDataHelper from '../hooks/useStatisticsDataHelper';

import { BAR_CHART_HEIGHT } from './constants';
import { NonIdealState, NonIdealStateType } from './NonIdealState';

interface ExtendLegendPayload extends Payload {
  dataKey?: string;
}

const useButtonStyles = makeStyles<ExtendedTheme, { isVisible: boolean }>((theme) => ({
  root: {
    fontSize: '12px',
    padding: 0,
    color: `${theme.palette.common.red}`,
    marginLeft: '0.25em',
    minWidth: 0,
    visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
  },
  label: {
    fontWeight: 'normal',
  },
  iconSizeSmall: {
    marginRight: 0,
  },
}));

const BarChartWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: calc(100vw - 130px);
    overflow-x: scroll;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    width: calc(100vw - 98px);
  }
`;

const PeriodFilter = styled.div`
  margin-top: -${(props) => props.theme.spacing(5)}px; // move it up into the bottom padding area of the heading
  display: flex;
  justify-content: flex-end;

  .MuiInputBase-root {
    border: none;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.primary.main};
  }

  .MuiInputBase-root:before {
    border: none;
  }

  .MuiSelect-root {
    display: flex;
    align-items: center;
  }

  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledLegend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing(2)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: start;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
`;

const StyledLegendFilter = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.common.gray};
`;

const StyledLegendItem = styled.li<{ color?: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.spacing(0, 2)};

  ::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ color }) => color};
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }
`;

const StyledLegendAction = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.main};
`;

function formatValue(value: number): string {
  return new Intl.NumberFormat(i18next.language.toLowerCase() === 'en' ? 'en-GB' : 'de-DE', {
    maximumFractionDigits: 1,
  }).format(value);
}

interface CdrBarChartProps {
  data: StatisticalValues[];
  currentPeriod: string;
  onPeriodChange?: (e?: ChangeEvent<{ name?: string; value: unknown }>) => void;
  filterVisible?: boolean;
}

const defaultProps = {
  onPeriodChange: () => false,
  filterVisible: false,
};

function CdrBarChart({
  data,
  currentPeriod,
  onPeriodChange = defaultProps.onPeriodChange,
  filterVisible = defaultProps.filterVisible,
}: CdrBarChartProps) {
  const { t } = useTranslation(['statistics', 'months', 'weeks', 'translation']);
  const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const [categoryVisible, setCategoryVisible] = useState('all');
  const { isCategoryEmpty } = useStatisticsDataHelper(data);

  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const resetButtonClasses = useButtonStyles({ isVisible: categoryVisible !== 'all' });

  const unit = t('translation:kilowatt hour unit');

  useEffect(() => {
    setCategoryVisible('all');
  }, [currentPeriod]);

  const formatTooltipValue = (value: number) => {
    return formatValue(value);
  };

  const renderLegendContent = ({ payload }: LegendProps) => {
    if (!payload) return <></>;

    return (
      <StyledLegend>
        <StyledLegendFilter>
          <>
            {t('filter')}
            <Button
              onClick={() => setCategoryVisible('all')}
              classes={resetButtonClasses}
              size="small"
              startIcon={<CloseIcon />}
              title={t('reset filter')}
            />
          </>
        </StyledLegendFilter>
        <ul>
          {(payload as ExtendLegendPayload[]).map(({ value, color, dataKey }) => {
            return (
              <StyledLegendItem key={dataKey} color={color}>
                <StyledLegendAction onClick={() => setCategoryVisible(dataKey ?? 'all')}>{value}</StyledLegendAction>
              </StyledLegendItem>
            );
          })}
        </ul>
      </StyledLegend>
    );
  };

  const getCurrentPeriodName = (dataIndex: number) => {
    const { statisticsPeriods, monthsArray } = constants;

    switch (currentPeriod) {
      case statisticsPeriods.YEAR:
        return t(`months:${monthsArray[dataIndex]}`);
      case statisticsPeriods.WEEK:
        return t(`weeks:${weekdays[dataIndex]}`);
      default:
        return dataIndex + 1;
    }
  };

  const getStatisticsData = () => {
    const dataForBarChart: {
      name: string | number;
      energyConsumptionHome: number;
      energyConsumptionBusiness: number;
      energyConsumptionPublic: number;
    }[] = [];

    data.forEach((statisticalValue, index) => {
      dataForBarChart.push({
        name: getCurrentPeriodName(index),
        energyConsumptionHome:
          statisticalValue?.energyConsumption?.private &&
          (categoryVisible === 'all' || categoryVisible === 'energyConsumptionHome')
            ? statisticalValue.energyConsumption.private
            : 0,
        energyConsumptionBusiness:
          statisticalValue?.energyConsumption?.business &&
          (categoryVisible === 'all' || categoryVisible === 'energyConsumptionBusiness')
            ? statisticalValue.energyConsumption.business
            : 0,
        energyConsumptionPublic:
          statisticalValue?.energyConsumption?.public &&
          (categoryVisible === 'all' || categoryVisible === 'energyConsumptionPublic')
            ? statisticalValue.energyConsumption?.public
            : 0,
      });
    });

    return dataForBarChart;
  };

  return (
    <>
      {filterVisible && (
        <PeriodFilter>
          <FormControl>
            <Select value={currentPeriod} onChange={onPeriodChange} data-testid="cdr-filter-select">
              <StyledMenuItem value={constants.statisticsPeriods.WEEK}>{t('week')}</StyledMenuItem>
              <StyledMenuItem value={constants.statisticsPeriods.MONTH}>{t('month')}</StyledMenuItem>
              <StyledMenuItem value={constants.statisticsPeriods.YEAR}>{t('year')}</StyledMenuItem>
            </Select>
          </FormControl>
        </PeriodFilter>
      )}
      {typeof data !== 'undefined' && isCategoryEmpty('energyConsumption') ? (
        <NonIdealState type={NonIdealStateType.EMPTY} />
      ) : (
        <BarChartWrapper data-testid="cdr-bar-chart">
          <ResponsiveContainer height={BAR_CHART_HEIGHT} width={isMobile ? '300%' : '100%'}>
            <BarChart
              data={getStatisticsData()}
              margin={{
                top: 20,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" style={{ fontSize: '0.8rem' }} />
              <YAxis
                label={{
                  value: unit,
                  position: 'insideLeft',
                  angle: -90,
                }}
                style={{ fontSize: '0.8rem' }}
              />
              <Tooltip formatter={formatTooltipValue} />
              <Bar
                name={t('translation:at home')}
                dataKey="energyConsumptionHome"
                fill={appTheme.palette.statistics.home}
              />
              <Bar
                name={t('translation:business site')}
                dataKey="energyConsumptionBusiness"
                fill={appTheme.palette.statistics.business}
              />
              <Bar
                name={t('translation:on the road')}
                dataKey="energyConsumptionPublic"
                fill={appTheme.palette.statistics.public}
              />
              <Legend align={isMobile ? 'left' : 'center'} content={renderLegendContent} />
            </BarChart>
          </ResponsiveContainer>
        </BarChartWrapper>
      )}
    </>
  );
}

CdrBarChart.defaultProps = defaultProps;

export default CdrBarChart;
