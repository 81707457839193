// @ts-nocheck
import i18n from 'i18next';

export default ({ size, type }, maxSize) => {
  const allowedTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];
  if (!allowedTypes.includes(type)) {
    return {
      message: i18n.t('validation:upload validation type'),
    };
  }
  if (size > maxSize) {
    return {
      message: i18n.t('validation:upload validation size', { n: maxSize / 1024 / 1024 }),
    };
  }

  return {};
};
