import { createContext, PropsWithChildren, useContext } from 'react';

import useStatisticsData from '../hooks/useStatisticsData';
import { PeriodValue, RequestStatus } from '../hooks/types';

type StatisticsDataContextValue = ReturnType<typeof useStatisticsData>;

const defaultContextValue = {
  changePeriod: () => null,
  result: null,
  status: RequestStatus.initial,
};

export const StatisticsDataContext = createContext<StatisticsDataContextValue>(defaultContextValue);

export const useStatisticsDataContext = () => useContext<StatisticsDataContextValue>(StatisticsDataContext);

interface StatisticsDataProviderProps {
  year: number;
  month?: number;
  week?: number;
  initialPeriod?: PeriodValue;
  organizationId?: number;
  contractId?: number;
}

const defaultProps = {
  month: undefined,
  week: undefined,
  initialPeriod: undefined,
  organizationId: undefined,
  contractId: undefined,
};

export const StatisticsDataProvider = ({
  year,
  month = defaultProps.month,
  week = defaultProps.week,
  initialPeriod = defaultProps.initialPeriod,
  organizationId = defaultProps.organizationId,
  contractId = defaultProps.contractId,
  children,
}: PropsWithChildren<StatisticsDataProviderProps>) => {
  const { result, status, changePeriod } = useStatisticsData({
    year,
    month,
    week,
    initialPeriod,
    organizationId,
    contractId,
  });

  return (
    <StatisticsDataContext.Provider value={{ result, status, changePeriod }}>{children}</StatisticsDataContext.Provider>
  );
};

StatisticsDataProvider.defaultProps = defaultProps;
