import { createContext, useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';

import InfoSlider from './InfoSlider';

interface InfoPopoverOptions {
  name: string;
  images?: JSX.Element | JSX.Element[];
  content: JSX.Element | JSX.Element[];
}

interface InfoPopoverContextValue {
  openInfoPopover: (popoverData: InfoPopoverOptions) => void;
}

const defaultContextValue = {
  openInfoPopover: () => undefined,
};

export const InfoPopoverContext = createContext<InfoPopoverContextValue>(defaultContextValue);
export const useInfoPopover = () => useContext(InfoPopoverContext);

interface InfoPopoverProviderProps {
  children: React.ReactNode | ((...args: unknown[]) => unknown);
}

export function InfoPopoverProvider({ children }: InfoPopoverProviderProps) {
  const [infoPopoverState, setInfoPopoverState] = useState<InfoPopoverOptions | undefined>(undefined);

  const componentMounted = useRef<boolean>();

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const openInfoPopover = useCallback((options: InfoPopoverOptions) => {
    setInfoPopoverState((prev) => (prev?.name === options.name ? undefined : options));
  }, []);

  const closeConfirmation = () => {
    if (componentMounted.current) {
      setInfoPopoverState(undefined);
    }
  };

  const value = useMemo(
    () => ({
      openInfoPopover,
    }),
    [openInfoPopover]
  );

  return (
    <InfoPopoverContext.Provider value={value}>
      {children}
      <InfoSlider
        key={infoPopoverState?.name}
        images={infoPopoverState?.images}
        content={infoPopoverState?.content}
        onClose={closeConfirmation}
        open={typeof infoPopoverState !== 'undefined' && Object.keys(infoPopoverState).length > 0}
      />
    </InfoPopoverContext.Provider>
  );
}
