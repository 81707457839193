import { StatisticalValues } from '../../../utils/models';

import { StatisticalSubValueCategories } from './types';

export default function useStatisticsDataHelper(statisticalValues: StatisticalValues[] | undefined) {
  const isCategoryEmpty = (category: StatisticalSubValueCategories) => {
    if (!statisticalValues || statisticalValues?.length === 0 || !Array.isArray(statisticalValues)) {
      return true;
    }

    return !statisticalValues.find((value) => {
      const categoryValues = value?.[category];

      return (
        value &&
        categoryValues &&
        ((typeof categoryValues?.business === 'number' && categoryValues?.business > 0) ||
          (typeof categoryValues?.public === 'number' && categoryValues?.public > 0) ||
          (typeof categoryValues?.private === 'number' && categoryValues?.private > 0))
      );
    });
  };

  const getAccumulatedSubValues = (category: StatisticalSubValueCategories) => {
    if (!statisticalValues || isCategoryEmpty(category)) return null;

    const summary = {
      public: 0,
      private: 0,
      business: 0,
    };

    return statisticalValues.reduce((prev, current) => {
      const currentCat = current[category];

      return {
        public: prev.public + (currentCat?.public ?? 0),
        private: prev.private + (currentCat?.private ?? 0),
        business: prev.business + (currentCat?.business ?? 0),
      };
    }, summary);
  };

  return { isCategoryEmpty, getAccumulatedSubValues };
}
