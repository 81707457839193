/* eslint-disable react/jsx-props-no-spreading */
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseControllerProps,
} from 'react-hook-form';
import { BaseTextFieldProps, InputBaseComponentProps, TextField } from '@material-ui/core';

interface InputControllerProps<T extends FieldValues> extends UseControllerProps<T>, InputBaseComponentProps {
  errors: FieldErrors<T>;
  as?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: BaseTextFieldProps['fullWidth'];
  margin?: BaseTextFieldProps['margin'];
  required?: BaseTextFieldProps['required'];
  size?: BaseTextFieldProps['size'];
  type?: BaseTextFieldProps['type'];
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>;
}

const defaultProps: Partial<InputControllerProps<FieldValues>> = {
  as: null,
  disabled: false,
  fullWidth: true,
  margin: 'none',
  required: true,
  size: 'small',
  type: 'text',
  defaultValue: '',
};

function InputController<T extends FieldValues>({
  control,
  name,
  label,
  errors,
  disabled = defaultProps.disabled,
  fullWidth = defaultProps.fullWidth,
  margin = defaultProps.margin,
  required = defaultProps.required,
  size = defaultProps.size,
  type = defaultProps.type,
  defaultValue = defaultProps.defaultValue,
  ...props
}: InputControllerProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          variant="outlined"
          label={label}
          disabled={disabled}
          // @ts-ignore
          error={!!name.split('.').reduce((p, c) => p?.[c], errors)}
          // @ts-ignore
          helperText={name.split('.').reduce((p, c) => p?.[c], errors)?.message}
          required={required}
          type={type}
          size={size}
          fullWidth={fullWidth}
          margin={margin}
          inputProps={{ 'data-testid': `form-input-${name}`, ...props }}
          {...field}
        />
      )}
    />
  );
}

InputController.defaultProps = defaultProps;

export default InputController;
