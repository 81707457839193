import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';

import DocumentUploadCard from '../../Documents/DocumentUpload/DocumentUploadCard';
import BuildingConsentDownloadCard from '../../Documents/DocumentDownload/BuildingConsentDownloadCard';

const StyledDiv = styled.div`
  margin-right: 16px;
`;

const DocumentUploadWrapper = styled.div`
  margin-top: 24px;
`;

function DocumentUploadWidget({ onClose, onUpload }: { onClose: () => void; onUpload: (data: never) => void }) {
  return (
    <DocumentUploadWrapper>
      <Grid container>
        <Grid item xs={12} md={6}>
          <StyledDiv>
            <BuildingConsentDownloadCard />
          </StyledDiv>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledDiv>
            <DocumentUploadCard onClose={onClose} onUpload={onUpload} />
          </StyledDiv>
        </Grid>
      </Grid>
    </DocumentUploadWrapper>
  );
}

DocumentUploadWidget.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};
export default DocumentUploadWidget;
