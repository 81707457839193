// @ts-nocheck
import autocomplete from './autocomplete.json';
import contact from './contact.json';
import contracts from './contracts.json';
import cookiePolicy from './cookiePolicy.json';
import dashboard from './dashboard.json';
import days from './days.json';
import errors from './errors.json';
import footer from './footer.json';
import landingpage from './landingpage.json';
import navigation from './navigation.json';
import permissions from './permissions.json';
import profile from './profile.json';
import roles from './roles.json';
import stations from './stations.json';
import translation from './translation.json';
import validation from './validation.json';
import tourGuide from './tourGuide.json';
import countries from './countries.json';
import months from './months.json';
import weeks from './weeks.json';
import statistics from './statistics.json';
import invitees from './invitees.json';
import processwidget from './processwidget.json';
import faq from './faq.json';
import logout from './logout.json';
import dpc from './dpc.json';
import selfsignup from './selfsignup.json';

export default {
  autocomplete,
  contact,
  contracts,
  cookiePolicy,
  dashboard,
  days,
  errors,
  footer,
  landingpage,
  navigation,
  permissions,
  profile,
  roles,
  stations,
  translation,
  validation,
  tourGuide,
  countries,
  months,
  weeks,
  statistics,
  invitees,
  processwidget,
  faq,
  logout,
  dpc,
  selfsignup,
};
