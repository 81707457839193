import { useState } from 'react';
import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import constants from '../../../../config/constants';
import InativeContractIcon from '../../../Icons/InactiveContractIcon';
import ActiveContractIcon from '../../../Icons/ActiveContractIcon';
import DraftContractIcon from '../../../Icons/DraftContractIcon';
import { useContractProvider } from '../../../../utils/ContractProvider';
import CheckIcon from '../../../Icons/CheckIcon';
import { ProductType } from '../../../../utils/models';
import { isMetered } from '../../../Contracts/ContractUtils';

const Wrapper = styled.div<{ selected: boolean; hovered: number }>`
  display: flex;
  padding: ${(props) => props.theme.spacing(2, props.selected ? 4 : 8)};
  color: ${(props) =>
    props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.darker};

  ${(props) => props.theme.breakpoints.up('sm')} {
    &:hover {
      background-color: ${(props) => props.theme.palette.secondary.lightest};
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  > svg {
    width: 13px;
    color: ${(props) => props.theme.palette.primary.main};
    margin-right: calc(${(props) => props.theme.spacing(4)}px - 13px);
  }
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BasicInfoFirstLine = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing(0.5)}px;
  align-items: center;
`;

const ContractNumber = styled(Typography)<{ selected: boolean; hovered: number }>`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  margin-right: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) =>
    props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.darker};
`;

const ContractType = styled(Typography)<{ selected: boolean; hovered: number }>`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) =>
    props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.darker};
`;

const ContractStatus = styled(Typography)<{ selected: boolean; hovered: number }>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: ${(props) => props.theme.spacing(0.5, 1)};
  font-size: 0.65rem;
  line-height: 1.5rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${(props) =>
    props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.darker};
  border: 1px solid
    ${(props) =>
      props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.main};

  > svg {
    width: 12px;
    height: 12px;
    margin-right: ${(props) => props.theme.spacing(1)}px;
    color: ${(props) =>
      props.selected || props.hovered ? props.theme.palette.primary.main : props.theme.palette.tertiary.main};
  }
`;

interface ContractMenuItemProps {
  contract: {
    contractNumber?: string;
    contractStatus?: string;
    startDate?: string;
    product?: {
      productType?: ProductType;
    };
  };
  isSelectedContract: boolean;
  handleClose(...args: unknown[]): unknown;
}

function ContractMenuItem({ contract, isSelectedContract, handleClose }: ContractMenuItemProps) {
  const { changeActiveContract } = useContractProvider();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation('contracts');

  const handleChangeActiveContract = () => {
    changeActiveContract(contract);
    handleClose();
    history.push('/');
  };

  const handleOnHover = (status: boolean) => {
    setIsHovered(status);
  };

  const getFormatedDate = () => {
    return moment(contract.startDate).format('DD.MM.YYYY');
  };

  const getContractStatusImg = (status: string) => {
    switch (status) {
      case constants.contractStatus.INACTIVE:
        return <InativeContractIcon />;
      case constants.contractStatus.ACTIVE:
        return <ActiveContractIcon />;
      default:
        return <DraftContractIcon />;
    }
  };

  return (
    <Wrapper
      selected={isSelectedContract}
      hovered={isHovered ? 1 : 0}
      onClick={handleChangeActiveContract}
      onMouseEnter={() => handleOnHover(true)}
      onMouseLeave={() => handleOnHover(false)}
    >
      {isSelectedContract && <CheckIcon />}
      <BasicInfoWrapper>
        <BasicInfoFirstLine>
          <ContractNumber variant="h5" selected={isSelectedContract} hovered={isHovered ? 1 : 0}>
            {contract.contractNumber}
          </ContractNumber>
          {contract.product && (
            <ContractType variant="caption" selected={isSelectedContract} hovered={isHovered ? 1 : 0}>
              {isMetered(contract?.product)
                ? t(`contracts:EEFLAT KWH`)
                : t(`contracts:${contract.product?.productType?.toString()}`)}
            </ContractType>
          )}
          {contract.contractStatus && (
            <ContractStatus selected={isSelectedContract} hovered={isHovered ? 1 : 0}>
              {getContractStatusImg(contract.contractStatus)}
              {contract.contractStatus}
            </ContractStatus>
          )}
        </BasicInfoFirstLine>
        {contract.startDate && getFormatedDate()}
      </BasicInfoWrapper>
    </Wrapper>
  );
}

export default ContractMenuItem;
