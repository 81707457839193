// @ts-nocheck
import PropTypes from 'prop-types';
import { Card as MuiCard, CardHeader, CardContent } from '@material-ui/core';
import styled from 'styled-components';

const CardStyled = styled(MuiCard)`
  ${(props) => props.padding && `padding: ${props.theme.spacing(4)}px;`};
  background-color: ${(props) => props.background};
  width: 100%;
  height: ${(props) => (props.hasfixedheight ? 'fit-content' : '100%')};
  position: relative;
  border-radius: 0px;
  box-shadow: none;
  ${(props) => props.border && `border: 1px solid ${props.theme.palette.primary.bright};`};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => (props.sidepaddingonmobile ? `${props.theme.spacing(3)}px` : props.theme.spacing(3, 0))};
  }
`;

const CardHeaderStyled = styled(CardHeader)`
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    font-size: ${(props) => (props.largetitle ? '2rem' : '1.35rem')};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const CardContentStyled = styled(CardContent)`
  padding: 0;

  :last-child {
    padding: 0;
  }
`;
function Card({
  title,
  action,
  background,
  hasHeader,
  children,
  largeTitle,
  noBorder,
  noPadding,
  sidePaddingOnMobile,
  id,
  hasFixedHeight,
}) {
  return (
    <CardStyled
      background={background}
      border={noBorder ? 0 : 1}
      sidepaddingonmobile={sidePaddingOnMobile ? 1 : 0}
      id={id}
      hasfixedheight={hasFixedHeight ? 1 : 0}
      padding={noPadding ? 0 : 1}
    >
      {hasHeader && <CardHeaderStyled title={title} action={action} largetitle={largeTitle ? 1 : 0} />}
      <CardContentStyled>{children}</CardContentStyled>
    </CardStyled>
  );
}

Card.defaultProps = {
  title: '',
  action: <></>,
  background: '#fff',
  hasHeader: false,
  largeTitle: false,
  noBorder: false,
  noPadding: false,
  sidePaddingOnMobile: true,
  id: null,
  hasFixedHeight: false,
};

Card.propTypes = {
  title: PropTypes.string,
  action: PropTypes.node,
  background: PropTypes.string,
  hasHeader: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  largeTitle: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  sidePaddingOnMobile: PropTypes.bool,
  id: PropTypes.string,
  hasFixedHeight: PropTypes.bool,
};

export default Card;
