import styled from 'styled-components/macro';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Contract } from '../../../../utils/models';

import ContractSelectorList from './ContractSelectorList';
import AddNewContractSection from './AddNewContractSection';

const BackButton = styled(Button)`
  position: absolute;
  top: 0;
  width: fit-content;
  padding: ${(props) => props.theme.spacing(4)}px;

  svg {
    width: 14px;
    color: ${(props) => props.theme.palette.primary.main};
    margin-right: ${(props) => props.theme.spacing(3)}px;
  }
`;

const BackButtonText = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;
interface MobileContractSelectorMenuProps {
  contractList?: Contract[];
  selectedContract?: Contract;
  handleGoBack(...args: unknown[]): unknown;
  eligibleForFollowUpContract: boolean;
  handleClose(...args: unknown[]): unknown;
}
function MobileContractSelectorMenu({
  contractList,
  selectedContract,
  handleGoBack,
  eligibleForFollowUpContract,
  handleClose,
}: MobileContractSelectorMenuProps) {
  const { t } = useTranslation('contracts');
  return (
    <div>
      <BackButton onClick={handleGoBack}>
        <ArrowBackIosIcon />
        <BackButtonText variant="h4" color="primary">
          {t('my contracts')}
        </BackButtonText>
      </BackButton>
      {contractList !== undefined && (
        <ContractSelectorList
          contractList={contractList}
          selectedContract={selectedContract}
          handleClose={handleClose}
        />
      )}{' '}
      <AddNewContractSection handleClose={handleClose} isFollowUp={false} disabled={false} />
      <AddNewContractSection handleClose={handleClose} isFollowUp disabled={!eligibleForFollowUpContract} />
    </div>
  );
}

MobileContractSelectorMenu.defaultProps = {
  selectedContract: null,
  contractList: [],
};

export default MobileContractSelectorMenu;
