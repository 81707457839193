import React, { createContext, useCallback, useContext, useMemo, useState, useRef, useEffect, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ReactElementLike } from 'prop-types';
import { TFunctionResult } from 'i18next';

import SnackbarNotification from '../components/SnackbarNotification';
import ErrorDialog from '../components/ErrorDialog';
import ActionDialog from '../components/ActionDialog';

interface ConfirmationType {
  content: string | number | boolean | ReactElementLike;
  textConfirm: string;
  disableBackdropClick: boolean;
  hideCancel: boolean;
  title: string;
  catchOnCancel: boolean;
  handleRequest: () => void;
}

interface NotificationInterface {
  openSnackbar: (content: string, title: string) => void;
  closeSnackbar: (key: string) => void;
  openAlert: (title: string, message: string, timestamp?: Date) => void;
  closeAlert: () => void;
  openConfirmation: (options: TFunctionResult) => void;
}

export const NotificationContext = createContext<NotificationInterface>({
  closeAlert(): void {},
  closeSnackbar(): void {},
  openAlert(): void {},
  openConfirmation(): void {},
  openSnackbar(): void {},
});

export const useNotification = () => useContext<NotificationInterface>(NotificationContext);

const NotificationWrapper = styled.span`
  display: inline;
`;

interface NotificationProviderProps {
  children: ReactNode | ((...args: unknown[]) => unknown);
}

interface SnackType {
  key: number;
  message: string;
  title?: string;
  open: boolean | null;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [snacks, setSnacks] = useState<SnackType[]>([]);
  const [alert, setAlert] = useState<{
    title?: string;
    message?: string;
    open: boolean;
    timestamp?: Date;
  }>({ open: false });
  const [confirmationState, setConfirmationState] = useState<ConfirmationType | Record<string, never>>({});
  const [loading, setLoading] = useState(false);

  const componentUnmounted: React.MutableRefObject<boolean | undefined> = useRef();
  const awaitingPromiseRef: React.MutableRefObject<
    { resolve: (value?: unknown) => void; reject: (e?: unknown) => void } | undefined
  > = useRef();

  useEffect(() => {
    componentUnmounted.current = true;
    return () => {
      componentUnmounted.current = false;
    };
  }, []);

  const closeSnackbar = useCallback((key) => {
    setSnacks((existingSnacks) => existingSnacks.filter((item) => item.key !== key));
  }, []);

  const handleOnCloseSnackbar = useCallback(
    (event, reason, key) => {
      if (reason === 'clickaway') {
        return;
      }
      closeSnackbar(key);
    },
    [closeSnackbar]
  );

  const openSnackbar = useCallback((content, title) => {
    const key = new Date().getTime() + Math.random();
    const snack: SnackType = {
      key,
      message: content,
      open: true,
      title,
    };
    setSnacks((existingSnacks) => [...existingSnacks, snack]);
  }, []);

  const openAlert = useCallback((title, message, timestamp) => {
    setAlert({
      title,
      message,
      open: true,
      timestamp,
    });
  }, []);

  const closeAlert = useCallback(() => {
    setAlert({
      open: false,
    });
  }, []);

  const handleOnCloseAlert = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      closeAlert();
    },
    [closeAlert]
  );

  const openConfirmation = useCallback((options) => {
    setConfirmationState(options);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const closeConfirmation = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    if (componentUnmounted.current) {
      setConfirmationState({});
    }
  };

  const submitConfirmation = async () => {
    setLoading(true);
    try {
      if (confirmationState.handleRequest) {
        await confirmationState.handleRequest();
      }
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
    } catch (error) {
      awaitingPromiseRef.current?.reject(error);
    }
    if (componentUnmounted.current) {
      setLoading(false);
      setConfirmationState({});
    }
  };

  const value: NotificationInterface = useMemo(
    () => ({
      openSnackbar,
      closeSnackbar,
      openAlert,
      closeAlert,
      openConfirmation,
    }),
    [openSnackbar, closeSnackbar, openAlert, closeAlert, openConfirmation]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {snacks.length > 0 && (
        <NotificationWrapper>
          {snacks.map((snack) => (
            <SnackbarNotification
              key={snack.key}
              id={snack.key}
              message={snack.message}
              open={snack.open}
              title={snack.title}
              onClose={(event, reason) => {
                handleOnCloseSnackbar(event, reason, snack.key);
              }}
            />
          ))}
        </NotificationWrapper>
      )}
      {alert.open && (
        <ErrorDialog
          message={alert.message}
          title={alert.title}
          open={alert.open}
          backendDate={alert.timestamp}
          onClose={(event, reason) => {
            handleOnCloseAlert(event, reason);
          }}
        />
      )}
      {Object.keys(confirmationState).length > 0 && (
        <ActionDialog
          content={confirmationState.content}
          confirmationButtonText={confirmationState.textConfirm}
          disableBackdropClick={confirmationState.disableBackdropClick || false}
          hideCancel={confirmationState.hideCancel || false}
          isLoading={loading}
          title={confirmationState.title}
          open={Object.keys(confirmationState).length > 0}
          handleConfirmation={submitConfirmation}
          handleClose={closeConfirmation}
        />
      )}
    </NotificationContext.Provider>
  );
}
