// @ts-nocheck
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { usePermissions } from '../../utils/PermissionsProvider';
import constants from '../../config/constants';
import { getUserSettings } from '../../utils/api';

import Greeter from './Greeter';
import DashboardDriver from './DashboardDriver';
import DashboardFleetManager from './DashboardFleetManager';
import WelcomeDialog from './WelcomeDialog';
import DashboardDefaultUser from './DashboardDefaultUser';

function Dashboard() {
  const { hasAssumedRole } = usePermissions();

  // Update the language based on the user settings
  const { i18n } = useTranslation();
  const { data } = useSWR('getUserSettings', getUserSettings);
  const lang = localStorage.getItem('groalng');

  useEffect(() => {
    if (data?.language && data.language !== lang) {
      i18n.changeLanguage(data.language);
      localStorage.setItem('groalng', data.language);
    }
  }, [data, i18n, lang]);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const renderContent = () => {
    if (hasAssumedRole(constants.assumedRoles.MANAGER)) {
      return <DashboardFleetManager />;
    }
    if (hasAssumedRole(constants.assumedRoles.DRIVER)) {
      return <DashboardDriver />;
    }
    return <DashboardDefaultUser />;
  };

  return (
    <>
      <Helmet>
        <title>{constants.PAGE_TITLE}</title>
      </Helmet>
      <Greeter />
      {renderContent()}
      {desktop && <WelcomeDialog />}
    </>
  );
}

export default Dashboard;
