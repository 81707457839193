import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useContractProvider } from '../../utils/ContractProvider';
import DashboardCdrCo2Card from '../Statistics/StatisticsDriver/DashboardCdrCo2Card';
import { ErrorWrapper } from '../Loader/Loader.parts';
import { ProductType } from '../../utils/models';

import { isWidgetVisible } from './DashboardStorageUtils';
import CreateNewContractWidget from './CreateNewContractWidget/CreateNewContractWidget';
import InterestingFacts from './InterestingFacts';
import ProcessWidget from './ProcessWidget';

const StyledErrorWrapper = styled(ErrorWrapper)`
  color: ${(props) => props.theme.palette.primary.main};
  height: ${(props) => props.theme.spacing(16)}px;
`;

function DashboardDriver() {
  const { selectedContract, availableContracts, error } = useContractProvider();
  const hasContract = Array.isArray(availableContracts) ? availableContracts?.length > 0 : false;
  const { t } = useTranslation('errors');

  const showNewProcessWidget = () => {
    const isSelectedDraft = selectedContract?.contractStatus === 'DRAFT';
    const widgetNotHidden = typeof selectedContract?.id === 'string' ? isWidgetVisible(selectedContract?.id) : false;
    const isSelectedeFlat =
      selectedContract?.product?.productType === ProductType.EEFLAT ||
      selectedContract?.product?.productType === ProductType.EEFLAT_KWH;
    const isSelectedFollowUp = !!selectedContract?.previousContractId;
    if (isSelectedFollowUp) {
      return false;
    }

    const hasSingleeFlat =
      availableContracts?.filter(
        (contract) =>
          contract.product?.productType === ProductType.EEFLAT ||
          selectedContract?.product?.productType === ProductType.EEFLAT_KWH
      ).length === 1;

    if (!availableContracts || !selectedContract) {
      return false;
    }

    return hasSingleeFlat && isSelectedeFlat && isSelectedDraft && widgetNotHidden;
  };

  const renderContent = () => {
    if (error) {
      return (
        <StyledErrorWrapper>
          <ErrorOutlineIcon />
          <Typography variant="h4">{t('error processing response')}</Typography>
        </StyledErrorWrapper>
      );
    }
    if (availableContracts?.length === 0) {
      return <CreateNewContractWidget />;
    }
    if (showNewProcessWidget()) {
      return <ProcessWidget />;
    }
    return null;
  };

  return (
    <>
      {renderContent()}
      {hasContract && <DashboardCdrCo2Card />}
      <InterestingFacts isManager={false} />
    </>
  );
}

export default DashboardDriver;
