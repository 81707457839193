// @ts-nocheck
import PropTypes from 'prop-types';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { useStyles } from './NavigationBar.parts';

export default function AnimatedDrawer({ children, open }) {
  const { drawerOpen, drawerClose, drawerOpenMobile, drawerCloseMobile } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let className = open ? drawerOpen : drawerClose;
  if (isMobile) {
    className = open ? drawerOpenMobile : drawerCloseMobile;
  }

  return (
    <Drawer
      variant="permanent"
      className={className}
      classes={{
        paper: className,
      }}
      open={open}
      anchor={isMobile ? 'top' : 'left'}
    >
      {children}
    </Drawer>
  );
}

AnimatedDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
