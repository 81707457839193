import { useTranslation } from 'react-i18next';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import styled from 'styled-components/macro';
import { useCallback, useState } from 'react';
import fileDownload from 'js-file-download';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { TFunctionResult } from 'i18next';

import NewDashboardContractIcon from '../../../Icons/NewDashboardContractIcon';
import GestattungIcon from '../../../Icons/GestattungIcon';
import DownloadIcon from '../../../Icons/DownloadIcon';
import ActionDialog from '../../../ActionDialog';
import { downloadContractDocument } from '../../../../utils/api';

import DocumentUploadWidget from './DocumentUploadWidget';

const Wrapper = styled.div`
  height: 100%;
`;

const ButtonText = styled.div`
  padding-left: 10px;
`;

const Title = styled.div`
  display: flex;
  color: transparent;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(5)}px;

  svg {
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(5)}px;

  img {
    height: 240px;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(5)}px;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const GestattungIconWrapper = styled.div`
  svg {
    width: 174px;
    height: 113px;
    top: ${(props) => props.theme.spacing(2.5)}px;
    right: ${(props) => props.theme.spacing(3.5)}px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const GestattungText = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(4.5)}px;
  text-align: center;
`;

function BuildingConsentUploadStep({
  documentId,
  updateDocumentId,
}: {
  documentId?: string;
  updateDocumentId: (data: never) => void;
}) {
  const { t } = useTranslation('processwidget');
  const [documentUploadDialogOpen, setDocumentUploadDialogOpen] = useState(false);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderTitle = (title: TFunctionResult) => {
    return (
      <Title>
        <NewDashboardContractIcon />
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
      </Title>
    );
  };

  const openDocumentUploadDialog = () => {
    setDocumentUploadDialogOpen(true);
  };

  const closeDocumentUploadDialog = () => {
    setDocumentUploadDialogOpen(false);
  };

  const download = useCallback(async (id) => {
    const response = await downloadContractDocument(id);

    const name = 'gestattung.pdf';
    fileDownload(response.data, name);
  }, []);

  const renderContent = () => {
    return (
      <>
        {!documentId && (
          <>
            {renderTitle(t('building consent not uploaded yet'))}
            <ContentWrapper>
              <GestattungIconWrapper>
                <GestattungIcon />
              </GestattungIconWrapper>
              <GestattungText variant="body1" color="primary">
                {t('building consent description 1')}
              </GestattungText>
              <ButtonWrapper>
                <Button type="submit" color="primary" variant="contained" onClick={openDocumentUploadDialog}>
                  {t('start upload button')}
                </Button>
              </ButtonWrapper>
            </ContentWrapper>
          </>
        )}
        {documentId && (
          <>
            {renderTitle(t('building consent uploaded'))}
            <ContentWrapper>
              <GestattungIconWrapper>
                <GestattungIcon />
              </GestattungIconWrapper>
              <GestattungText variant="body1" color="primary">
                {t('building consent description 2')}
              </GestattungText>
              <ButtonWrapper>
                <Button variant="outlined" color="primary" onClick={() => download(documentId)}>
                  <DownloadIcon />
                  <ButtonText>{t('download your building consent')}</ButtonText>
                </Button>
              </ButtonWrapper>
            </ContentWrapper>
          </>
        )}
        <ActionDialog
          open={documentUploadDialogOpen}
          handleClose={closeDocumentUploadDialog}
          content={<DocumentUploadWidget onClose={closeDocumentUploadDialog} onUpload={updateDocumentId} />}
          showCloseIcon
          maxWidth={isMobile ? 'sm' : 'lg'}
          noActions
        />
      </>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
}

BuildingConsentUploadStep.defaultProps = {
  documentId: undefined,
};

export default BuildingConsentUploadStep;
