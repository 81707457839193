import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';

import Payment from '../components/Icons/Payment';
import Pricing from '../components/Icons/Pricing';
import Support from '../components/Icons/Support';

export interface SupportContact {
  key: string; // Used as key for translations
  icon?: JSX.Element;
  email: string;
  phone?: string;
}

export const contactData: SupportContact[] = [
  {
    key: 'service',
    icon: <Support />,
    email: 'service.plus@mer.eco',
    phone: '+49 89 809 1330 50',
  },
  {
    key: 'billing',
    icon: <Pricing />,
    email: 'billing.plus@mer.eco',
  },
  {
    key: 'order',
    icon: <Payment />,
    email: 'order.plus@mer.eco',
  },
  {
    key: 'sales',
    icon: <WbIncandescentOutlinedIcon />,
    email: 'sales.plus@mer.eco',
  },
];

export const getLinkablePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\s/g, '');
};
