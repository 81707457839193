import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import useSWR from 'swr';

import { useContractProvider } from '../../../../utils/ContractProvider';
import Co2Savings from '../../Co2Savings';
import CdrBarChart from '../../CdrBarChart';
import { StatisticsDataProvider } from '../../providers/StatisticsDataProvider';
import { getStatisticsCo2SavingsForMonth } from '../../../../utils/api';
import { Co2SavingsResult } from '../../Co2Savings/Co2SavingsContainer';
import CdrCo2CardContainer from '../../utils/CdrCo2CardContainer';
import constants from '../../../../config/constants';
import useStatisticsDataHelper from '../../hooks/useStatisticsDataHelper';

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

function DashboardCdrCo2Card() {
  const { t } = useTranslation('statistics');
  const { selectedContract } = useContractProvider();
  const contractId = selectedContract?.id ? +selectedContract?.id : undefined;

  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const currentWeek = moment().isoWeek();

  // On the driver's dashboard, CO2 savings are always displayed for current month.
  const { data, error } = useSWR(contractId ? `getUserStatisticsForMonth-${contractId}` : null, () => {
    // The fetcher will only be called if there is a contractId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getStatisticsCo2SavingsForMonth(currentYear, currentMonth, contractId!);
  });

  const { isCategoryEmpty } = useStatisticsDataHelper(data ? [data] : undefined);

  if (!contractId) return <></>;

  const getCo2Result = (): Co2SavingsResult => {
    if (!data && !error) {
      return { status: 'loading' };
    }

    if (error) {
      return { status: 'error' };
    }

    // If all data is empty -> display the empty state instead of 0 values
    const dataForDisplay = data?.co2Savings === undefined || isCategoryEmpty('co2Savings') ? null : data.co2Savings;

    return { status: 'success', data: dataForDisplay };
  };

  return (
    <Wrapper>
      <CdrCo2CardContainer
        barChart={
          <StatisticsDataProvider
            year={currentYear}
            month={currentMonth}
            week={currentWeek}
            initialPeriod={constants.statisticsPeriods.MONTH}
            contractId={contractId}
          >
            <CdrBarChart filterVisible title={t('charge detail records')} />
          </StatisticsDataProvider>
        }
        co2Card={<Co2Savings title={t('co2 footprint current month')} result={getCo2Result()} />}
      />
    </Wrapper>
  );
}

export default DashboardCdrCo2Card;
