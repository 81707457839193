import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../utils/NotificationProvider';
import { uploadDocument as uploadDocumentRequest } from '../utils/api';

import useErrorHandler from './useErrorHandler';

const useDocumentApi = () => {
  const { t } = useTranslation(['dashboard', 'translation']);
  const { openSnackbar } = useNotification();
  const { errorHandler } = useErrorHandler();

  const [isLoading, setIsLoading] = useState(false);

  const cancelRequest = useRef<boolean>();

  useEffect(() => {
    cancelRequest.current = false;
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const uploadDocument = useCallback(
    async (file, data: never, contractId) => {
      setIsLoading(true);
      try {
        const response = await uploadDocumentRequest(file, data, contractId);
        openSnackbar(t('upload document success'), t('translation:congratulations'));
        if (!cancelRequest.current) {
          setIsLoading(false);
        }
        return response.data;
      } catch (error) {
        if (!cancelRequest.current) {
          setIsLoading(false);
        }

        errorHandler(error);
        return null;
      }
    },
    [errorHandler, openSnackbar, t]
  );

  return {
    uploadDocument,
    isLoading,
  };
};

export default useDocumentApi;
