import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import api, { apiResponseInterceptor } from '../utils/api';
import { useNotification } from '../utils/NotificationProvider';
import { setupAuth0Client } from '../utils/Auth0Provider';

const globalStatusCodes = [401, 403];

const useErrorHandler = () => {
  const { t } = useTranslation(['errors', 'translation', 'permissions']);
  const { openAlert, openConfirmation } = useNotification();

  const errorHandler = useCallback(
    (error) => {
      if (
        !error.response ||
        !globalStatusCodes.includes(error.response?.status) ||
        error?.config?.method === 'put' ||
        error?.config?.method === 'post' ||
        error?.config?.method === 'patch'
      ) {
        const message = error.response?.data?.message?.trim() || '';
        const timestamp = error?.response?.data?.timestamp;
        let dateObject: Date | undefined;
        try {
          if (timestamp && Array.isArray(timestamp)) {
            // @ts-ignore
            dateObject = new Date(...timestamp);
          }
          if (timestamp && (typeof timestamp === 'string' || typeof timestamp === 'number')) {
            dateObject = new Date(timestamp);
          }
        } catch (e) {
          /* eslint-disable no-console */
          console.error(`could not format the timestamp ${timestamp}`);
        }

        const errorMessage =
          message !== '' && message !== 'null'
            ? `${t('error processing response')} <br/><br/> ${t('be error')} <br/> <em>${message}</em>`
            : t('error processing response');
        openAlert(t('error'), errorMessage, dateObject);
      }
    },
    [openAlert, t]
  );

  const triggerLogout = useCallback(async () => {
    const auth0Client = await setupAuth0Client();
    return auth0Client.logout();
  }, []);

  const initializeErrorHandler = useCallback(() => {
    api.interceptors.response.use(apiResponseInterceptor, async (error) => {
      switch (error.response?.status) {
        case 401: {
          if (!window.location.href.includes('signup?selfSignupTokenId')) {
            openConfirmation({
              content: t('session expired message'),
              disableBackdropClick: true,
              hideCancel: true,
              textConfirm: t('translation:logout'),
              title: t('session expired'),
              handleRequest: triggerLogout,
            });
          }
          break;
        }
        case 403: {
          openAlert(t('permissions:no permission'), t('permissions:no permission to perform action'));
          break;
        }
        default:
          break;
      }
      return Promise.reject(error);
    });
  }, [openAlert, openConfirmation, t, triggerLogout]);

  return {
    errorHandler,
    initializeErrorHandler,
  };
};

export default useErrorHandler;
