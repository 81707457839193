import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import VerifiedIcon from '../Icons/VerifiedIcon';
import appTheme from '../../config/appTheme';

const StepNavigation = styled.div`
  svg {
    width: 13px;
  }
`;

const FinishTour = styled(Typography)`
  padding: ${(props) => props.theme.spacing(1, 4)};
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  display: flex;
  align-items: center;

  svg {
    fill: ${(props) => props.theme.palette.common.white};
    width: 16px;
    height: 16px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;
interface TourGuideProps {
  showTour: boolean;
  onFinishTour(...args: unknown[]): unknown;
}

function TourGuide({ showTour, onFinishTour }: TourGuideProps) {
  const { t } = useTranslation(['tourGuide', 'translation']);

  const driverSteps: Step[] = [
    {
      placement: 'bottom',
      target: '.user-profile',
      title: t('your profile'),
      content: t('profile step description'),
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
    },
    {
      title: t('translation:FAQ'),
      placement: 'bottom',
      target: '.faq-link',
      floaterProps: {
        disableAnimation: true,
      },
      content: t('faq step description'),
    },
    {
      title: t('contract selector'),
      target: '.contract-selector',
      floaterProps: {
        disableAnimation: true,
      },
      content: t('contract selector step description'),
      placement: 'bottom',
    },
    {
      title: t('translation:dashboard'),
      content: t('dashboard step description'),
      placement: 'right',
      target: '.dashboard-nav-icon',
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      title: t('contract page'),
      content: t('contract step description'),
      placement: 'right',
      target: '.contract-nav-icon',
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      target: '.process-widget',
      title: t('process widget'),
      content: t('process step description'),
      placement: 'bottom',
      floaterProps: {
        disableAnimation: true,
      },
    },
  ];

  const handleCallback = (data: CallBackProps) => {
    if (data.action === 'close' || data.action === 'stop') {
      onFinishTour();
    }
  };

  return (
    <Joyride
      continuous
      run={showTour}
      callback={(data) => handleCallback(data)}
      steps={driverSteps}
      disableOverlayClose
      disableScrolling
      showSkipButton
      spotlightPadding={0}
      locale={{
        skip: <>{t('skip tour')}</>,
        next: (
          <StepNavigation data-testid="tour-guide-next">
            <ArrowForwardIosIcon />
          </StepNavigation>
        ),
        back: (
          <StepNavigation>
            <ArrowBackIosIcon />
          </StepNavigation>
        ),
        last: (
          <FinishTour data-testid="tour-guide-finish">
            <VerifiedIcon />
            {t('finish tour')}
          </FinishTour>
        ),
      }}
      styles={{
        options: {
          backgroundColor: appTheme.palette.common.white,
          textColor: appTheme.palette.primary.main,
          width: 500,
          zIndex: 1201,
        },
        spotlight: {
          border: `4px solid ${appTheme.palette.secondary.main}`,
        },
        tooltip: {
          borderRadius: 0,
          textAlign: 'left',
          padding: appTheme.spacing(4),
        },
        tooltipTitle: {
          fontSize: '2rem',
          textAlign: 'left',
          paddingBottom: appTheme.spacing(2),
        },
        tooltipContent: {
          padding: '0',
          textAlign: 'left',
          fontSize: '1.15rem',
          lineHeight: '1.7rem',
          overflowWrap: 'break-word',
          letterSpacing: '0.05em',
        },
        buttonClose: {
          width: '10px',
          outline: 'none',
        },
        buttonSkip: {
          padding: '0',
          fontSize: '0.85rem',
          color: appTheme.palette.primary.main,
          outline: 'none',
        },
        buttonNext: {
          backgroundColor: 'transparent',
          color: appTheme.palette.primary.main,
          padding: appTheme.spacing(0, 1),
          outline: 'none',
        },
        buttonBack: {
          backgroundColor: 'transparent',
          color: appTheme.palette.primary.main,
          padding: appTheme.spacing(0, 1),
          outline: 'none',
        },
      }}
    />
  );
}

export default TourGuide;
