import { parseJSON } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz';
import { de, enUS } from 'date-fns/locale';
import i18next from 'i18next';

const locales = { de, en: enUS };

const timeZone = 'Europe/Berlin';
const defaultFormat = 'dd.LL.yyyy, HH:mm:ss';
const backendFormat = 'yyyy-MM-dd';

export function formatTimeFromUtc(date: string | number | Date | undefined | null, formatStr = defaultFormat) {
  if (!date) {
    return null;
  }
  const sqlDate = utcToZonedTime(parseJSON(date), timeZone);

  return format(sqlDate, formatStr, {
    // @ts-ignore
    locale: locales[i18next.language],
  });
}

export function formatTimeToBEDate(date: string | number | Date | undefined, formatStr = backendFormat) {
  if (!date) {
    return undefined;
  }

  const sqlDate = zonedTimeToUtc(parseJSON(date), timeZone);

  return format(sqlDate, formatStr);
}

export function formatTimeLocal(date: Date | string | undefined, formatStr = defaultFormat) {
  if (!date) {
    return null;
  }
  let output;
  try {
    output = format(new Date(date?.toString?.().slice(0, 10)), formatStr);
  } catch (e) {
    output = `Invalid date ${date?.toString?.()}`;
  }
  return output;
}
