import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';

import { useContractProvider } from '../../../utils/ContractProvider';
import CheckboxOn from '../../Icons/CheckboxOn';
import { Contract, HomeType } from '../../../utils/models';

import DigitalPrecheckStep from './DigitalPrecheckStep';
import FinalStep from './FinalStep/FinalStep';
import { getButtonText, getHeader, isStepCompleted, isFinalStepDisabled, isDisabled } from './ProcessWidgetUtils';
import BuildingConsentUploadStep from './BuildingConsentUploadStep/BuildingConsentUploadStep';

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const ButtonNumber = styled.div<{ isDisabled: number }>`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => (props.isDisabled ? props.theme.palette.tertiary.main : props.theme.palette.primary.main)};
`;

const StyledText = styled.div<{ isDisabled: number }>`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.isDisabled ? props.theme.palette.tertiary.main : props.theme.palette.primary.main)};
`;

const StepContainer = styled.div`
  padding: ${(props) => props.theme.spacing(2.5, 3, 3)};
  background-color: ${(props) => props.theme.palette.secondary.bright};
  min-height: 480px;
`;

const StyledAccordion = styled(Accordion)<{ isexpanded: number }>`
  .accordion-header {
    padding: ${(props) => props.theme.spacing(4)}px;
    background-color: ${(props) => props.theme.palette.background.default};
  }

  &.MuiAccordion-root.Mui-expanded {
    border-color: ${(props) =>
      props.isexpanded ? props.theme.palette.primary.main : props.theme.palette.tertiary.lighter};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${(props) => props.theme.spacing(2)}px;
  padding-top: 0;
`;

interface MobileProcessWidgetProps {
  selectedState: number;

  onChangeSelection(...args: unknown[]): unknown;

  contractStatus: string;
  stationState?: string;
}

export function getStep(
  selectedStep: number | null,
  selectedContract: Contract,
  stationState: string | undefined,
  documentId: string | undefined,
  setDocumentId: (data: never) => void,
  setFinalStepInProgress: (() => void) | undefined,
  amountOfSteps: number
) {
  switch (selectedStep) {
    case 1:
      return <DigitalPrecheckStep />;
    case 2:
      return amountOfSteps === 2 ? (
        <FinalStep
          contract={selectedContract}
          stationState={stationState}
          setFinalStepInProgress={setFinalStepInProgress}
        />
      ) : (
        <BuildingConsentUploadStep documentId={documentId} updateDocumentId={setDocumentId} />
      );
    case 3:
      return (
        <FinalStep
          contract={selectedContract}
          stationState={stationState}
          setFinalStepInProgress={setFinalStepInProgress}
        />
      );
    default:
      return <p />;
  }
}

function MobileProcessWidget({
  selectedState,
  onChangeSelection,
  contractStatus,
  stationState,
}: MobileProcessWidgetProps) {
  const [elementExpanded, setExpanded] = useState<number | boolean>(selectedState);
  const selectedContract = useContractProvider()?.selectedContract;
  const [contractDocumentId, setContractDocumentId] = useState<string>();
  const { t } = useTranslation('processwidget');
  const isMultiHome = selectedContract?.homeType === HomeType.MULTIHOME;
  const isSingleHome = selectedContract?.homeType === HomeType.SINGLEHOME;
  const amountOfSteps = isMultiHome ? 2 : 3;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
    onChangeSelection(panel);
    setExpanded(expanded ? panel : false);
  };

  useEffect(() => {
    if (selectedContract?.documents !== undefined && selectedContract?.documents.length > 0) {
      setContractDocumentId(selectedContract?.documents[0].id || '');
    }
  }, [selectedContract]);

  if (!selectedContract) {
    return null;
  }

  function getStepDisabled(i: number) {
    return selectedContract && i === 3 && isFinalStepDisabled(selectedContract) ? 1 : 0;
  }

  const updateDocumentId = (data: { id: SetStateAction<string | undefined> }) => {
    setContractDocumentId(data.id);
  };

  const getAccordion = () => {
    const buttons = [];
    for (let i = 1; i <= amountOfSteps; i += 1) {
      const text = getButtonText(i, amountOfSteps);
      const isMiddleStep = i === 2 && isSingleHome;

      buttons.push(
        <StyledAccordion
          key={i}
          expanded={elementExpanded === i}
          onChange={handleChange(i)}
          isexpanded={elementExpanded === i ? 1 : 0}
          disabled={isDisabled(selectedContract, contractDocumentId, i === amountOfSteps, isMiddleStep, stationState)}
        >
          <AccordionSummary aria-controls={`panel${i}a-content`} className="accordion-header">
            <ButtonWrapper>
              <ButtonContent data-testid={`widgetMobileButton${i}`}>
                {contractStatus !== 'ACTIVE' && <ButtonNumber isDisabled={getStepDisabled(i)}>0{i}</ButtonNumber>}
                <StyledText isDisabled={getStepDisabled(i)}>{t(text)}</StyledText>
              </ButtonContent>
              {isStepCompleted(i, selectedContract, stationState || '', !!contractDocumentId) && <CheckboxOn />}
            </ButtonWrapper>
          </AccordionSummary>
          <StyledAccordionDetails>
            <StepContainer>
              {getStep(
                i,
                selectedContract,
                stationState,
                contractDocumentId,
                updateDocumentId,
                undefined,
                amountOfSteps
              )}
            </StepContainer>
          </StyledAccordionDetails>
        </StyledAccordion>
      );
    }
    return buttons;
  };

  return (
    <div>
      {getHeader(t)}
      {getAccordion()}
    </div>
  );
}

MobileProcessWidget.propTypes = {
  selectedState: PropTypes.number.isRequired,
  onChangeSelection: PropTypes.func.isRequired,
  contractStatus: PropTypes.string.isRequired,
  stationState: PropTypes.string,
};

MobileProcessWidget.defaultProps = {
  stationState: undefined,
};

export default MobileProcessWidget;
