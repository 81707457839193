import { useCallback, useEffect, useReducer, useState } from 'react';

import {
  getStatisticsDataForWeekByDays,
  getStatisticsDataForMonthByDays,
  getStatisticsDataForYearByMonths,
} from '../../../../utils/api';
import { StatisticalValues } from '../../../../utils/models';
import constants from '../../../../config/constants';
import { PeriodValue, RequestStatus } from '../types';

import {
  statisticalValuesRequestInitialState,
  statisticalValuesRequestReducer,
} from './statisticalValuesRequestReducer';
import Cache from './cache';

const { statisticsPeriods } = constants;

const cache = new Cache<StatisticalValues[]>();

export interface UseStatisticsDataProps {
  year: number;
  month?: number;
  week?: number;
  organizationId?: number;
  contractId?: number;
  initialPeriod?: PeriodValue;
}

const useStatisticsData = ({
  year,
  month,
  week,
  organizationId,
  contractId,
  initialPeriod,
}: UseStatisticsDataProps) => {
  const [state, dispatch] = useReducer(statisticalValuesRequestReducer, statisticalValuesRequestInitialState);

  const getPeriodFromProps = useCallback(() => {
    if (week) return statisticsPeriods.WEEK;
    if (month) return statisticsPeriods.MONTH;
    return statisticsPeriods.YEAR;
  }, [week, month]);

  const [forcedPeriod, setForcedPeriod] = useState<PeriodValue | undefined>(initialPeriod);
  const currentPeriod = forcedPeriod || getPeriodFromProps();

  const isValidPeriod = (value: unknown): value is PeriodValue => {
    return typeof value === 'string' && Object.values<string>(statisticsPeriods).includes(value);
  };

  const getCacheKey = useCallback(() => {
    // Using only the relevant values for cache keys avoids additional requests when the period
    // is force-changed with the period filter.
    switch (currentPeriod) {
      case statisticsPeriods.WEEK:
        return `${currentPeriod}-${year}-${week}-${contractId}-${organizationId}`;
      case statisticsPeriods.MONTH:
        return `${currentPeriod}-${year}-${month}-${contractId}-${organizationId}`;
      default:
        return `${currentPeriod}-${year}-${contractId}-${organizationId}`;
    }
  }, [currentPeriod, year, month, week, organizationId, contractId]);

  const getDataForPeriod = useCallback(
    (signal: AbortSignal) => {
      if (!year) {
        throw new Error('Cannot fetch data without a year');
      }

      switch (currentPeriod) {
        case statisticsPeriods.YEAR: {
          return getStatisticsDataForYearByMonths(year, contractId, signal);
        }
        case statisticsPeriods.MONTH:
          if (month) {
            return getStatisticsDataForMonthByDays(year, month, contractId, signal);
          }
          break;
        case statisticsPeriods.WEEK:
          if (week) {
            return getStatisticsDataForWeekByDays(year, week, contractId, signal);
          }
          break;
      }

      return null;
    },
    [currentPeriod, year, month, week, contractId]
  );

  useEffect(() => {
    let cancelRequest = false;
    const abortController = new AbortController();
    const cacheKey = getCacheKey();

    const fetchData = async () => {
      dispatch({ type: RequestStatus.loading });

      try {
        const { signal } = abortController;
        const data = await getDataForPeriod(signal);

        if (cancelRequest) return null;

        if (data) {
          cache.setItem(cacheKey, data);
          return dispatch({ type: RequestStatus.success, payload: { data, period: currentPeriod } });
        }

        throw new Error(`Data could not be loaded for ${cacheKey}`);
      } catch (error) {
        if (cancelRequest) return null;

        return dispatch({ type: RequestStatus.error });
      }
    };

    if (cache.hasItem(cacheKey)) {
      dispatch({ type: RequestStatus.success, payload: { data: cache.getItem(cacheKey), period: currentPeriod } });
    } else {
      fetchData();
    }

    return () => {
      abortController.abort();
      cancelRequest = true;
    };
  }, [currentPeriod, getCacheKey, getDataForPeriod]);

  return {
    changePeriod: (value: unknown) => {
      if (isValidPeriod(value)) {
        setForcedPeriod(value);
      }
    },
    ...state,
  };
};

export default useStatisticsData;
