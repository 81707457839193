import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography, IconButton, CircularProgress } from '@material-ui/core';

import InfoIcon from '../../Icons/InfoIcon';
import { useInfoPopover } from '../../InfoPopover';
import { PopoverContent, PopoverImage } from '../../../layout/InfoPopover';
import { StatisticsWhite } from '../../Illustrations';
import constants from '../../../config/constants';
import { usePermissions } from '../../../utils/PermissionsProvider';
import { useStatisticsDataContext } from '../providers/StatisticsDataProvider';
import { RequestStatus } from '../hooks/types';

import CdrBarChart from './CdrBarChart';
import { NonIdealStateType, NonIdealState } from './NonIdealState';
import { BAR_CHART_HEIGHT } from './constants';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: ${BAR_CHART_HEIGHT}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: auto;
  }

  .recharts-legend-item {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    padding: ${(props) => props.theme.spacing(0.5, 1)};
    margin-top: ${(props) => props.theme.spacing(1)}px;
  }
`;

const TitleWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(5)}px;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }

  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const TitleText = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

interface CdrBarChartContainerProps {
  filterVisible?: boolean;
  title: string;
}

const defaultProps = {
  filterVisible: false,
};

function CdrBarChartContainer({ filterVisible = defaultProps.filterVisible, title }: CdrBarChartContainerProps) {
  const { t } = useTranslation(['statistics']);
  const openInfoPopover = useInfoPopover()?.openInfoPopover;

  const { hasAssumedRole } = usePermissions();

  const { result, status, changePeriod } = useStatisticsDataContext();

  const handlePeriodChange = (event: ChangeEvent<{ name?: string; value: unknown }> | undefined) => {
    const value = event?.target.value;
    changePeriod(value);
  };

  const cdrInfoPopup = () => {
    if (typeof openInfoPopover !== 'undefined') {
      const decriptionKey = `charging sessions per category popup content ${
        hasAssumedRole(constants.assumedRoles.DRIVER) ? 'driver' : 'fleet manager'
      }`;

      openInfoPopover({
        name: t('charging sessions per category'),
        content: <PopoverContent title={t('charging sessions per category')} description={t(decriptionKey)} />,
        images: <PopoverImage image={<StatisticsWhite />} />,
      });
    }
  };

  const renderContent = () => {
    const isLoading = status === RequestStatus.loading || status === RequestStatus.initial;

    if (isLoading) {
      return (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      );
    }

    if (status === RequestStatus.success && result?.data) {
      const { data, period } = result;

      if (data.length) {
        return (
          <CdrBarChart
            data={data}
            currentPeriod={period}
            onPeriodChange={handlePeriodChange}
            filterVisible={filterVisible}
          />
        );
      }

      if (data.length === 0) {
        return <Typography variant="h4">{t('please invite a driver to see data')}</Typography>;
      }
    }

    return <NonIdealState type={NonIdealStateType.ERROR} />;
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleText variant="h4" color="primary">
          {title}
        </TitleText>
        <IconButton
          size="small"
          aria-label={title}
          onClick={cdrInfoPopup}
          data-testid="cdr-statistics-barchart-info-popover"
        >
          <InfoIcon />
        </IconButton>
      </TitleWrapper>
      {renderContent()}
    </Wrapper>
  );
}

CdrBarChartContainer.defaultProps = defaultProps;

export default CdrBarChartContainer;
