// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth0 } from '../../utils/Auth0Provider';

function LogoutRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuth0();

  return <Route {...rest}>{isAuthenticated ? <Redirect to="/" /> : <>{children}</>}</Route>;
}

LogoutRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};

export default LogoutRoute;
