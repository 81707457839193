// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Footer from '../../components/Footer/Footer';
import NavigationBar from '../../components/NavigationBar';
import Header from '../../components/UserHeader';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const PageContent = styled.div`
  background-color: ${(props) =>
    props.color === 'paper' ? props.theme.palette.background.paper : props.theme.palette.background.default};
  max-width: ${(props) => props.theme.general.maxWidth};
  padding: ${(props) => props.theme.spacing(5, 8, 14)};
  flex-grow: 1;
  flex-shrink: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3, 5, 10)};
    width: 100vw;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(3, 2, 6)};
  }
`;

function UserPageLayout({ color, children }) {
  return (
    <NavigationBar>
      <PageContainer>
        <Header />
        <PageContent color={color}>{children}</PageContent>
        <Footer />
      </PageContainer>
    </NavigationBar>
  );
}

UserPageLayout.defaultProps = {
  color: 'default',
};

UserPageLayout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};

export default UserPageLayout;
