// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

import InputController from '../InputController';

import SelectOrganization from './SelectOrganization';

const Form = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin: ${(props) => (props.dense ? props.theme.spacing(1, 0, 1.5) : props.theme.spacing(1, 0, 3))};
  }
`;

function DriverInviteForm({ control, errors, selectOrganization, organizations }) {
  const { t } = useTranslation();

  return (
    <Form dense>
      <InputController control={control} errors={errors} label={t('first name')} name="firstName" margin="none" />
      <InputController control={control} errors={errors} label={t('last name')} name="lastName" margin="none" />
      <InputController control={control} errors={errors} label={t('email')} name="email" margin="none" />
      {organizations.length !== 1 ? (
        <SelectOrganization errors={errors} onChange={selectOrganization} />
      ) : (
        <TextField
          variant="outlined"
          label={t('organization')}
          defaultValue={organizations[0]?.name}
          disabled
          data-testid="disabled-organization-field"
        />
      )}
    </Form>
  );
}

DriverInviteForm.defaultProps = {
  organizations: [],
};

DriverInviteForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  selectOrganization: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default DriverInviteForm;
