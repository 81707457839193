// @ts-nocheck
// Add polyfill for Intl.DateTimeFormat for IE11
const fillIntlDateTimeFormatTimezone = () => {
  try {
    new Intl.DateTimeFormat('en', {
      timeZone: 'Europe/Berlin',
      timeZoneName: 'long',
    }).format();
    return;
  } catch (error) {
    // eslint-disable-next-line global-require
    require('date-time-format-timezone');
  }
};

fillIntlDateTimeFormatTimezone();

export {};
