import constants from '../../../config/constants';
import { StatisticalValues, StatisticalSubValues } from '../../../utils/models';

const { statisticsPeriods } = constants;

type _PeriodKey = keyof typeof statisticsPeriods;
export type PeriodValue = typeof statisticsPeriods[_PeriodKey];

export const enum RequestStatus {
  initial = 'INITIAL',
  loading = 'LOADING',
  success = 'SUCCESS',
  error = 'ERROR',
}

export type StatisticalSubValueCategories = {
  [Key in keyof StatisticalValues]-?: StatisticalValues[Key] extends StatisticalSubValues | undefined ? Key : never;
}[keyof StatisticalValues];
