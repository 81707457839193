// @ts-nocheck
import PropTypes from 'prop-types';

import { useAuth0 } from '../../utils/Auth0Provider';

import UserPageLayout from './UserPageLayout';
import GuestPageLayout from './GuestPageLayout';

function PageLayout({ color, children }) {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <UserPageLayout color={color}>{children}</UserPageLayout>;
  }
  return <GuestPageLayout color={color}>{children}</GuestPageLayout>;
}

PageLayout.defaultProps = {
  color: 'default',
};

PageLayout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};

export default PageLayout;
