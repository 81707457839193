import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core/';
import { Autocomplete, AutocompleteRenderOptionState, FilterOptionsState } from '@material-ui/lab/';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useDebounce from '../../hooks/useDebounce';

interface AutoCompleteSearchProps {
  disabled?: boolean;
  error?: boolean;
  filterOptions?(...args: unknown[]): unknown[];
  helperText?: ReactNode;
  inputValue?: string;
  label: string;
  minLength?: number;
  name?: string;
  options?: unknown[];
  required?: boolean;
  getOptionLabel?(...args: unknown[]): string;
  renderOption?(option: unknown, state: AutocompleteRenderOptionState): ReactNode;
  renderNoOptionsText?(...args: unknown[]): ReactNode;
  requestSearch?(...args: unknown[]): Promise<{ data: { content: unknown[] } }>;
  onChange(...args: unknown[]): unknown;
}

export default function AutoCompleteSearch({
  disabled,
  error,
  filterOptions,
  helperText,
  inputValue,
  label,
  minLength,
  name,
  options: defaultOptions,
  required,
  getOptionLabel,
  renderOption,
  requestSearch,
  renderNoOptionsText,
  onChange,
}: AutoCompleteSearchProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('autocomplete');
  const safeDefaultOptions = useMemo(
    () => (typeof defaultOptions === 'undefined' ? [] : defaultOptions),
    [defaultOptions]
  );
  const [options, setOptions] = useState<unknown[]>(safeDefaultOptions);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [noOptionSelectedError, setNoOptionSelectedError] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(inputValue || '');
  const [searchTerm, setSearchTerm] = useState(inputValue || '');
  const searchTermDebounced = useDebounce(searchTerm, 150);

  const defaultOptionsText: ReactNode = isError ? t('error while loading') : t('no results found');
  const noOptionsText: ReactNode = renderNoOptionsText ? renderNoOptionsText(isError) : defaultOptionsText;

  const fetchData = useCallback(
    async (search) => {
      if (search === '') {
        setOptions([]);
      }
      if (typeof minLength === 'number' && search.length < minLength) {
        return;
      }

      setIsError(false);
      setIsLoading(true);
      try {
        if (requestSearch) {
          const result = await requestSearch(search);
          setOptions(result.data.content);
        }
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    },
    [minLength, requestSearch]
  );

  const handleSearch = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setOptionSelected(false);
    setAutocompleteValue(value);
    setSearchTerm(value);
  };

  const changeValue = useCallback(
    (value) => {
      if (value) {
        setOptionSelected(true);
        if (getOptionLabel) {
          setAutocompleteValue(getOptionLabel(value));
        }
        onChange(value);
      } else {
        setOptionSelected(false);
        setAutocompleteValue('');
        onChange('');
      }
    },
    [onChange, getOptionLabel]
  );

  useEffect(() => {
    if (open && requestSearch) {
      fetchData(searchTermDebounced);
    }
  }, [searchTermDebounced, open, fetchData, requestSearch]);

  useEffect(() => {
    return () => setOptions([]);
  }, []);

  useEffect(() => {
    setOptions(safeDefaultOptions);
    return () => setOptions(safeDefaultOptions);
  }, [safeDefaultOptions, setOptions]);

  const customFilterOptions: ((options: unknown[], state: FilterOptionsState<unknown>) => unknown[]) | undefined =
    requestSearch ? (x) => x : filterOptions;
  return (
    <Autocomplete
      clearOnEscape
      disabled={disabled}
      inputValue={autocompleteValue}
      filterOptions={customFilterOptions}
      getOptionLabel={getOptionLabel}
      options={options}
      open={open}
      fullWidth
      noOptionsText={noOptionsText}
      popupIcon={<ExpandMoreIcon />}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(e, selectedValue) => changeValue(selectedValue)}
      renderOption={renderOption}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          error={error || noOptionSelectedError}
          helperText={helperText || (noOptionSelectedError && t('must select an option'))}
          label={label}
          name={name}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            autoComplete: 'off',
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            // related to typescript issue https://github.com/microsoft/TypeScript/issues/28960
            // @ts-ignore
            'data-testid': name ? `autocomplete-search-input-${name}` : 'autocomplete-search-input',
          }}
          onChange={handleSearch}
          onBlur={() => {
            if (!optionSelected) {
              setAutocompleteValue('');
              setSearchTerm('');
              setNoOptionSelectedError(true);
            }
          }}
          onFocus={() => setNoOptionSelectedError(false)}
        />
      )}
    />
  );
}

AutoCompleteSearch.defaultProps = {
  disabled: false,
  error: undefined,
  filterOptions: undefined,
  helperText: null,
  inputValue: undefined,
  minLength: 2,
  name: null,
  options: [],
  required: false,
  getOptionLabel: (option: unknown) => option,
  requestSearch: null,
  renderOption: undefined,
  renderNoOptionsText: null,
};
