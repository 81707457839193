import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@material-ui/core';

import appTheme from '../../../../config/appTheme';
import { getLinkablePhoneNumber, SupportContact } from '../../../../utils/contactData';

const ICON_SIZE = '24px';
const ICON_MARGIN = `${appTheme.spacing(2)}px`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-top: ${(props) => props.theme.spacing(4)}px;
  }
`;

const StyledIcon = styled.div`
  width: ${ICON_SIZE};
  margin-right: ${ICON_MARGIN};
  color: ${(props) => props.theme.palette.primary.dark};

  > svg {
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
  }
`;

const StyledContent = styled.div`
  flex-grow: 0;
  // For narrow column widths, the icon will sit above the text, for wider ones beside
  min-width: calc(100% - (${ICON_SIZE} + ${ICON_MARGIN}));
  width: calc(16rem + (${ICON_SIZE} + ${ICON_MARGIN}));
  max-width: 100%;
`;

const StyledDescription = styled(Typography)`
  font-size: 1rem;
  line-height: 1.4;
  color: ${(props) => props.theme.palette.primary.dark};
`;

const StyledLinkWrapper = styled.div`
  margin-top: 0.5em;
  line-height: 1.4;
`;

const StyledLink = styled(Link)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 1.1666rem;
`;

interface ContactCardItemProps {
  contact: SupportContact;
}

const ContactCardItem = ({ contact }: ContactCardItemProps) => {
  const { t } = useTranslation('contact');

  const { key, icon, email, phone } = contact;

  // If the translation result matches the key the translation is missing
  const description = t(key) !== key ? t(key) : undefined;

  return (
    <StyledWrapper>
      <StyledIcon>{icon}</StyledIcon>
      <StyledContent>
        {description && <StyledDescription data-testid="contract-card-item-description">{t(key)}</StyledDescription>}
        <StyledLinkWrapper>
          <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
        </StyledLinkWrapper>
        {phone && (
          <StyledLinkWrapper>
            <StyledLink href={`tel:${getLinkablePhoneNumber(phone)}`}>{phone}</StyledLink>
          </StyledLinkWrapper>
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

export default ContactCardItem;
