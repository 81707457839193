// @ts-nocheck
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import bannerCoverImg from '../../assets/images/bannerImage.png';
import bannerCoverImgMobile from '../../assets/images/bannerImageMobile.png';

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 600px;
  background-image: url('${bannerCoverImg}');
  background-position: center;
  background-size: cover;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 500px;
    background-image: url('${bannerCoverImgMobile}');
  }
`;

const Section = styled.div`
  align-self: flex-end;
  padding: ${(props) => props.theme.spacing(0, 25, 14, 25)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3)}px;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  line-height: 6rem;
  color: ${(props) => props.theme.palette.common.white};
  margin: 0;
  padding-bottom: ${(props) => props.theme.spacing(4)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 4rem;
    line-height: 4.67rem;
    padding-bottom: ${(props) => props.theme.spacing(3)}px;
  }
`;

function BannerSection() {
  const { t } = useTranslation('landingpage');

  const scrollToSection = () => {
    document.getElementById('whatwedo').scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <SectionWrapper>
      <Section>
        <Title>{t('title')}</Title>
        <Button variant="outlined" color="secondary" onClick={scrollToSection}>
          {t('learn more')}
        </Button>
      </Section>
    </SectionWrapper>
  );
}

export default BannerSection;
