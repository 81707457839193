// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import CheckIcon from '@material-ui/icons/Check';
import { Fade } from '@material-ui/core';

import { borderStyle } from './commonStyles';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;

  ${borderStyle};
  border-radius: 50%;

  &.hasError {
    border: 1px solid ${(props) => props.theme.palette.common.red};
  }

  > svg {
    height: 0.6em;
    color: ${(props) => {
      if (props.disabled && props.checked) {
        return props.theme.palette.secondary.main;
      }
      if (props.checked) {
        return props.theme.palette.secondary.main;
      }
      return props.theme.palette.primary.lightest;
    }};
    transition: ${(props) =>
      props.theme.transitions.create(['border'], {
        easing: props.theme.transitions.easing.easeInOut,
        duration: props.theme.transitions.duration.short,
      })};
  }
`;

function CustomCheckbox({ checked, className, disabled }) {
  return (
    <CheckboxContainer checked={checked} disabled={disabled} className={className}>
      <Fade in={checked}>
        <CheckIcon />
      </Fade>
    </CheckboxContainer>
  );
}

CustomCheckbox.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomCheckbox;
