import { StatisticalValues } from '../../../../utils/models';
import { PeriodValue, RequestStatus } from '../types';

type Action =
  | { type: RequestStatus.loading }
  | { type: RequestStatus.success; payload: { data: StatisticalValues[]; period: PeriodValue } }
  | { type: RequestStatus.error };

interface State {
  status: RequestStatus;
  result: {
    data: StatisticalValues[];
    period: PeriodValue;
  } | null;
}

export const statisticalValuesRequestInitialState: State = {
  status: RequestStatus.initial,
  result: null,
};

export const statisticalValuesRequestReducer = (_: State, action: Action) => {
  switch (action.type) {
    case RequestStatus.loading:
      return { ...statisticalValuesRequestInitialState, status: RequestStatus.loading };
    case RequestStatus.success:
      return { ...statisticalValuesRequestInitialState, status: RequestStatus.success, result: action.payload };
    case RequestStatus.error:
      return { ...statisticalValuesRequestInitialState, status: RequestStatus.error };
    default:
      return statisticalValuesRequestInitialState;
  }
};
