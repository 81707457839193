/* tslint:disable */
/* eslint-disable */
/**
 * Consolidated API for apps/portals of the Mer Group
 * The idea behind this API is to create a common API to provide the functionality for a common customer portal used by fleet-managers, building owners, employees of companies, private customers and many more. 🇪🇺 Available for both backends. 🇩🇪 Available for ePower. 🇳🇴 Available for Frigg. 
 *
 * OpenAPI spec version: VERSION_PLACEHOLDER
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The channel used for starting a ChargingSession.
 * @export
 * @enum {string}
 */
export enum StartMedium {
    APP = 'APP',
    RFID = 'RFID',
    HOME = 'HOME',
    AUTOCHARGE = 'AUTOCHARGE'
}

