import { Typography } from '@material-ui/core';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';

import station from '../../../assets/illustrations/signupSuccess.svg';
import { Contract, HomeType } from '../../../utils/models';

export function isDigitalPrecheckDone(selectedContract: Contract) {
  return (
    selectedContract?.digitalPrecheckStatus === 'SUBMITTED' ||
    selectedContract?.digitalPrecheckStatus === 'MANUAL_PRECHECK' ||
    selectedContract?.digitalPrecheckStatus === 'APPROVED'
  );
}

export function areDocumentsUploaded(selectedContract: Contract) {
  return selectedContract?.documents?.length && selectedContract?.documents?.length > 0;
}

export function isFinalState(contractStatus: string | undefined, chargingStationState: string | undefined) {
  return contractStatus === 'ACTIVE' && chargingStationState === 'OPERATIVE';
}

export const isStepCompleted = (
  step: number,
  selectedContract: Contract,
  chargingStationState: string,
  documentsUploaded: boolean
) => {
  return (
    (step === 1 && isDigitalPrecheckDone(selectedContract)) ||
    (step === 2 &&
      selectedContract.homeType === HomeType.SINGLEHOME &&
      (documentsUploaded || areDocumentsUploaded(selectedContract))) ||
    ((step === 3 || (step === 2 && selectedContract.homeType === HomeType.MULTIHOME)) &&
      isFinalState(selectedContract.contractStatus, chargingStationState))
  );
};

export function isFinalStepDisabled(selectedContract: Contract, uploadedNow?: boolean) {
  return !(
    isDigitalPrecheckDone(selectedContract) &&
    (areDocumentsUploaded(selectedContract) || selectedContract.homeType === HomeType.MULTIHOME || uploadedNow)
  );
}

export function isUploadStepDisabled(selectedContract: Contract) {
  return selectedContract.homeType === HomeType.MULTIHOME;
}

export const isStepInProgress = (
  step: number,
  selectedContract: Contract,
  chargingStationState: string | undefined
) => {
  return (
    !isFinalStepDisabled(selectedContract) &&
    step === 3 &&
    isFinalState(selectedContract.contractStatus, chargingStationState)
  );
};

export function getButtonText(i: number, amountOfSteps: number): string {
  let text = '';
  if (i === 1) {
    text = 'submit digital';
  } else if (i === 3 || amountOfSteps === 2) {
    text = 'station progress';
  } else if (i === 2) {
    text = 'upload consent';
  }
  return text;
}

const Headline = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: 2rem;
`;

const ProductImage = styled.div`
  background: url('${station}');
  margin-right: ${(props) => props.theme.spacing(2.5)}px;
  background-size: cover;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export function isDisabled(
  selectedContract: Contract,
  contractDocumentId: string | undefined,
  isFinalStep: boolean,
  isMiddleStep: boolean,
  chargingStationState: string | undefined
) {
  return (
    (isFinalStepDisabled(selectedContract, !!contractDocumentId) && isFinalStep) ||
    (isUploadStepDisabled(selectedContract) && isMiddleStep) ||
    isFinalState(selectedContract?.contractStatus, chargingStationState)
  );
}

export function getHeader(
  t: TFunction<'processwidget', undefined>,
  contractStatus?: string,
  chargingStationState?: string
) {
  return (
    <HeaderContainer>
      {contractStatus !== 'ACTIVE' && <ProductImage />}
      <Headline color="primary">
        {isFinalState(contractStatus, chargingStationState) ? t('well done') : t('a few more steps')}
      </Headline>
    </HeaderContainer>
  );
}
