import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AddIcon from '../../../Icons/AddIcon';
import { CONTRACTS, FOLLOW_UP_CONTRACTS } from '../../../../config/routes';

const Wrapper = styled.div<{ disabled: boolean; isFollowUp: boolean }>`
  display: flex;
  padding: ${(props) => props.theme.spacing(3, 3, 3, 3)};
  border-top: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) =>
    props.disabled ? props.theme.palette.tertiary.main : props.theme.palette.primary.main} !important;

  > svg {
    color: ${(props) => (props.disabled ? props.theme.palette.tertiary.main : props.theme.palette.primary.main)};
    width: 21px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }

  .MuiTypography-colorPrimary.disabled {
    color: ${(props) => props.theme.palette.tertiary.main};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: absolute;
    bottom: ${(props) => (props.isFollowUp ? 0 : '60px')};
    width: 100%;
  }
`;

function AddNewContractSection({
  handleClose,
  isFollowUp,
  disabled,
}: {
  handleClose: () => void;
  isFollowUp: boolean;
  disabled: boolean;
}) {
  const history = useHistory();
  const { t } = useTranslation('contracts');

  const handleAddContract = () => {
    window.location.hash = '';
    history.push(isFollowUp ? FOLLOW_UP_CONTRACTS : CONTRACTS);
    handleClose();
  };

  return (
    <Wrapper disabled={disabled} isFollowUp={isFollowUp}>
      <AddIcon />
      <Typography
        variant="body1"
        color="primary"
        onClick={disabled ? undefined : handleAddContract}
        className={disabled ? 'disabled' : ''}
      >
        {isFollowUp ? t('add new follow-up contract') : t('add new contract')}
      </Typography>
    </Wrapper>
  );
}

export default AddNewContractSection;
