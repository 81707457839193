// @ts-nocheck
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PageLayout from '../../layout/PageLayout';

const StyledText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const Title = styled(Typography)`
  margin: 0 0 2rem;
`;

function CookiePolicyEN() {
  const { t } = useTranslation('cookiePolicy');
  return (
    <PageLayout>
      <Title variant="h1" color="primary">
        {t('cookie policy')}
      </Title>
      <Typography variant="h3">1. Introduction</Typography>
      <StyledText varaint="body1" paragraph>
        Our customer portal, <Link href="https://groa.mer.eco">https://groa.mer.eco</Link>
        (subsequently mentioned as groa, customer portal or website) uses cookies. They may also be placed by third
        parties delegated by us. In the following text, we inform you about the cookie usage on this website.
      </StyledText>
      <Typography variant="h3">2. What are cookies?</Typography>
      <StyledText varaint="body1" paragraph>
        A cookie is a simple and small file, which is sent together with website data and which can be stored on a users
        device. It contains information which can be sent to our server as well as a third party server during the visit
        of our website.
      </StyledText>
      <Typography variant="h3">3. Consent</Typography>
      <StyledText varaint="body1" paragraph>
        On your first visit to our website we will show you a popup with a short explanation about cookies. By clicking
        on &ldquo;Ok&rdquo;, you consent that we are allowed to use all cookies mentioned in the popup and within this
        explanation. You are able to deactivate cookies in your browser. Please be aware, that our customer portal
        relies on some cookies
      </StyledText>
      <Typography variant="h3">4. Cookies</Typography>
      <Typography variant="h3">4.1 Technical or functional cookies</Typography>
      <StyledText varaint="body1" paragraph>
        Some cookies ensure proper functionality of groa. With them, we facilitate your usage of groa, e.g. by remember
        your login status for a while. Your consent is not necessary for this kind of cookies.
      </StyledText>
      <StyledText varaint="body1" paragraph>
        Used Cookies: <br />
        Authentication Cookie
      </StyledText>
      <Typography variant="h3">4.2 Cookies used for analytics</Typography>
      <StyledText varaint="body1" paragraph>
        We do not use cookies for analytics.
      </StyledText>
      <Typography variant="h3">4.3 Advertising cookies</Typography>
      <StyledText varaint="body1" paragraph>
        We do not use advertising cookies.
      </StyledText>
      <Typography variant="h3">5. Activation/Deactivation and deletion of cookies</Typography>
      <StyledText varaint="body1" paragraph>
        You can use your browsers settings to manually or automatically delete cookies. You can also prevent your
        browser from storing cookies at all or to notify you each time when a cookie is set. Further information on this
        topic can be found in the browser help. <br />
        Please be aware that groa might not work properly when cookies are disabled. Groa will try to store cookies on
        every new visit after you deleted groas cookies.
      </StyledText>
      <Typography variant="h3">6. Contact</Typography>
      <StyledText varaint="body1" paragraph>
        Reach out if you have questions regarding our cookie policy: <br />
        Mer Germany GmbH <br />
        Taunusstraße 23, 80807 München <br />
        Deutschland <br />
      </StyledText>
      <StyledText varaint="body1" paragraph>
        Website: <Link href="https://de.mer.eco">https://de.mer.eco</Link> <br />
        E-Mail: <Link href="mailto:service.plus@mer.eco">service.plus@mer.eco</Link> <br />
        Phone number: +49 89 80913300
      </StyledText>
    </PageLayout>
  );
}

export default CookiePolicyEN;
