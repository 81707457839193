// @ts-nocheck
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import randomPersonImg from '../../assets/images/portalMotif.png';

import LandingPageAccordion from './LandingPageAccordion';

const SectionWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing(19, 7)};
  background: ${(props) => props.theme.palette.tertiary.bright};

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(10, 3)};
  }
`;

const SectionImg = styled.div`
  background: url('${randomPersonImg}') no-repeat;
  background-position: center;
  background-size: cover;
  flex: 1;
  height: 650px;
`;

const SectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(5, 0, 5, 9)};
  flex: 2;

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const SectionTitle = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 4rem;
  line-height: 5.5rem;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  margin: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 2.7rem;
    line-height: 3.3rem;
    padding-bottom: ${(props) => props.theme.spacing(5)}px;
  }
`;

const Description = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

function OurPortalSection() {
  const { t } = useTranslation('landingpage');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionWrapper id="our-portal">
      {isDesktop && <SectionImg />}
      <SectionDetails>
        <SectionTitle color="primary">{t('our customer portal')}</SectionTitle>
        <Description variant="h4" color="primary">
          {t('why groa')}
        </Description>
        {[...Array(4)].map((_, index) => (
          <LandingPageAccordion
            key={`step ${index + 1}`}
            title={t(`step ${index + 1}`)}
            description={t(`step ${index + 1} description`)}
          />
        ))}
      </SectionDetails>
    </SectionWrapper>
  );
}

export default OurPortalSection;
