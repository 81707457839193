// @ts-nocheck
import styled from 'styled-components/macro';
import { TableCell } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)`
  background-color: transparent;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: ${(props) => props.theme.spacing(1.25)}px;
  }
`;
