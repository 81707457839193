import i18n from 'i18next';

interface GreetingsInterface {
  defaults: string[];
  dayPeriods: { from: number; to: number; messages: string[] }[];
}

export const generateGreeting = (greetings: GreetingsInterface) => {
  let output = '';
  if (greetings.dayPeriods && (Math.floor(Math.random() * 2) > 0 || !greetings.defaults)) {
    const today = new Date();
    const curHr = today.getHours();
    greetings.dayPeriods.forEach((interval) => {
      if (curHr >= interval.from && curHr <= interval.to) {
        output = interval.messages[Math.floor(Math.random() * interval.messages.length)];
      }
    });
  }
  if (!output && greetings.defaults) {
    output = greetings.defaults[Math.floor(Math.random() * greetings.defaults.length)];
  }
  return output;
};

export const greeterGreetings = (name: string): GreetingsInterface => ({
  defaults: [i18n.t('dashboard:default greeting', { name })],
  dayPeriods: [
    {
      from: 3,
      to: 11,
      messages: [i18n.t('dashboard:morning greeting', { name })],
    },
    {
      from: 17,
      to: 21,
      messages: [i18n.t('dashboard:evening greeting', { name })],
    },
  ],
});
