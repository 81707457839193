// @ts-nocheck
/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components/macro';

export const borderStyle = css`
  border: 1px solid
    ${(props) => {
      if (props.disabled && !props.checked) {
        return props.theme.palette.primary.bright;
      }
      return props.theme.palette.primary.main;
    }};

  transition: ${(props) =>
    props.theme.transitions.create(['border', 'opacity'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};
`;
