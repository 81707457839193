import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import DocumentDownloadCard from '../Documents/DocumentDownload';
import appTheme from '../../../config/appTheme';
import Card from '../../../layout/Card';

import FaqCard from './FaqCard';
import CarbonCard from './CarbonCard';
import ContactCard from './ContactCard';

const DEFAULT_ORDER = 3; // Default of 3 allows change of positions 1 and 2 by dedicated order per box

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(7, 0, 3, 0)};
  color: ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(6, 0, 3, 0)};
  }
`;

const StyledGridItem = styled(Grid)<{ order?: number }>`
  order: ${(props) => props.order || DEFAULT_ORDER};
`;

interface InterestingFactsProps {
  isManager?: boolean;
}

const defaultProps = { isManager: true };

function InterestingFacts({ isManager = defaultProps.isManager }: InterestingFactsProps) {
  const { t } = useTranslation('dashboard');

  const md = useMediaQuery(appTheme.breakpoints.only('md'));

  const getMediumColumnWidth = () => {
    // Three in a row for managers, two in a row for drivers
    return isManager ? 4 : 6;
  };

  const getLargeColumnWidth = () => {
    // Three in a row for managers, four in a row for drivers
    return isManager ? 4 : 3;
  };

  return (
    <>
      <Title variant="h4">{t('facts title')}</Title>
      <Grid container spacing={2}>
        {!isManager && (
          <StyledGridItem item xs={12} md={getMediumColumnWidth()} lg={getLargeColumnWidth()} order={1}>
            <DocumentDownloadCard />
          </StyledGridItem>
        )}
        <StyledGridItem item xs={12} sm={12} md={getMediumColumnWidth()} lg={getLargeColumnWidth()}>
          <Card>
            <FaqCard />
          </Card>
        </StyledGridItem>
        <StyledGridItem item xs={12} sm={12} md={getMediumColumnWidth()} lg={getLargeColumnWidth()}>
          <Card>
            <CarbonCard />
          </Card>
        </StyledGridItem>
        <StyledGridItem
          item
          xs={12}
          sm={12}
          md={getMediumColumnWidth()}
          lg={getLargeColumnWidth()}
          // For drivers on md screens: two in a row and contact box as second
          order={!isManager && md ? 2 : DEFAULT_ORDER}
        >
          <Card background={appTheme.palette.secondary.bright} noBorder>
            <ContactCard />
          </Card>
        </StyledGridItem>
      </Grid>
    </>
  );
}

InterestingFacts.defaultProps = defaultProps;

export default InterestingFacts;
