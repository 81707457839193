import { useCallback } from 'react';
import styled from 'styled-components/macro';
import { IconButton, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import useSWR from 'swr';
import Skeleton from '@material-ui/lab/Skeleton';
import i18next from 'i18next';

import ContractIcon from '../../../Icons/ContractIcon';
import { Document, StaticDocument } from '../../../../utils/models';
import DownloadIcon from '../../../Icons/DownloadIcon';
import {
  downloadDocument,
  downloadStaticDocument,
  getAvailableProducts,
  getMyDocuments,
  getWallboxGuides,
} from '../../../../utils/api';
import Card from '../../../../layout/Card';
import { preventAutoRevalidationConfig } from '../../../../utils/preventAutoRevalidationConfig';

import { showStaticDocuments } from './ShowStaticDocuments';

const StyledCardWrapper = styled.div`
  height: 100%; // Fill full height of card boxes

  ${(props) => props.theme.breakpoints.down('sm')} {
    scroll-margin-top: 70px; // Height of the mobile header
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  svg {
    margin-right: ${(props) => props.theme.spacing(1)}px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const DocumentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${(props) => props.theme.spacing(2, 0)};
`;

const DocumentListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1, 2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
`;

const SkeletonWrapper = styled.li`
  margin: 0;
  padding: 0;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 30px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

const StyledText = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeight};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledIconButton = styled(IconButton)`
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const getFileName = (name: string, url: string) => {
  const [urlWithoutQueryParams] = url?.split('?');
  const extension = urlWithoutQueryParams?.split('.')?.pop();

  return `${name}.${extension}`;
};

function DocumentDownloadCard() {
  const currentLang = i18next.language;
  const { t } = useTranslation(['dashboard', 'errors']);

  const { data: products, error: productsError } = useSWR(
    'getAvailableProducts',
    getAvailableProducts,
    preventAutoRevalidationConfig
  );
  const { data: customerDocuments, error: customerDocumentsError } = useSWR(
    'getMyDocuments',
    getMyDocuments,
    preventAutoRevalidationConfig
  );
  const { data: staticDocuments, error: staticDocumentsError } = useSWR(
    ['getWallboxGuides', currentLang],
    getWallboxGuides,
    preventAutoRevalidationConfig
  );

  const isLoadingCustomerDocuments = !customerDocuments && !customerDocumentsError;
  const isLoadingStaticDocuments = !staticDocuments && !staticDocumentsError && !products && !productsError;
  const isLoading = isLoadingCustomerDocuments || isLoadingStaticDocuments;
  const hasLoadingError = customerDocumentsError && staticDocumentsError;

  const noDocumentsFound =
    !isLoading && !customerDocuments?.length && (!staticDocuments?.length || !showStaticDocuments(products));

  const downloadCustomerDoc = useCallback(async (document: Document) => {
    const { id, title, url } = document;
    if (id && title && url) {
      const { data } = await downloadDocument(id);
      fileDownload(data, getFileName(title, url));
    }
  }, []);

  const downloadStaticDoc = useCallback(async ({ url, displayName }: StaticDocument) => {
    const { data } = await downloadStaticDocument(url);
    fileDownload(data, getFileName(displayName, url));
  }, []);

  const getLoadingIndicator = () => {
    return (
      <SkeletonWrapper>
        <StyledSkeleton variant="rect" />
        <StyledSkeleton variant="rect" />
      </SkeletonWrapper>
    );
  };

  const getDocumentListItem = ({
    key,
    title,
    onClick,
  }: {
    key: string | undefined;
    title: string | undefined;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <DocumentListItem key={key}>
        <StyledText variant="h5" color="primary">
          {title}
        </StyledText>
        <StyledIconButton aria-label="download" size="small" onClick={onClick}>
          <DownloadIcon />
        </StyledIconButton>
      </DocumentListItem>
    );
  };

  const getCustomerDocuments = () => {
    if (isLoadingCustomerDocuments) {
      return getLoadingIndicator();
    }

    return customerDocuments?.map((doc) => {
      return getDocumentListItem({ key: doc.id, title: doc.title, onClick: () => downloadCustomerDoc(doc) });
    });
  };

  const getStaticDocuments = () => {
    if (isLoadingStaticDocuments) {
      return getLoadingIndicator();
    }

    if (!showStaticDocuments(products)) {
      return [];
    }

    return staticDocuments?.map((staticDoc) => {
      return getDocumentListItem({
        key: `${staticDoc.displayName}-${staticDoc.language}`,
        title: staticDoc.displayName,
        onClick: () => downloadStaticDoc(staticDoc),
      });
    });
  };

  const renderContent = () => {
    if (hasLoadingError) {
      return (
        <Wrapper>
          <ErrorOutlineIcon />
          <StyledText variant="h5" color="primary">
            {t('errors:error processing response')}
          </StyledText>
        </Wrapper>
      );
    }

    if (noDocumentsFound) {
      return (
        <Wrapper>
          <ContractIcon />
          <StyledText variant="h5" color="primary">
            {t('no documents found')}
          </StyledText>
        </Wrapper>
      );
    }

    return (
      <DocumentList>
        {getCustomerDocuments()}
        {getStaticDocuments()}
      </DocumentList>
    );
  };

  return (
    // ID is used as scroll target
    <StyledCardWrapper id="availableDocuments">
      <Card hasHeader title={t('available documents')}>
        {renderContent()}
      </Card>
    </StyledCardWrapper>
  );
}

export default DocumentDownloadCard;
