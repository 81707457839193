import styled from 'styled-components/macro';
import { Popover, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Contract } from '../../../../utils/models';

import ContractSelectorList from './ContractSelectorList';
import AddNewContractSection from './AddNewContractSection';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
`;

const Title = styled(Typography)`
  padding: ${(props) => props.theme.spacing(4.5, 3, 2, 3)};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

interface DesktopContractSelectorMenuProps {
  contractList?: Contract[];
  selectedContract?: Contract;
  isOpen: boolean;
  anchorEl?: Element | null;
  eligibleForFollowUpContract: boolean;
  handleClose(...args: unknown[]): unknown;
}

function DesktopContractSelectorMenu({
  contractList,
  selectedContract,
  isOpen,
  anchorEl,
  handleClose,
  eligibleForFollowUpContract,
}: DesktopContractSelectorMenuProps) {
  const { t } = useTranslation('contracts');
  return (
    <Popover
      id="contract-selector-menu"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ContentWrapper>
        <Title variant="h4" color="primary">
          {t('my contracts')}
        </Title>

        {contractList !== undefined && (
          <ContractSelectorList
            contractList={contractList}
            selectedContract={selectedContract}
            handleClose={handleClose}
          />
        )}
        <AddNewContractSection handleClose={handleClose} isFollowUp={false} disabled={false} />
        <AddNewContractSection handleClose={handleClose} isFollowUp disabled={!eligibleForFollowUpContract} />
      </ContentWrapper>
    </Popover>
  );
}

DesktopContractSelectorMenu.defaultProps = {
  anchorEl: null,
  selectedContract: null,
  contractList: [],
};

export default DesktopContractSelectorMenu;
