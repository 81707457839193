import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import NoDataFound from '../../Icons/NoDataFound';
import ErrorBubble from '../../Icons/ErrorBubble';

import { BAR_CHART_HEIGHT } from './constants';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  min-height: ${BAR_CHART_HEIGHT}px;
  padding-bottom: ${(props) => props.theme.spacing(5)}px;

  svg {
    height: 144px;
    width: 168px;
  }
`;

const StyledText = styled(Typography)`
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export enum NonIdealStateType {
  ERROR,
  EMPTY,
}

export function NonIdealState({ type }: { type: NonIdealStateType }): JSX.Element {
  const { t } = useTranslation('statistics');

  return (
    <IconWrapper data-testid="non-ideal-state">
      {type === NonIdealStateType.EMPTY ? <NoDataFound /> : <ErrorBubble />}
      <StyledText>{type === NonIdealStateType.EMPTY ? t('no data') : t('errors:error processing response')}</StyledText>
    </IconWrapper>
  );
}
