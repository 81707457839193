// @ts-nocheck
import styled from 'styled-components/macro';
import { Skeleton } from '@material-ui/lab';
import { Hidden } from '@material-ui/core';

import { useAuth0 } from '../utils/Auth0Provider';

import PageLoadingSpinner from './PageLoadingSpinner';

const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UserPageContent = styled.div`
  max-width: ${(props) => props.theme.general.maxWidth};
  padding: ${(props) => props.theme.spacing(5, 8, 14)};
  flex-grow: 1;
  flex-shrink: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3, 5, 10)};
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(3, 2, 6)};
  }
`;

const UserHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(2, 4)};

  > *:not(:last-of-type) {
    margin-right: 2.5rem;
  }
`;

function PageSkeleton() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <PageLoadingSpinner />;
  }

  return (
    <Wrapper data-testid="page-loading">
      <Hidden smDown>
        <Skeleton variant="rect" width={64} height="100vh" />
      </Hidden>
      <Content>
        <UserHeader>
          <Skeleton variant="circle" width={30} height={30} />
          <Skeleton variant="circle" width={30} height={30} />
        </UserHeader>
        <UserPageContent>
          <Skeleton width={160} height={24} />
          <Skeleton width={300} height={28} />
        </UserPageContent>
      </Content>
    </Wrapper>
  );
}

export default PageSkeleton;
