export default class Cache<T> {
  cache: Record<string, T>;

  constructor() {
    this.cache = {};
  }

  hasItem(key: string) {
    return key in this.cache;
  }

  getItem(key: string) {
    return this.cache[key];
  }

  setItem(key: string, value: T) {
    this.cache[key] = value;
  }

  removeItem(key: string) {
    delete this.cache[key];
  }

  clear() {
    this.cache = {};
  }
}
