import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { setupAuth0Client } from './Auth0Provider';
import { getAssumedRole, translateAsummedRoleToAuth0Role } from './assumedRole';
import {
  BasicVehicleData,
  ChargePoint,
  Contract,
  ContractOrderRequest,
  Document,
  DocumentType,
  LanguageCode,
  PageContentChargeDetailRecord,
  PageContentContract,
  PageContentInvitation,
  PageContentOrganization,
  Product,
  SelfSignupToken,
  SelfSignupTokenVerification,
  StaticDocument,
  StatisticalValues,
  UpdateUserSettingsRequest,
  User,
  UserSettings,
} from './models';
import { StatisticType } from './models/statistic-type';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  noAuth?: boolean;
}

let token: Promise<never> | null = null;

async function setupAdditionalHeaders() {
  if (!token) {
    const auth0Client = await setupAuth0Client();
    token = await auth0Client.getTokenSilently();
  }

  const assumedRole = await getAssumedRole();
  let headerRole = '';
  if (typeof assumedRole === 'string') {
    headerRole = translateAsummedRoleToAuth0Role(assumedRole);
  }

  return {
    Authorization: `Bearer ${token}`,
    assumedRole: headerRole,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_AORTA_GATEWAY,

  headers: {
    accept: 'application/json',
    'x-epower-authentication-provider': 'auth0',
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiResponseInterceptor = async (configData: { config: ExtendedAxiosRequestConfig; headers: any }) => {
  if (configData) {
    const newConfig = configData;
    const newHeaders = configData.config.noAuth ? {} : await setupAdditionalHeaders();
    newConfig.headers = {
      ...configData.headers,
      ...newHeaders,
    };
    return newConfig;
  }
  return configData;
};

// Disable interceptors for testing
if (process.env.NODE_ENV !== 'test') {
  api.interceptors.request.use(
    async (configData: ExtendedAxiosRequestConfig) => {
      const newConfig = configData;
      const newHeaders = configData.noAuth ? {} : await setupAdditionalHeaders();

      newConfig.headers = {
        ...configData.headers,
        ...newHeaders,
      };

      return newConfig;
    },

    (error) => {
      Promise.reject(error);
    }
  );
}

export default api;

const versionOne = 'v1';

/* --- GET requests --- */
export async function getPermissions() {
  return api.get(`${versionOne}/users/me/permissions`);
}

export async function getOrganizations(searchParams?: {
  filter: never;
}): Promise<AxiosResponse<PageContentOrganization>> {
  const params = {
    pageSize: 100,
    pageNumber: 0,
    ...searchParams,
  };
  return api.get(`${versionOne}/organizations`, { params });
}

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  pageSize: number;
  pageNumber: number;
  sort: string;
  searchString?: string;
}

export async function getMyOrganizationContracts(
  params: AxiosRequestConfigExtended,
  filters?: string
): Promise<PageContentContract> {
  return api
    .get(`${versionOne}/organizations/my/contracts${filters ? `?${filters}` : ''}`, { params })
    .then((response) => response.data);
}

export async function exportMyOrganizationContracts(): Promise<AxiosResponse<Blob>> {
  const downloadFileConfig: AxiosRequestConfig = {
    headers: { accept: 'application/octet-stream' },
    responseType: 'blob',
    timeout: 30000,
  };
  return api.get(`${versionOne}/organizations/my/contracts/export`, downloadFileConfig);
}

export async function getMyPersonalContracts(): Promise<Array<Contract>> {
  return api.get(`${versionOne}/users/me/contracts`).then((response) => response.data);
}

export async function getMyPersonalContractById(contractId: string): Promise<Contract> {
  return api.get(`${versionOne}/users/me/contracts/${contractId}`).then((response) => response.data);
}

export async function getMyOrganizationContractById(contractId: string): Promise<Contract> {
  return api.get(`${versionOne}/organizations/my/contracts/${contractId}`).then((response) => response.data);
}

export async function getAvailableProducts(): Promise<Array<Product>> {
  return api.get(`${versionOne}/organizations/my/products`).then((response) => response.data);
}

// Static documents

export async function getStaticDocuments(type: DocumentType): Promise<Array<StaticDocument>> {
  return api.get(`${versionOne}/static/documents?type=${type}`).then((response) => response.data);
}

export async function getBuildingConsentTemplate(): Promise<string> {
  const docs = await getStaticDocuments(DocumentType.BUILDINGCONSENT);

  // Only a single document is expected as "Gestattung"
  return docs?.[0].url;
}

export async function getWallboxGuides(_key: string, currentLang?: string) {
  const staticDocs = await getStaticDocuments(DocumentType.WALLBOXGUIDE);

  const validLanguageCode = Object.values(LanguageCode).find((langCode) => langCode === currentLang?.toUpperCase());
  if (validLanguageCode) {
    return staticDocs.filter(({ language }) => language === validLanguageCode);
  }

  return staticDocs;
}

export async function downloadStaticDocument(url: string): Promise<AxiosResponse<Blob>> {
  const downloadFileConfig: AxiosRequestConfig = {
    headers: { accept: '*/*' },
    responseType: 'blob',
    timeout: 30000,
  };
  return axios.get(url, downloadFileConfig);
}

// Customer documents

export async function getMyDocuments(): Promise<Document[]> {
  return api.get(`${versionOne}/organizations/my/documents`).then((response) => response.data);
}

export async function downloadDocument(id: string): Promise<AxiosResponse<Blob>> {
  const downloadFileConfig: AxiosRequestConfig = {
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
    timeout: 30000,
  };
  return api.get(`${versionOne}/organizations/my/documents/${id}`, downloadFileConfig);
}

export async function downloadContractDocument(id: string): Promise<AxiosResponse<Blob>> {
  const downloadFileConfig: AxiosRequestConfig = {
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
    timeout: 30000,
  };
  return api.get(`${versionOne}/users/me/documents/${id}`, downloadFileConfig);
}

export async function getUserSettings(): Promise<UserSettings> {
  return api.get(`${versionOne}/users/me/settings`).then((response) => response.data);
}

export async function getUserCompleteProfile(): Promise<User> {
  return api.get(`${versionOne}/users/me/profile`).then((response) => response.data);
}

// Statistics

interface StatisticsParams {
  contractId?: number | string;
  statisticTypes: StatisticType[];
}

function createStatisticsParameters(
  contractId: number | undefined,
  statisticTypes = [StatisticType.ENERGYCONSUMPTION, StatisticType.CO2SAVING]
): StatisticsParams {
  const params: StatisticsParams = {
    statisticTypes,
  };

  if (typeof contractId === 'number') {
    params.contractId = contractId;
  }

  return params;
}

export async function getStatisticsCo2SavingsForMonth(
  year: number,
  month: number,
  contractId: number
): Promise<StatisticalValues> {
  const params = createStatisticsParameters(contractId, [StatisticType.CO2SAVING]);

  return api
    .get(`${versionOne}/users/me/statistics/years/${year}/months/${month}`, { params })
    .then((response) => response.data);
}

export async function getStatisticsDataForWeekByDays(
  year: number,
  week: number,
  contractId: number | undefined,
  signal?: AbortSignal
): Promise<Array<StatisticalValues>> {
  const params = createStatisticsParameters(contractId);

  return api
    .get(`${versionOne}/users/me/statistics/years/${year}/weeks/${week}/days`, { params, signal })
    .then((response) => response.data);
}

export async function getStatisticsDataForMonthByDays(
  year: number,
  month: number,
  contractId: number | undefined,
  signal?: AbortSignal
): Promise<Array<StatisticalValues>> {
  const params = createStatisticsParameters(contractId);

  return api
    .get(`${versionOne}/users/me/statistics/years/${year}/months/${month}/days`, { params, signal })
    .then((response) => response.data);
}

export async function getStatisticsDataForYearByMonths(
  year: number,
  contractId: number | undefined,
  signal?: AbortSignal
): Promise<Array<StatisticalValues>> {
  const params = createStatisticsParameters(contractId);

  return api
    .get(`${versionOne}/users/me/statistics/years/${year}/months`, { params, signal })
    .then((response) => response.data);
}

export async function getUserCdrsByContractid(
  contractId: number,
  dateFrom: string,
  dateTo: string,
  pageNumber: number
): Promise<PageContentChargeDetailRecord> {
  const params = {
    pageNumber,
    pageSize: 10,
  };
  return api
    .get(`${versionOne}/users/me/contracts/${contractId}/cdrs?dateFrom=${dateFrom}&dateTo=${dateTo}`, { params })
    .then((response) => response.data);
}

export async function getUserMeCdrs(
  dateFrom: string,
  dateTo: string,
  pageNumber: number
): Promise<PageContentChargeDetailRecord> {
  const params = {
    pageNumber,
    pageSize: 10,
  };
  return api
    .get(`${versionOne}/users/me/cdrs?dateFrom=${dateFrom}&dateTo=${dateTo}`, { params })
    .then((response) => response.data);
}

export async function getUsersMeCdrCsvExport(
  dateFrom: string,
  dateTo: string,
  onDownloadProgress?: (event: ProgressEvent) => void,
  signal?: AbortSignal
): Promise<AxiosResponse<Blob>> {
  const params = { dateFrom, dateTo };

  const downloadFileConfig: AxiosRequestConfig = {
    headers: { accept: 'application/octet-stream' },
    responseType: 'blob',
    onDownloadProgress,
  };

  return api.get(`${versionOne}/users/me/cdrs/export`, { params, signal, ...downloadFileConfig });
}

export async function getUserCdrsCalibrationLawExport(
  contractId: number | string,
  cdrId: number | string
): Promise<Blob> {
  const downloadFileConfig: AxiosRequestConfig = {
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
    timeout: 30000,
  };
  return api
    .get(`${versionOne}/users/me/contracts/${contractId}/cdrs/${cdrId}/export`, downloadFileConfig)
    .then((response) => response.data);
}

// Signup

export async function getSelfSignupTokenByOrganization(organizationId: number): Promise<Array<SelfSignupToken>> {
  return api.get(`${versionOne}/organizations/${organizationId}/selfsignuptokens`).then((response) => response.data);
}

export async function validateSignupToken(
  organizationId: number,
  selfSignupTokenId: string
): Promise<SelfSignupTokenVerification> {
  const config: ExtendedAxiosRequestConfig = {
    noAuth: true,
  };
  return api
    .get(`${versionOne}/organizations/${organizationId}/selfsignuptokens/${selfSignupTokenId}/verification`, config)
    .then((response) => response.data);
}

export async function getNumberOfInvitations(): Promise<number> {
  return api.get(`${versionOne}/organizations/my/invitations/count`).then((response) => response.data);
}

export async function getAllInvitations(params: AxiosRequestConfig): Promise<PageContentInvitation> {
  return api.get(`${versionOne}/organizations/my/invitations`, { params }).then((response) => response.data);
}

export async function getStationByUuid(uuid: string): Promise<ChargePoint> {
  return api.get(`${versionOne}/chargepoints/${uuid}`).then((response) => response.data);
}

export async function getVehicles(): Promise<Array<BasicVehicleData>> {
  return api.get(`${versionOne}/vehicles`).then((response) => response.data);
}

/* --- POST requests --- */
export async function uploadDocument(file: string, body: never, contractId: number): Promise<AxiosResponse<Document>> {
  const formData = new FormData();
  formData.append('file', file);
  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  formData.append('fileUploadRequest', blob);

  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post(`${versionOne}/users/me/contracts/${contractId}/documents`, formData, { headers });
}

export async function orderContract(body: ContractOrderRequest): Promise<AxiosResponse<string>> {
  return api.post(`${versionOne}/organizations/my/contracts`, body);
}

export async function resendInvitationToUser(userId: number): Promise<AxiosResponse<void>> {
  return api.post(`${versionOne}/users/${userId}/resendinvitation`);
}

export async function inviteUser(
  organizationId: string,
  body: User,
  signupToken: string | null = null
): Promise<AxiosResponse<User>> {
  // create a user as an invitee
  const signupParam = signupToken ? `?selfSignupTokenId=${signupToken}` : '';
  const config: ExtendedAxiosRequestConfig = {
    noAuth: !!signupToken,
  };
  return api.post(`${versionOne}/organizations/${organizationId}/users${signupParam}`, body, config);
}

/* --- PUT requests --- */
export async function updateUserSettings(body: UpdateUserSettingsRequest): Promise<AxiosResponse<UserSettings>> {
  return api.put(`${versionOne}/users/me/settings`, body);
}

/* --- PATCH requests --- */
export async function updateMyOrganizationContractById(contractId: string, body: Contract) {
  return api
    .patch<Contract, AxiosResponse<null>>(`${versionOne}/organizations/my/contracts/${contractId}`, body)
    .then((response) => response.data);
}
