import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

const auth: Auth0ClientOptions = {
  domain: process.env.REACT_APP_AUTH_0_ISSUER || 'NO_DOMAIN',
  client_id: process.env.REACT_APP_AUTH_0_CLIENT_ID || 'NO_CLIENT_ID',
  audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
  redirect_uri: window.location.origin,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
};

export default auth;
