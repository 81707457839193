import { usePermissions } from './PermissionsProvider';

export default function UserHas({
  children,
  fallback,
  permission,
  role,
}: {
  children?: JSX.Element;
  fallback?: JSX.Element;
  permission?: string | string[] | null;
  role?: string;
}) {
  const { hasPermissions, hasAssumedRole, loadingPermissions } = usePermissions();

  if (role && hasAssumedRole(role)) {
    return <>{children}</>;
  }

  if (loadingPermissions) {
    return null;
  }

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  if (hasPermissions(permission!)) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
}

UserHas.defaultProps = {
  children: null,
  fallback: null,
};

UserHas.defaultProps = {
  permission: null,
  role: null,
};
