// @ts-nocheck
import InterestingFacts from './InterestingFacts';

function DashboardDefaultUser() {
  return (
    <>
      <InterestingFacts />
    </>
  );
}

export default DashboardDefaultUser;
