import styled from 'styled-components/macro';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

export const DialogImage = styled.div`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  width: 100%;
  height: 110px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(1.25, 0)};
  svg {
    max-width: 100px;
    max-height: 90px;
    width: 100%;
    height: 100%;
    fill: none;
  }
`;

export const DialogText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 400px;
  overflow-y: visible;
  padding: ${(props) => props.theme.spacing(0.5, 6)};
  font-size: 1.2rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 200px;
    padding: ${(props) => props.theme.spacing(0.5, 3)};
  }
`;

export const DialogActionsStyled = styled(DialogActions)<{ fulllength: number; justifyright: number }>`
  padding: ${(props) => props.theme.spacing(3, 6, 6)};
  flex-direction: ${(props) => (props.fulllength ? 'column-reverse' : 'row')};
  justify-content: ${(props) => (props.justifyright ? 'flex-end' : 'center')};

  button:not(:first-child) {
    width: ${(props) => (props.fulllength ? '100%' : 'auto')};
  }

  ${(props) => {
    if (props.fulllength) {
      const margin = props.theme.spacing(0, 0, 2.5);
      return `button:not(:first-child) {margin: ${margin};}`;
    }
    return null;
  }}

  ${(props) => props.theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
    padding: ${(props) => props.theme.spacing(2, 3, 3)};

    button:not(:first-child) {
      width: 100%;
      margin: ${(props) => props.theme.spacing(0, 0, 2.5)};
    }
  }
`;

export const DialogTitleStyled = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(6, 6, 3)};
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(6, 3, 3)};
  }
`;

export const DialogContainer = styled.div<{ large: 0 | 1; allowOverflow?: 0 | 1 }>`
  ${(props) => props.large && `width: 1010px`};
  display: flex;
  flex-direction: column;
  overflow-x: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    width: 1rem;
    height: 1rem;
  }
`;
