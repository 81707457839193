import { AccessCategory, Product } from '../../../../utils/models';

export function showStaticDocuments(products: Product[] | undefined) {
  return (
    products &&
    products.find((product) =>
      product?.accessCategories?.find((accessCategory) => accessCategory === AccessCategory.HOME)
    )
  );
}
