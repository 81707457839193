import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import parse from 'html-react-parser';
import styled from 'styled-components/macro';

const defaultProps = {
  message: '',
  open: false,
  title: '',
};
type ErrorDialogType = {
  message?: string;
  open?: boolean;
  title?: string;
  backendDate: Date | undefined;
  onClose(...args: unknown[]): unknown;
} & typeof defaultProps;

const DateTime = styled.div`
  color: ${(props) => props.theme.palette.tertiary.darker};
`;
/**
 * Dialog to be used for error display.
 */
function ErrorDialog({ message, open, title, onClose, backendDate }: ErrorDialogType) {
  const { t } = useTranslation();
  const errorTime = backendDate ? `${backendDate.toISOString()}` : `${new Date().toISOString()} FE`;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title">
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }} paragraph>
          {parse(message)}
        </DialogContentText>
        <DateTime>{errorTime}</DateTime>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ErrorDialog.defaultProps = defaultProps;

export default ErrorDialog;
