// @ts-nocheck
import styled from 'styled-components/macro';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import GroaAccordion from '../../layout/GroaAccordion';

const SectionWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(11, 7)};

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(3, 3, 11)};
  }
`;

const Title = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: ${(props) => props.theme.spacing(7)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 2.7rem;
    line-height: 3.33rem;
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const AccordionDescription = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightLighter};
  line-height: 2.4rem;
  font-size: 1.35rem;
  margin: 0;

  &:not(:last-child) {
    padding-bottom: ${(props) => props.theme.spacing(3)}px;
  }
`;

function OurProductsSection() {
  const { t } = useTranslation('landingpage');
  const sections = ['our products', 'about Mer', 'which products', 'what options'];

  return (
    <SectionWrapper id="our-products">
      <Title color="primary">{t('services')}</Title>
      {sections.map((section) => (
        <GroaAccordion title={t(section)} key={section}>
          <AccordionDescription variant="subtitle1" color="primary">
            <Trans ns="landingpage" t={t} i18nKey={`${section} description`} />
          </AccordionDescription>
        </GroaAccordion>
      ))}
    </SectionWrapper>
  );
}

export default OurProductsSection;
