import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { BasicVehicleData } from './models';

export interface VehicleModelContextInterface {
  vehicleModels: Array<BasicVehicleData>;
  setVehicleModels: (models: Array<BasicVehicleData>) => void;
}

export const VehicleModelContext = createContext<VehicleModelContextInterface>({
  vehicleModels: [],
  setVehicleModels: () => {},
});

interface BasicVehicleDataExtended extends BasicVehicleData {
  vehicleModel: string;
}

export const useVehicleModel = () => useContext(VehicleModelContext);

export function VehicleModelProvider({ children }: { children: ReactNode }) {
  const [vehicleModels, setVehicleModels] = useState<Array<BasicVehicleData>>([]);
  const [sortedVehicleModels, setSortedVehicleModels] = useState<Array<BasicVehicleDataExtended>>([]);

  useEffect(() => {
    function compareVehicleModels(vehicle1: BasicVehicleDataExtended, vehicle2: BasicVehicleDataExtended) {
      return vehicle1?.vehicleModel.localeCompare(vehicle2?.vehicleModel);
    }

    const sortedVehicles = [...vehicleModels]
      .filter((vehicleModel): vehicleModel is BasicVehicleDataExtended => !!vehicleModel)
      .sort(compareVehicleModels);
    setSortedVehicleModels(sortedVehicles);
  }, [vehicleModels, setSortedVehicleModels]);

  return (
    <VehicleModelContext.Provider
      value={{
        vehicleModels: sortedVehicleModels,
        setVehicleModels,
      }}
    >
      {children}
    </VehicleModelContext.Provider>
  );
}
