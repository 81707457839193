/* eslint-disable react/jsx-props-no-spreading */
import { Suspense } from 'react';
import { Route } from 'react-router-dom';

import { useAuth0 } from '../utils/Auth0Provider';
import PageLayout from '../layout/PageLayout';
import PageSkeleton from '../layout/PageSkeleton';

import Homepage from './Homepage/Homepage';

// eslint-disable-next-line react/require-default-props
function ProtectedRoute({ children, ...rest }: { children: JSX.Element; path: string; exact?: boolean }) {
  const { isAuthenticated } = useAuth0();

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <Suspense fallback={<PageSkeleton />}>
          <PageLayout>{children}</PageLayout>
        </Suspense>
      ) : (
        <Homepage />
      )}
    </Route>
  );
}

export default ProtectedRoute;
