import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Title = styled(Typography)`
  font-size: 3.5rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: 3.5rem;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const Content = styled(Typography)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: ${(props) => props.theme.spacing(6)}px;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.palette.primary.main};

  svg {
    width: 10px;
    margin-left: ${(props) => props.theme.spacing(0.75)}px;
  }
`;

function CarbonCard() {
  const { t } = useTranslation('dashboard');

  return (
    <>
      <Title>{t('carbon title')}</Title>
      <Content>{t('carbon content')}</Content>
      <StyledLink href="https://de.mer.eco/news/so-funktioniert-eine-wallbox/" target="_blank">
        {t('carbon link')} <ArrowForwardIcon />
      </StyledLink>
    </>
  );
}

export default CarbonCard;
