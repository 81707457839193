import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form';

import constants from '../../config/constants';
import { AccessCategory, Product, CarOwnership, VehicleData, ProductType } from '../../utils/models';

import { EditVehicleFormData, SignupFormData, VehicleType } from './types';

type SetValueType = UseFormSetValue<SignupFormData | EditVehicleFormData>;
type ClearErrorsType = UseFormClearErrors<SignupFormData> | UseFormClearErrors<EditVehicleFormData>;

export function resetVehicleData(
  vehicleType: VehicleType | string | undefined | null,
  clearErrors: ClearErrorsType,
  vehicleModel: string | undefined,
  setValue: SetValueType
) {
  return () => {
    if (vehicleModel !== constants.vehicleModel.OTHER) {
      clearErrors('vehicleModelOther');
      clearErrors('vehicleType');
      clearErrors('batteryCapacity');

      setValue('vehicleModelOther', undefined);
      setValue('vehicleType', undefined);
      setValue('batteryCapacity', undefined);
    }
  };
}

export function onChangeVehicleModel(setValue: SetValueType) {
  return (vehicleModel: string) => {
    setValue('vehicleModel', vehicleModel, { shouldValidate: true });
    setValue('batteryCapacity', undefined, { shouldValidate: false });
    setValue('vehicleType', undefined, { shouldValidate: false });
    setValue('vehicleModelOther', undefined, { shouldValidate: false });
    setValue('vehicleModelReferenceNumber', undefined, { shouldValidate: false });
  };
}

export function onChangeVehicleType(setValue: SetValueType) {
  const changeHandler: SelectInputProps['onChange'] = (event) => {
    setValue('vehicleType', event.target.value as VehicleType, { shouldValidate: true });
  };

  return changeHandler;
}

export function isNotEeDrive(product?: Product) {
  return product?.productType !== ProductType.EEDRIVE;
}

export function isCarOwnershipCompany(product?: Product) {
  return product?.carOwnership === CarOwnership.COMPANY;
}

export function isMetered(product?: Product) {
  return product?.id === '12' || product?.id === '13' || product?.id === '16';
}

export function needsFinalHandoverDate(product?: Product) {
  return product?.accessCategories?.includes(AccessCategory.HOME) && isCarOwnershipCompany(product);
}

export function isHandoverDisabled(storedVehicleData: VehicleData | undefined) {
  // The handover date inputs are disabled if the isHandoverFinal flag is stored as `true`.
  // Note: This also happens if the isHandoverDateFinal is set for products that do NOT display the radio buttons,
  // e.g. when it is set by an operator in WeClapp (which is always possible).
  // To avoid disabling the handover date in this case, add
  // `needsFinalHandoverDate(product) &&`
  // to this check here -- but the BE then needs to allow the change of the handover date despite it being final!
  return !!storedVehicleData?.vehicleHandoverDate && !!storedVehicleData?.isHandoverDateFinal;
}
