import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Link, Typography, IconButton } from '@material-ui/core';
import useSWR from 'swr';

import Card from '../../../../layout/Card';
import { getBuildingConsentTemplate } from '../../../../utils/api';
import DownloadIcon from '../../../Icons/DownloadIcon';
import { preventAutoRevalidationConfig } from '../../../../utils/preventAutoRevalidationConfig';

const DocumentDownloadInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1, 2, 1, 0)};
`;

const DocumentDownloadInfoText = styled.div`
  text-align: left;
`;

const DocumentDownloadCardTitle = styled(Typography)`
  letter-spacing: 0.05em;
  font-size: 1.7rem;
  font-weight: ${(props) => props.theme.typography.fontWeightNormal};
  line-height: 2.28rem;
  color: ${(props) => props.theme.palette.primary.main};
`;

const DocumentDownloadCardSubTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: 1.7rem;
  color: ${(props) => props.theme.palette.primary.main};
  margin-top: 16px;
`;

const StyledText = styled(Typography)`
  margin-left: 24px;
  margin-top: 40px;
`;

const StyledListItem = styled(Typography)`
  min-width: 24px;
  min-height: 24px;
  border: 1.8px solid;
  border-radius: 50%;
  font-size: 10px;
  line-height: 20px;
  margin-left: 24px;
  margin-right: 8px;
`;

const StyledButtonWrapper = styled.div`
  margin-left: 8px;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const DownloadInfoBox = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin: ${(props) => props.theme.spacing(2, 0, 2.5)};
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.tertiary.bright};

  transition: ${(props) =>
    props.theme.transitions.create(['color', 'background-color', 'border-color'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};

  > div:first-child {
    padding-top: ${(props) => props.theme.spacing(3)}px;
  }

  > *:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }

  input {
    display: none;
  }
`;

function BuildingConsentDownloadCard() {
  const { t } = useTranslation('processwidget');
  const { data: buildingConsentDocumentUrl } = useSWR(
    'getBuildingConsentTemplate',
    getBuildingConsentTemplate,
    preventAutoRevalidationConfig
  );

  const renderContent = () => {
    return (
      <>
        <DocumentDownloadCardTitle align="left">{t('upload permission')}</DocumentDownloadCardTitle>
        <DocumentDownloadCardSubTitle align="left">{t('follow the steps')}</DocumentDownloadCardSubTitle>
        <DownloadInfoBox>
          <DocumentDownloadInfo>
            <StyledListItem>1</StyledListItem>
            <DocumentDownloadInfoText>
              <Typography variant="body1">
                {t('download text 1')}
                <Link underline="always" href={buildingConsentDocumentUrl} target="_blank" download>
                  {t('download text 2')}
                </Link>
                {t('download text 3')}
              </Typography>
            </DocumentDownloadInfoText>
            <StyledButtonWrapper>
              <IconButton
                component="a"
                aria-label="download"
                size="small"
                href={buildingConsentDocumentUrl}
                target="_blank"
                download
              >
                <DownloadIcon />
              </IconButton>
            </StyledButtonWrapper>
          </DocumentDownloadInfo>
          <DocumentDownloadInfo>
            <StyledListItem>2</StyledListItem>
            <DocumentDownloadInfoText>
              <Typography variant="body1">{t('upload signed')}</Typography>
            </DocumentDownloadInfoText>
          </DocumentDownloadInfo>
          <StyledText>{t('will check')}</StyledText>
        </DownloadInfoBox>
      </>
    );
  };

  return (
    <Card noBorder noPadding>
      {renderContent()}
    </Card>
  );
}

export default BuildingConsentDownloadCard;
