// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledAccordionDetails = styled(AccordionDetails)`
  color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2, 3, 3)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(2, 4, 4)};
  }
`;

function GroaAccordion({ children, title, expanded, onChange }) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
        <Typography color="primary" variant="h4">
          {title}
        </Typography>
      </AccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </Accordion>
  );
}

GroaAccordion.defaultProps = {
  expanded: undefined,
  onChange: undefined,
};

GroaAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
};

export default GroaAccordion;
