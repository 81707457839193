import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '../../utils/PermissionsProvider';
import { useContractProvider } from '../../utils/ContractProvider';
import { useAuth0 } from '../../utils/Auth0Provider';
import HelpIcon from '../Icons/HelpIcon';

import UserAvatar from './UserAvatar';
import UserProfileMenu from './UserProfileMenu/UserProfileMenu';
import ContractSelectorButton from './UserProfileMenu/ContractSelector/ContractSelectorButton';
import DesktopContractSelectorMenu from './UserProfileMenu/ContractSelector/DesktopContractSelectorMenu';

const Banner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2, 4)};
  min-height: 70px;
  background-color: transparent;
  z-index: 1;
`;

const IconLink = styled(Link)`
  margin-right: 2.5rem;
  height: 2rem;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledLink = styled(Link)<{ isfaq: 0 | 1 }>`
  &:hover,
  &:focus {
    color: ${(props) => (props.isfaq ? props.theme.palette.primary.bright : undefined)};
  }
  &:link,
  &:active {
    color: ${(props) => (props.isfaq ? props.theme.palette.primary.bright : props.theme.palette.primary.main)};
  }
  &:visited {
    color: ${(props) => (props.isfaq ? props.theme.palette.primary.lighter : undefined)};
  }

  text-decoration: underline;
  margin-right: 20px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

function UserHeader() {
  const { user } = useAuth0();
  const { getAssumedRole } = usePermissions();
  const activeRole = getAssumedRole();
  const { availableContracts, selectedContract, eligibleForFollowUpContract } = useContractProvider();
  const { t } = useTranslation('translation');

  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isProfileMenuOpened, setIsProfileMenuOpened] = useState(false);
  const [isContractSelectorOpened, setIsContractSelectorOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const isfaq = window.location.pathname.startsWith('/faq');

  const handleOpenMenu = (event: React.MouseEvent, menuType: string) => {
    setAnchorEl(event.currentTarget);
    if (menuType === 'profile') {
      setIsProfileMenuOpened(true);
    } else {
      setIsContractSelectorOpened(true);
    }
  };

  const handleCloseMenu = () => {
    setIsProfileMenuOpened(false);
    setIsContractSelectorOpened(false);
    setAnchorEl(null);
  };

  const renderBanner = () => {
    if (isMobile) {
      return null;
    }

    return (
      <>
        <StyledLink to="/profile" isfaq={isfaq ? 1 : 0}>
          {t('language')}
        </StyledLink>
        {activeRole === 'driver' && (
          <>
            <ContractSelectorButton
              contract={selectedContract}
              handleClick={(event: React.MouseEvent) => handleOpenMenu(event, 'contract')}
            />
            <DesktopContractSelectorMenu
              contractList={availableContracts}
              selectedContract={selectedContract}
              isOpen={isContractSelectorOpened}
              anchorEl={anchorEl}
              handleClose={handleCloseMenu}
              eligibleForFollowUpContract={!!eligibleForFollowUpContract}
            />
          </>
        )}

        {!isfaq && (
          <IconLink to="/faq" data-testid="header-banner-faq" className="faq-link">
            <HelpIcon />
          </IconLink>
        )}
        {user && (
          <>
            <UserAvatar
              user={user}
              isOpen={isProfileMenuOpened}
              handleOpen={(event: React.MouseEvent) => handleOpenMenu(event, 'profile')}
            />
            <UserProfileMenu
              user={user}
              isOpen={isProfileMenuOpened}
              anchorEl={anchorEl}
              handleClose={handleCloseMenu}
            />
          </>
        )}
      </>
    );
  };

  return <Banner>{renderBanner()}</Banner>;
}

export default UserHeader;
