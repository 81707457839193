// @ts-nocheck
import { Grid } from '@material-ui/core';

import UserHas from '../../utils/UserHas';
import DriverInvite from '../DriverInvite/DriverInvite';

import ContractsCard from './Contracts/ContractsCard';
import InterestingFacts from './InterestingFacts';
import SelfSignup from './SelfSignup';

function DashboardFleetManager() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <UserHas permission="contracts:read">
            <ContractsCard />
          </UserHas>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <DriverInvite />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <SelfSignup />
        </Grid>
      </Grid>
      <InterestingFacts />
    </>
  );
}

export default DashboardFleetManager;
