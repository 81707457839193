import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import useSWR, { mutate } from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';

import useDriverInviteApi from '../../hooks/useDriverInviteApi';
import ActionDialog from '../ActionDialog';
import { AroundGlobe, InviteDriverSuccess } from '../Illustrations';
import InviteesListIcon from '../Icons/InviteesListIcon';
import AddUserIcon from '../Icons/AddUserIcon';
import { getNumberOfInvitations, getOrganizations } from '../../utils/api';
import { usePermissions } from '../../utils/PermissionsProvider';
import constants from '../../config/constants';

import DriverInviteForm from './DriverInviteForm';
import validationSchema from './validation';
import {
  CardContentStyled,
  CardHeaderStyled,
  CardStyled,
  IconWrapper,
  IllustrationWrapper,
  InviteButton,
  InviteSuccessContentWrapper,
  NoOfInviteesButton,
  NoOfInviteesWrapper,
} from './DriverInvite.parts';

interface DriverInviteProps {
  simplifiedView?: boolean;
  handleTriggerRefetchData?(input: boolean): unknown;
}

interface OnSubmitParams {
  organization: string;
  firstName: string;
  lastName: string;
  email: string;
}

function DriverInvite({ simplifiedView, handleTriggerRefetchData }: DriverInviteProps) {
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [initialValues, setInitialValues] = useState<OnSubmitParams>({
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
  });
  const { createInvitee, isLoading } = useDriverInviteApi();
  const { t } = useTranslation(['invitees', 'errors']);
  const history = useHistory();
  const { hasAssumedRole } = usePermissions();
  const { data: noOfInvitees } = useSWR(simplifiedView ? null : 'getNumberOfInvitations', getNumberOfInvitations);
  const { data: organizations } = useSWR('getOrganizations', () => getOrganizations());

  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    unregister,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (organizations?.data?.content?.length === 1) {
      const org = organizations.data.content[0];
      if (!org.id) {
        return;
      }
      setInitialValues({
        firstName: '',
        lastName: '',
        email: '',
        organization: org.id,
      });
      reset({
        firstName: '',
        lastName: '',
        email: '',
        organization: org.id,
      });
    }
  }, [organizations, reset]);

  useEffect(() => {
    register('organization');
    return () => {
      unregister('organization');
    };
  }, [register, unregister]);

  const selectOrganization = (input: { id: string }) => {
    if (input) {
      setValue('organization', input.id);
      clearErrors('organization');
    }
  };

  const openInviteDialog = () => {
    setInviteDialogOpen(true);
  };

  const openExternalOrderPage = () => {
    window.open('https://de.mer.eco/standortladekarte/', '_blank');
  };

  const closeInviteDialog = () => {
    setInviteDialogOpen(false);
    clearErrors();
    reset(initialValues);
  };

  const openSuccessDialog = () => {
    setSuccessDialogOpen(true);
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);

    if (simplifiedView) {
      if (handleTriggerRefetchData) {
        handleTriggerRefetchData(true);
      }
    } else {
      mutate('getNumberOfInvitations', getNumberOfInvitations);
    }
  };

  const onSubmit = async (data: OnSubmitParams) => {
    const response = await createInvitee(data.organization, data);
    if (response) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      closeInviteDialog();
      openSuccessDialog();
    }
    reset(initialValues);
  };

  const inviteMore = () => {
    closeSuccessDialog();
    openInviteDialog();
  };

  const handleShowMore = () => {
    history.push('/invitees');
  };

  const renderContent = () => {
    if (simplifiedView) {
      return (
        <InviteButton
          variant="contained"
          color="primary"
          onClick={openInviteDialog}
          simplifiedview={simplifiedView ? 1 : 0}
        >
          <IconWrapper>
            <AddUserIcon />
          </IconWrapper>
          {t('invite driver')}
        </InviteButton>
      );
    }
    return (
      <CardStyled>
        <IllustrationWrapper>
          <AroundGlobe />
        </IllustrationWrapper>
        <CardHeaderStyled title={t('new driver')} />
        <CardContentStyled>
          <div>{t('driver invitation card description')}</div>
          <InviteButton variant="contained" color="primary" onClick={openInviteDialog}>
            <IconWrapper>
              <AddUserIcon />
            </IconWrapper>
            {t('invite driver')}
          </InviteButton>
          {hasAssumedRole(constants.assumedRoles.MANAGER) && (
            <InviteButton variant="contained" color="primary" onClick={openExternalOrderPage}>
              {t('order workplace charging card')}
            </InviteButton>
          )}
          {noOfInvitees !== 0 && noOfInvitees && (
            <NoOfInviteesWrapper>
              <InviteesListIcon />
              <Typography variant="caption" color="primary">
                +{noOfInvitees} {noOfInvitees > 1 ? t('invitations') : t('invitation')}
              </Typography>
              <NoOfInviteesButton variant="outlined" onClick={handleShowMore}>
                <Typography variant="caption" color="primary">
                  {t('show')}
                </Typography>
              </NoOfInviteesButton>
            </NoOfInviteesWrapper>
          )}
        </CardContentStyled>
      </CardStyled>
    );
  };

  return (
    <>
      {renderContent()}
      <ActionDialog
        open={inviteDialogOpen}
        handleClose={closeInviteDialog}
        image={<AroundGlobe />}
        title={t('invite new driver title')}
        confirmationButtonText={t('invite new driver button text')}
        content={
          <DriverInviteForm
            control={control}
            errors={errors}
            selectOrganization={selectOrganization}
            organizations={organizations?.data?.content}
          />
        }
        handleConfirmation={handleSubmit(onSubmit)}
        showCloseIcon
        isLoading={isLoading}
      />
      <ActionDialog
        open={successDialogOpen}
        handleClose={closeSuccessDialog}
        title={t('success invite title')}
        image={<InviteDriverSuccess />}
        confirmationButtonText={t('success button text')}
        content={
          <InviteSuccessContentWrapper>
            <Trans
              ns="invitees"
              t={t}
              i18nKey="success invite text"
              values={{ fullName: `${firstName} ${lastName}` }}
            />
          </InviteSuccessContentWrapper>
        }
        handleConfirmation={inviteMore}
        hideCancel
        showCloseIcon
        disableFullscreen
      />
    </>
  );
}

DriverInvite.defaultProps = {
  simplifiedView: false,
  handleTriggerRefetchData: () => {},
};

export default DriverInvite;
