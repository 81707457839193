import { Grid } from '@material-ui/core';

import Card from '../../../layout/Card';

interface CdrCo2CardContainerProps {
  barChart: JSX.Element;
  co2Card: JSX.Element;
}

const CdrCo2CardContainer = ({ barChart, co2Card }: CdrCo2CardContainerProps) => {
  return (
    <Card>
      <Grid container spacing={4} style={{ display: 'flex' }}>
        <Grid item xs={12} md={8}>
          {barChart}
        </Grid>
        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          {co2Card}
        </Grid>
      </Grid>
    </Card>
  );
};

export default CdrCo2CardContainer;
