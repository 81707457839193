// @ts-nocheck
/* eslint-disable no-else-return */
import i18next from 'i18next';

import CookiePolicyDE from './CookiePolicyDE';
import CookiePolicyEN from './CookiePolicyEN';

function CookiePolicy() {
  if (i18next.language === 'en') {
    return <CookiePolicyEN />;
  } else {
    return <CookiePolicyDE />;
  }
}

export default CookiePolicy;
