import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import LogoutRoute from './components/LogoutPage/LogoutRoute';
import Homepage from './components/Homepage';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth0 } from './utils/Auth0Provider';
import CookiePolicy from './components/CookiePolicy';
import PageSkeleton from './layout/PageSkeleton';
import PageLoadingSpinner from './layout/PageLoadingSpinner';
import { ROUTES as STATISTICS_ROUTES } from './components/Statistics/routes';
import { CONTRACTS, FOLLOW_UP_CONTRACTS } from './config/routes';

// @ts-ignore
const Invitees = lazy(() => import(/* webpackChunkName: "Invitees" */ './components/InviteesList'));
const Contracts = lazy(() => import(/* webpackChunkName: "Contracts" */ './components/Contracts'));
const Statistics = lazy(() => import(/* webpackChunkName: "Statistics" */ './components/Statistics'));
const ContractDetail = lazy(
  () => import(/* webpackChunkName: "ContractDetail" */ './components/Contracts/ContractDetail')
);
const Stations = lazy(() => import(/* webpackChunkName: "Stations" */ './components/Stations'));
const LegalNotice = lazy(() => import(/* webpackChunkName: "LegalNotice" */ './components/LegalNotice'));
const LogoutPage = lazy(() => import(/* webpackChunkName: "LogoutPage" */ './components/LogoutPage'));
const UserProfile = lazy(() => import(/* webpackChunkName: "UserProfile" */ './components/UserProfile'));
const FaqPage = lazy(() => import(/* webpackChunkName: "FAQ" */ './components/FAQ'));
const FaqCategory = lazy(() => import(/* webpackChunkName: "FAQCategory" */ './components/FAQ/FaqCategory'));
const FaqTopic = lazy(() => import(/* webpackChunkName: "FAQTopic" */ './components/FAQ/FaqTopic'));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "PrivacyPolicy" */ './components/PrivacyPolicy'));
const SelfSignup = lazy(() => import(/* webpackChunkName: "SelfSignup" */ './components/SelfSignup'));
const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ './components/ErrorPage'));

function App() {
  const { loading } = useAuth0();
  if (loading) {
    return <PageSkeleton />;
  }

  return (
    <Switch>
      <Route exact path="/impressum">
        <Suspense fallback={<PageLoadingSpinner />}>
          <LegalNotice />
        </Suspense>
      </Route>
      <Route exact path="/cookies">
        <Suspense fallback={<PageLoadingSpinner />}>
          <CookiePolicy />
        </Suspense>
      </Route>
      <Route exact path="/privacy">
        <Suspense fallback={<PageLoadingSpinner />}>
          <PrivacyPolicy />
        </Suspense>
      </Route>
      <Route exact path="/signup">
        <Suspense fallback={<PageLoadingSpinner />}>
          <SelfSignup />
        </Suspense>
      </Route>
      <ProtectedRoute path={`${CONTRACTS}/:id`}>
        <ContractDetail />
      </ProtectedRoute>
      <ProtectedRoute exact path={CONTRACTS}>
        <Contracts />
      </ProtectedRoute>
      <ProtectedRoute exact path={FOLLOW_UP_CONTRACTS}>
        <Contracts />
      </ProtectedRoute>
      <ProtectedRoute path={STATISTICS_ROUTES.overview}>
        <Statistics />
      </ProtectedRoute>
      <ProtectedRoute exact path="/stations">
        <Stations />
      </ProtectedRoute>
      <ProtectedRoute exact path="/faq">
        <FaqPage />
      </ProtectedRoute>
      <ProtectedRoute path="/faq/:category/:topic">
        <FaqTopic />
      </ProtectedRoute>
      <ProtectedRoute path="/faq/:category">
        <FaqCategory />
      </ProtectedRoute>
      <ProtectedRoute exact path="/profile">
        <UserProfile />
      </ProtectedRoute>
      <ProtectedRoute exact path="/invitees">
        <Suspense fallback={<PageLoadingSpinner />}>
          <Invitees />
        </Suspense>
      </ProtectedRoute>
      <LogoutRoute exact path="/logout">
        <Suspense fallback={<PageLoadingSpinner />}>
          <LogoutPage />
        </Suspense>
      </LogoutRoute>
      <Route exact path="/error">
        <Suspense fallback={<PageLoadingSpinner />}>
          <ErrorPage />
        </Suspense>
      </Route>
      <Route exact path="/">
        <Homepage />
      </Route>
      <Route path="*">
        <Homepage />
      </Route>
    </Switch>
  );
}

export default App;
