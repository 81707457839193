import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ContractMenuItem from './ContractMenuItem';

const Wrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    overflow-x: auto;
    position: absolute;
    word-break: break-word;
    top: 80px;
    bottom: 96px;
    width: 100%;
  }
`;

const NoContractText = styled(Typography)`
  padding: ${(props) => props.theme.spacing(0, 3, 3, 3)};
`;

const ActiveContractSection = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
`;

interface ContractSelectorListProps {
  contractList: {
    contractNumber?: string;
    contractStatus?: string;
    startDate?: string;
  }[];
  selectedContract?: {
    contractNumber?: string;
    contractStatus?: string;
    startDate?: string;
  };
  handleClose(...args: unknown[]): unknown;
}

function ContractSelectorList({ contractList, selectedContract, handleClose }: ContractSelectorListProps) {
  const hasContract = contractList.length !== 0;
  const activeContract = contractList.filter((contract) => contract.contractStatus === 'ACTIVE')[0];

  const { t } = useTranslation('contracts');

  const renderContent = () => {
    if (!hasContract) {
      return (
        <NoContractText variant="body2" color="primary">
          {t('no contract exists yet')}
        </NoContractText>
      );
    }
    return (
      <>
        {activeContract && (
          <ActiveContractSection key={activeContract.contractNumber}>
            <ContractMenuItem
              contract={activeContract}
              isSelectedContract={activeContract.contractNumber === selectedContract?.contractNumber}
              handleClose={handleClose}
            />
          </ActiveContractSection>
        )}

        {contractList.map(
          (contract) =>
            contract.contractStatus !== 'ACTIVE' && (
              <ContractMenuItem
                key={contract.contractNumber}
                contract={contract}
                isSelectedContract={contract.contractNumber === selectedContract?.contractNumber}
                handleClose={handleClose}
              />
            )
        )}
      </>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
}

ContractSelectorList.defaultProps = {
  selectedContract: null,
};

export default ContractSelectorList;
