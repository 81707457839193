import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';

import { getMyOrganizationContracts } from '../../../utils/api';
import Card from '../../../layout/Card';
import { CONTRACTS } from '../../../config/routes';

import UserList from './UserList';

const Wrapper = styled.div<{ direction?: string }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  svg {
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }
`;

const LicencePlate = styled(Typography)`
  text-align: center;
`;

const ContractList = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    border-top: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  }
`;

const ContractListItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  padding: ${(props) => props.theme.spacing(2, 3)};
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};

  > * {
    flex-basis: ${(props) => (props.isMobile ? 'auto' : '33%')};
  }
`;

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

function ContractsCard() {
  const history = useHistory();
  const { t } = useTranslation(['dashboard', 'errors']);

  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const goToContractDetail = (contractId: string | undefined) => {
    if (contractId !== undefined) history.push(`${CONTRACTS}/${contractId}`);
  };

  const { data: contracts, error: isError } = useSWR('getMyOrganizationContracts', () =>
    getMyOrganizationContracts({
      pageSize: 4,
      pageNumber: 0,
      sort: 'id,desc',
    })
  );

  const renderContent = () => {
    if (!contracts && !isError) {
      return (
        <Wrapper direction="column">
          <StyledSkeleton variant="rect" width="100%" height={30} />
          <StyledSkeleton variant="rect" width="100%" height={30} />
          <StyledSkeleton variant="rect" width="100%" height={30} />
        </Wrapper>
      );
    }

    if (isError) {
      return (
        <Wrapper>
          <ErrorOutlineIcon />
          <Typography variant="h4" color="primary">
            {t('errors:error processing response')}
          </Typography>
        </Wrapper>
      );
    }

    if (contracts?.numberOfElements === 0) {
      return (
        <Wrapper>
          <InsertDriveFileOutlinedIcon />
          <Typography variant="h4" color="primary">
            {t('no contracts found')}
          </Typography>
        </Wrapper>
      );
    }

    return (
      <ContractList>
        {contracts &&
          contracts.content?.map((contract) => {
            const { users, vehicleData } = contract;
            return (
              <ContractListItem key={contract.id} isMobile={isMobile} onClick={() => goToContractDetail(contract.id)}>
                <Typography>{contract.contractNumber}</Typography>
                {!isMobile && <LicencePlate>{vehicleData?.licencePlate}</LicencePlate>}
                <UserList users={users} />
              </ContractListItem>
            );
          })}
      </ContractList>
    );
  };

  return (
    <Card hasHeader title={t('newest contracts')}>
      {renderContent()}
    </Card>
  );
}

export default ContractsCard;
