import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Popover, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '../../../utils/Auth0Provider';
import UserAvatar from '../UserAvatar';

import RoleSwitch from './RoleSwitch';

const StyledPopover = styled(Popover)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const ContentWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(5, 0, 4, 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  > div {
    width: 5rem;
    height: 5rem;
  }
`;

const UserEmail = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.dark};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(2, 0, 3.5, 0)};
`;

const UserDetailArea = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  border-top: 1px solid ${(props) => props.theme.palette.tertiary.lightest};
  padding: ${(props) => props.theme.spacing(4, 3)};
  align-items: center;

  :hover {
    background-color: ${(props) => props.theme.palette.secondary.lightest};
  }

  > div:nth-child(2) {
    flex-direction: column;
    padding-left: ${(props) => props.theme.spacing(2)}px;
  }
`;

const LogoutLink = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(5)}px;
  color: ${(props) => props.theme.palette.common.red};
  cursor: pointer;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const UserName = styled(Typography)`
  text-transform: uppercase;
`;

interface UserProfileMenuProps {
  user: {
    name?: string;
    email?: string;
    picture?: string;
  };
  isOpen: boolean;
  anchorEl?: Element | null;
  handleClose(): unknown;
}

function UserProfileMenu({ user, isOpen, anchorEl, handleClose }: UserProfileMenuProps) {
  const { t } = useTranslation(['profile', 'translation']);
  const history = useHistory();
  const { logout } = useAuth0();

  const handleEditProfile = () => {
    history.push('/profile');
    handleClose();
  };

  const handleRoleSwitch = () => {
    handleClose();
    history.push('/');
  };

  return (
    <StyledPopover
      id="profile-menu"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <ContentWrapper>
        <AvatarWrapper>
          <UserAvatar user={user} />
        </AvatarWrapper>
        <UserName color="primary" variant="h4">
          {user.name}
        </UserName>
        <UserEmail variant="h5">{user.email}</UserEmail>
        <StyledButton variant="contained" color="primary" onClick={handleEditProfile}>
          {t('edit profile')}
        </StyledButton>
        <UserDetailArea>
          <UserAvatar user={user} />
          <div>
            <RoleSwitch onSwitchRole={handleRoleSwitch} />
            <Typography color="primary" variant="h4">
              {user.name}
            </Typography>
            <UserEmail variant="h5">{user.email}</UserEmail>
          </div>
          <LogoutLink variant="h5" onClick={logout}>
            {t('translation:logout')}
          </LogoutLink>
        </UserDetailArea>
      </ContentWrapper>
    </StyledPopover>
  );
}

UserProfileMenu.defaultProps = {
  anchorEl: null,
};

export default UserProfileMenu;
