import styled from 'styled-components/macro';
import { Button, Theme, Typography, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import React from 'react';

import constants from '../../../../config/constants';
import InativeContractIcon from '../../../Icons/InactiveContractIcon';
import ActiveContractIcon from '../../../Icons/ActiveContractIcon';
import DraftContractIcon from '../../../Icons/DraftContractIcon';

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  border: ${(props) => `1px solid ${props.theme.palette.primary.lightest}`};
  padding: ${(props) => props.theme.spacing(1, 2.5)};
  margin-right: ${(props) => props.theme.spacing(3)}px;
  background-color: ${(props) => props.theme.palette.common.white};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(0, 4)};
  }
`;

const DefaultText = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.light};
`;

const ContractText = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};

  > svg {
    width: 16px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ExpandBtnWrapper = styled.div<{ hasContract: boolean }>`
  display: flex;
  margin-left: ${(props) => props.theme.spacing(2.5)}px;
  > svg {
    color: ${(props) => (props.hasContract ? props.theme.palette.primary.main : props.theme.palette.primary.light)};
    width: 18px;
  }
`;

interface ContractSelectorButtonProps {
  contract?: {
    contractNumber?: string;
    contractStatus?: string;
  };
  handleClick?(e?: unknown, s?: string): unknown;
}

function ContractSelectorButton({ contract, handleClick }: ContractSelectorButtonProps) {
  const { t } = useTranslation('contracts');
  const hasContract = !!(contract && Object.keys(contract).length !== 0);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getContractStatusImg = (status: string | undefined) => {
    switch (status) {
      case constants.contractStatus.INACTIVE:
        return <InativeContractIcon />;
      case constants.contractStatus.ACTIVE:
        return <ActiveContractIcon />;
      default:
        return <DraftContractIcon />;
    }
  };

  function getOnClick() {
    return (e: React.MouseEvent) => (isMobile ? handleClick?.() : handleClick?.(e, 'contract'));
  }

  return (
    <StyledButton onClick={getOnClick()} className="contract-selector">
      {hasContract ? (
        <>
          <ContractText variant="body2">
            {getContractStatusImg(contract?.contractStatus)}
            {contract?.contractNumber}
          </ContractText>
        </>
      ) : (
        <DefaultText variant="body2">{t('my contracts')}</DefaultText>
      )}
      <ExpandBtnWrapper hasContract={hasContract}>
        <ExpandMoreIcon />
      </ExpandBtnWrapper>
    </StyledButton>
  );
}

ContractSelectorButton.defaultProps = {
  contract: {},
  handleClick: () => {},
};

export default ContractSelectorButton;
