// @ts-nocheck
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import ArticleMotif from '../../assets/images/articleMotif.png';
import ChargingMotif from '../../assets/images/chargingMotif.png';
import ProductsMotif from '../../assets/images/productsMotif.png';

const SectionWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(15, 0)};

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(9, 0, 0, 0)};
  }
`;

const TitleWrapper = styled.div`
  margin: ${(props) => props.theme.spacing(0, 7)};

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin: ${(props) => props.theme.spacing(0, 3)};
  }
`;

const Subtitle = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  line-height: 3.4rem;
`;

const Title = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 4rem;
  line-height: 4rem;
  text-transform: uppercase;
  padding-bottom: ${(props) => props.theme.spacing(11)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 2.7rem;
    padding-bottom: ${(props) => props.theme.spacing(5)}px;
  }
`;

const ItemTitle = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ItemDescription = styled(Typography)`
  text-transform: initial;
  font-weight: ${(props) => props.theme.typography.fontWeightLighter};
  line-height: 2.2rem;

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-bottom: ${(props) => props.theme.spacing(5)}px;
  }
`;

const ItemImage = styled.img`
  height: 490px;
  width: 100%;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 280px;
  }
`;

const Container = styled(Grid)`
  height: 490px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: auto;
  }
`;

const ItemText = styled(Grid)`
  padding: ${(props) => props.theme.spacing(12, 9)};

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(4, 3, 9)};
  }
`;

function WhatWeDoSection() {
  const { t } = useTranslation(['landingpage', 'translation']);

  return (
    <SectionWrapper id="whatwedo">
      <TitleWrapper>
        <Subtitle variant="h4" color="primary">
          {t('what we bring forward')}
        </Subtitle>
        <Title color="primary">{t('the real change for mobility and energy transition')}</Title>
      </TitleWrapper>
      <Container container>
        <Grid item xs={12} sm={6}>
          <ItemImage src={ChargingMotif} alt={t('translation:charging')} />
        </Grid>
        <ItemText item xs={12} sm={6}>
          <ItemTitle variant="h3" color="primary">
            {t('translation:charging')}
          </ItemTitle>
          <ItemDescription variant="h4" color="primary">
            {t('charging description')}
          </ItemDescription>
        </ItemText>
      </Container>
      <Container container direction="row-reverse">
        <Grid item xs={12} sm={6}>
          <ItemImage src={ProductsMotif} alt={t('products')} />
        </Grid>
        <ItemText item xs={12} sm={6}>
          <ItemTitle variant="h3" color="primary">
            {t('products')}
          </ItemTitle>
          <ItemDescription variant="h4" color="primary">
            {t('products description')}
          </ItemDescription>
        </ItemText>
      </Container>
      <Container container>
        <Grid item xs={12} sm={6}>
          <ItemImage src={ArticleMotif} alt={t('articles')} />
        </Grid>
        <ItemText item xs={12} sm={6}>
          <ItemTitle variant="h3" color="primary">
            {t('articles')}
          </ItemTitle>
          <ItemDescription variant="h4" color="primary">
            {t('articles description')}
          </ItemDescription>
        </ItemText>
      </Container>
    </SectionWrapper>
  );
}

export default WhatWeDoSection;
