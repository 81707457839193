import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import InfoIcon from '../../Icons/InfoIcon';
import { PopoverContent, PopoverImage } from '../../../layout/InfoPopover';
import { TreeWhite } from '../../Illustrations';
import { useInfoPopover } from '../../InfoPopover';
import { StatisticalSubValues } from '../../../utils/models';
import NoDataIcon from '../../Icons/NoDataIcon';

import Co2Savings from './Co2Savings';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.brighter};
  color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
`;

const Title = styled(Typography)`
  padding: ${(props) => props.theme.spacing(0, 4, 1, 4)};
  text-align: center;
`;

const StyledInfoButton = styled(IconButton)`
  align-self: end;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: 0;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const LoadingWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.theme.spacing(3, 4)};

  svg {
    margin-right: ${(props) => props.theme.spacing(1)}px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const NoValues = styled(Typography)<{ variant: 'body2' }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;

  > svg {
    width: 56px;
    height: 56px;
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
  }
`;

export type Co2SavingsResult =
  | { status: 'loading' | 'error' }
  | { status: 'success'; data: StatisticalSubValues | null };

interface Co2SavingsContainerProps {
  title: string;
  result: Co2SavingsResult;
}

function Co2SavingsContainer({ title, result }: Co2SavingsContainerProps) {
  const { t } = useTranslation(['statistics', 'errors']);

  const { status } = result;

  const openInfoPopover = useInfoPopover()?.openInfoPopover;

  const cdrInfoPopup = () => {
    if (typeof openInfoPopover !== 'undefined') {
      openInfoPopover({
        name: t('you have an impact title'),
        content: <PopoverContent title={t('you have an impact title')} description={t(`you have an impact body`)} />,
        images: <PopoverImage image={<TreeWhite />} />,
      });
    }
  };

  const renderContent = () => {
    if (status === 'loading') {
      return (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      );
    }

    if (status === 'success') {
      const { data } = result;
      if (!data) {
        return (
          <NoValues variant="body2">
            <NoDataIcon />
            {t('statistics:no values')}
          </NoValues>
        );
      }

      return <Co2Savings data={data} />;
    }

    return (
      <ErrorWrapper>
        <ErrorOutlineIcon />
        <Typography variant="h4" color="primary" component="p">
          {t('errors:error processing response')}
        </Typography>
      </ErrorWrapper>
    );
  };

  return (
    <Wrapper>
      <StyledInfoButton size="small" aria-label="title" onClick={cdrInfoPopup} data-testid="progress-bar-info-popover">
        <InfoIcon />
      </StyledInfoButton>
      <Title variant="h4" color="primary">
        {title}
      </Title>

      {renderContent()}
    </Wrapper>
  );
}

export default Co2SavingsContainer;
