// @ts-nocheck
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Signet from '../Icons/Signet';
import MerLogoShort from '../Icons/MerLogoShort';

const LogoWrapper = styled.div`
  display: flex;
  margin: ${(props) => (props.open ? props.theme.spacing(2) : 0)}px;

  svg {
    width: ${(props) => (props.open ? '130px' : '100%')};
    height: auto;
    animation-name: img-animation;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;

    @keyframes img-animation {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0;
    svg {
      height: ${(props) => (props.open ? 63 : 40)}px;
      width: auto;
    }
  }
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  bottom: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: absolute;
    top: 16px;
    left: 16px;
    height: fit-content;
    width: fit-content;
  }
`;

export default function MerLogo({ open }) {
  return (
    <Link to="/">
      <LogoWrapper open={open}>{open ? <MerLogoShort /> : <Signet />}</LogoWrapper>
    </Link>
  );
}

MerLogo.propTypes = {
  open: PropTypes.bool.isRequired,
};
