// @ts-nocheck
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Snackbar, Typography, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import SignetIcon from '../Icons/Signet';

const IconWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: ${(props) => props.theme.spacing(3)}px;

  svg {
    width: 94px;
    height: 94px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Title = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  word-break: break-all;
  ${(props) => props.theme.breakpoints.up('sm')} {
    max-width: 300px;
  }
`;

function SnackbarNotification({ duration, message, id, open, onClose, title }) {
  const { t } = useTranslation();

  const Icon = SignetIcon;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={duration}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      key={id}
      open={open}
      onClose={onClose}
    >
      <SnackbarContent
        message={
          <>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <div>
              {title && (
                <Title id="title-id" variant="h3" color="primary">
                  {title}
                </Title>
              )}
              <Typography id="message-id" variant="body1" color="primary">
                {message}
              </Typography>
            </div>
          </>
        }
        action={[
          <IconButton key="close" aria-label={t('close')} size="small" onClick={onClose}>
            <CloseIcon fontSize="small" color="primary" />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

SnackbarNotification.defaultProps = {
  duration: 6000,
  open: null,
  title: null,
};

SnackbarNotification.propTypes = {
  duration: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default SnackbarNotification;
