import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Paper, IconButton, Slide, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const InfoPaper = styled(Paper)<{ width: number | undefined }>`
  border: 1px solid ${(props) => props.theme.palette.primary.brighter};
  box-shadow: ${(props) => props.theme.shadows[24]};
  overflow: hidden;
  bottom: ${(props) => props.theme.spacing(2)}px;
  right: ${(props) => props.theme.spacing(2)}px;

  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 128px;
  max-height: calc(100% - 32px);
  outline: 0;

  width: ${(props) => props.width}px;
  ${(props) => props.theme.breakpoints.down('xs')} {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    bottom: ${(props) => props.theme.spacing(0.5)}px;
    right: ${(props) => props.theme.spacing(0.5)}px;
    max-height: initial;
  }
`;

const CloseButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.main};
  position: absolute;
  top: ${(props) => props.theme.spacing(1.5)}px;
  right: ${(props) => props.theme.spacing(1.5)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

interface InfoPopoverProps {
  children: React.ReactNode;
  open: boolean;
  width?: number;
  onClose(...args: unknown[]): unknown;
}

function InfoPopover({ children, open, width, onClose }: InfoPopoverProps) {
  const { t } = useTranslation();

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const modalWidth = fullScreen ? '100%' : `${width}px`;

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      disableScrollLock
      onClose={onClose}
      hideBackdrop
      style={{
        bottom: 0,
        right: 0,
        width: modalWidth,
        top: 0,
        left: 'initial',
      }}
    >
      <Slide direction="left" in={open} timeout={350}>
        <InfoPaper width={width}>
          <CloseButton size="small" aria-label={t('close')} onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          {children}
        </InfoPaper>
      </Slide>
    </Modal>
  );
}

InfoPopover.defaultProps = {
  width: 400,
};

export default InfoPopover;
