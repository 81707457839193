// @ts-nocheck
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AutoCompleteSearch from '../AutocompleteSearch';
import { getOrganizations } from '../../utils/api';

const searchForOrganizations = async (filter) => {
  const organizationsResponse = await getOrganizations({ filter });
  return organizationsResponse;
};

function SelectOrganization({ errors, onChange }) {
  const { t } = useTranslation();

  const changeValue = (input) => {
    if (input) {
      onChange(input);
    } else {
      onChange(undefined);
    }
  };

  return (
    <AutoCompleteSearch
      label={t('organization')}
      name="organization"
      minLength={0}
      error={!!errors.organization}
      helperText={errors.organization && errors.organization.message}
      onChange={(value) => changeValue(value)}
      requestSearch={searchForOrganizations}
      getOptionLabel={(org) => (org && org.name) || ''}
      required
    />
  );
}

SelectOrganization.defaultProps = {
  errors: {},
};

SelectOrganization.propTypes = {
  errors: PropTypes.shape({
    organization: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
};

export default SelectOrganization;
