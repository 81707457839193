import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import contactPersonImg from '../../../../assets/images/contact_person.jpeg';
import { contactData } from '../../../../utils/contactData';
import constants from '../../../../config/constants';
import { usePermissions } from '../../../../utils/PermissionsProvider';

import ContactCardItem from './ContactCardItem';

const Avatar = styled.img`
  border-radius: 50%;
  width: 6.5rem;
  height: 6.5rem;
  flex-shrink: 0;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${(props) => props.theme.breakpoints.between('md', 'lg')} {
    flex-direction: column;
  }
`;

const StyledTitle = styled(Typography)<{ component: 'h5' }>`
  font-size: 1.7rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: 2rem;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledSubtitle = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const ContentWrapper = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  width: 100%;
  word-wrap: break-word;

  ${(props) => props.theme.breakpoints.between('md', 'lg')} {
    margin-left: 0;
  }
`;

interface ContactCardProps {
  showTitle?: boolean;
}

const defaultProps = { showTitle: true };

function ContactCard({ showTitle }: ContactCardProps) {
  const { t } = useTranslation('dashboard');
  const { hasAssumedRole } = usePermissions();

  return (
    <Wrapper>
      {hasAssumedRole(constants.assumedRoles.MANAGER) && (
        <ContentWrapper>
          {showTitle && (
            <>
              <StyledTitle component="h5">{t('contact title')}</StyledTitle>
              <StyledSubtitle>{t('contact subtitle')}</StyledSubtitle>
            </>
          )}

          {contactData.map((contact) => {
            return <ContactCardItem key={contact.key} contact={contact} />;
          })}
        </ContentWrapper>
      )}
      {hasAssumedRole(constants.assumedRoles.DRIVER) && (
        <>
          <Avatar src={`${contactPersonImg}`} alt={t('contact title driver')} />
          <ContentWrapper>
            <StyledTitle component="h5">{t('contact title driver')}</StyledTitle>
            <StyledSubtitle>{t('contact content driver')}</StyledSubtitle>
            <ContactCardItem key={contactData[0].key} contact={contactData[0]} />
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
}

ContactCard.defaultProps = defaultProps;

export default ContactCard;
