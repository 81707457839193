import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { getStationByUuid } from '../../../utils/api';
import appTheme from '../../../config/appTheme';
import Card from '../../../layout/Card';
import { useContractProvider } from '../../../utils/ContractProvider';
import CheckboxOn from '../../Icons/CheckboxOn';
import Renew from '../../Icons/Renew';
import { ChargePoint, HomeType } from '../../../utils/models';
import constants from '../../../config/constants';

import MobileProcessWidget, { getStep } from './MobileProcessWidget';
import {
  areDocumentsUploaded,
  getButtonText,
  getHeader,
  isDigitalPrecheckDone,
  isFinalState,
  isStepCompleted,
  isFinalStepDisabled,
  isDisabled,
} from './ProcessWidgetUtils';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepWrapper = styled.div`
  width: 100%;
  margin-left: 32px;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const ButtonNumber = styled.div`
  font-size: 10px;
  font-weight: 600;
  opacity: 0.7;
  letter-spacing: 0.05em;
`;

const ButtonText = styled.div`
  text-align: initial;
`;

const StyledGrid = styled(Grid)`
  display: flex;
`;

const StyledButton = styled(Button)<{ collapsed: number; active: number }>`
  width: 384px;
  height: ${(props) => (props.collapsed ? 60 : 96)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  ${(props) => {
    if (props.disabled) {
      return `background-color: ${props.theme.palette.tertiary.brighter};`;
    }
    return props.active ? `background-color: ${props.theme.palette.primary.bright};` : '';
  }};
  ${(props) => {
    if (props.disabled) {
      return `&.MuiButton-outlined.Mui-disabled { border-color: ${props.theme.palette.tertiary.brighter} }`;
    }
    return props.active
      ? `color: ${props.theme.palette.primary.main};`
      : `border: 1px solid ${props.theme.palette.tertiary.lighter};`;
  }}
`;

function ProcessWidget() {
  const { t } = useTranslation('processwidget');
  const selectedContract = useContractProvider()?.selectedContract;
  const [contractDocumentId, setContractDocumentId] = useState<string>();
  const theme: Theme = useTheme();
  const [stepThreeInProgress, setFinalStepInProgress] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMultiHome = selectedContract?.homeType === HomeType.MULTIHOME;
  const isSingleHome = selectedContract?.homeType === HomeType.SINGLEHOME;
  const amountOfSteps = isMultiHome ? 2 : 3;
  const { data: stationData } = useSWR(`getStationByUuid-${selectedContract?.homeStationUuid}`, () => {
    if (selectedContract?.homeStationUuid) {
      return getStationByUuid(selectedContract?.homeStationUuid);
    }
    return {};
  });

  useEffect(() => {
    if (selectedContract?.documents !== undefined && selectedContract?.documents.length > 0) {
      setContractDocumentId(selectedContract?.documents[0].id);
    }
  }, [selectedContract]);

  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  useEffect(() => {
    // step 2 is completed & step 1 is not yet completed
    if (selectedContract && !isDigitalPrecheckDone(selectedContract)) {
      setSelectedStep(1);
    }

    // step 1 is completed & step 2 is not yet completed
    if (
      selectedContract &&
      isDigitalPrecheckDone(selectedContract) &&
      !((areDocumentsUploaded(selectedContract) || !!contractDocumentId) && isSingleHome)
    ) {
      setSelectedStep(2);
    }

    // step 1 is completed & step 2 is completed or active contract
    if (
      selectedContract &&
      ((isDigitalPrecheckDone(selectedContract) &&
        (areDocumentsUploaded(selectedContract) || !!contractDocumentId || isMultiHome)) ||
        selectedContract.contractStatus === constants.contractStatus.ACTIVE)
    ) {
      setSelectedStep(amountOfSteps);
    }
  }, [selectedContract, contractDocumentId, isMultiHome, isSingleHome, amountOfSteps]);

  const handleChangeSelectedStep = (step: number) => {
    setSelectedStep(step);
  };

  const updateDocumentId = (data: { id: SetStateAction<string | undefined> }) => {
    setContractDocumentId(data.id);
  };

  const inProgressChange = (state: boolean): void => {
    setFinalStepInProgress(state);
  };
  const stationState = (stationData as ChargePoint)?.state || '';

  const getStationProgressButtons = () => {
    const buttons = [];
    for (let i = 1; i <= amountOfSteps; i += 1) {
      const isMiddleStep = i === 2 && isSingleHome;
      const text = getButtonText(i, amountOfSteps);
      buttons.push(
        selectedContract && (
          <StyledButton
            key={i}
            active={selectedStep === i ? 1 : 0}
            data-testid={`widgetButton${i}`}
            onClick={() => handleChangeSelectedStep(i)}
            variant="outlined"
            size="medium"
            color="primary"
            collapsed={isFinalState(selectedContract?.contractStatus, stationState) ? 1 : 0}
            disabled={isDisabled(selectedContract, contractDocumentId, i === amountOfSteps, isMiddleStep, stationState)}
          >
            <ButtonContent>
              {!isFinalState(selectedContract?.contractStatus, stationState) && <ButtonNumber>0{i}</ButtonNumber>}
              <ButtonText>{t(text)}</ButtonText>
            </ButtonContent>
            {isStepCompleted(i, selectedContract, stationState, !!contractDocumentId) && <CheckboxOn />}
            {i === amountOfSteps &&
              stepThreeInProgress &&
              !isStepCompleted(i, selectedContract, stationState, !!contractDocumentId) &&
              !isFinalStepDisabled(selectedContract, !!contractDocumentId) &&
              !isFinalState(selectedContract?.contractStatus, stationState) && <Renew />}
          </StyledButton>
        )
      );
    }
    return buttons;
  };
  if (!selectedContract) {
    return null;
  }
  if (isMobile) {
    return (
      <MobileProcessWidget
        selectedState={selectedStep || 1}
        onChangeSelection={(selection: number) => setSelectedStep(selection)}
        contractStatus={selectedContract?.contractStatus || ''}
        stationState={stationState}
      />
    );
  }
  // @ts-ignore
  const { bright } = appTheme.palette.secondary;
  return (
    <Card hasFixedHeight>
      <StyledGrid>
        <ButtonsWrapper>
          {getHeader(t, selectedContract?.contractStatus, stationState)}
          {getStationProgressButtons()}
        </ButtonsWrapper>
        <StepWrapper>
          <Card background={selectedStep && selectedStep < 3 ? bright : 'initial'} noBorder>
            {getStep(
              selectedStep,
              selectedContract,
              stationState,
              contractDocumentId,
              updateDocumentId,
              () => inProgressChange,
              amountOfSteps
            )}
          </Card>
        </StepWrapper>
      </StyledGrid>
    </Card>
  );
}

export default ProcessWidget;
